import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class Footer extends React.Component {
    state = {
        email: '',
        name: '',
        phone: '',
        message: '',
    }
    onLinkClick = (id) => {
        document.getElementById(id).scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });;
    }

    render() {
        const {
            name,
            email,
            phone,
            message,
        } = this.state
        return (
            <>
                <Row id="join-us" className="footer">
                    <Col xs={12} md={6} lg={6} className="footer-contact-form">
                        <h3>Drop us a message</h3>
                        <Form>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Name" value={name} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                            </Form.Group>
                            <Form.Group controlId="formBasicPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" placeholder="Enter Phone Number" value={phone} onChange={(e) => { this.setState({ phone: e.target.value }) }} />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                            </Form.Group>
                            <Form.Group controlId="formBasicMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} value={message} onChange={(e) => { this.setState({ message: e.target.value }) }} />
                            </Form.Group>
                            <Button variant="primary" type="button" style={{ backgroundColor: '#594F9B', padidng: 5, color: 'white', borderRadius: 40, borderWidth: 1, borderColor: '#594F9B', borderStyle: 'solid', paddingLeft: 15, paddingRight: 15 }} onClick={() => {

                                if (email != '' && name != '' && phone != '' && message != '') {
                                    var subject = 'Multibrands Website Contact Form Submission'
                                    var message1 = "<p style='width:100%'>You have a new contact form submission from multibrands website. Following are the details-" + name + "</p>"
                                    message1 = message1 + "<p style='width:100%'>Name:" + name + "</p>"
                                    message1 = message1 + "<p style='width:100%'>Email:" + email + "</p>"
                                    message1 = message1 + "<p style='width:100%'>Phone:" + phone + "</p>"
                                    message1 = message1 + "<p style='width:100%'>Message:" + message + "</p>"
                                    fetch('https://kitchacocms.com.au/api/v1/send-email/?email_to=info@multibrandsgroup.com.au&template=kitchaco-empty-template&subject=' + subject + '&message=' + message1 + '&name=Sahaj')
                                        .then(response => response.json())
                                        .then(dataResponse => {
                                            console.log(dataResponse)
                                            if (dataResponse.success === true) {
                                                if (dataResponse.message[0].status == 'sent') {
                                                    alert("Thank you for contacting us! We will get back to you within 2 business days!")
                                                    window.reload()
                                                }
                                                else {
                                                    alert("Something went wrong! Please try again!")
                                                }
                                            }
                                            else {
                                                alert("Something went wrong! Please try again!")
                                            }
                                        })
                                }
                                else {
                                    alert("Please enter all the fields!")
                                }
                            }}>
                                Submit
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={12} md={6} lg={6} >
                        <Row>
                            <Col xs={12} md={6} lg={6} className="footer-contact-form">
                                <h3>Contact Us</h3>
                                <p>EMAIL: info@multibrandsgroup.com.au</p>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <h3>Quick Links</h3>
                                <a style={{ cursor: 'pointer' }} onClick={() => { this.onLinkClick('why-multibrands') }}><p>Why Multibrands?</p></a>
                                <a style={{ cursor: 'pointer' }} onClick={() => { this.onLinkClick('what-customers-say') }}><p>What customers say?</p></a>
                                <a style={{ cursor: 'pointer' }} onClick={() => { this.onLinkClick('what-is-the-process') }}><p>What's the process?</p></a>
                                <a style={{ cursor: 'pointer' }} onClick={() => { this.onLinkClick('join-us') }}><p>Join Us</p></a>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="footer-copyright">
                        <Row>
                            <Col xs={12} md={12} lg={12}>
                                <p>&copy; MULTI BRANDS GROUP PTY LTD. All rights reserved.</p>

                            </Col>
                        </Row>
                    </Col>
                </Row>
               
            </>

        );
    }
}

export default Footer;