import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"
import OperatorNavBar from '../../components/UI/OperatorNavBar';

class StockOrderEditScreen extends Component {


  state = {
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    pendingOrder: new Array(),
    quantitiesArray: [],
    gstArray: [],
    email: getFromLocalStorage("stockEmail"),
    password: getFromLocalStorage("stockPassword"),
    operatorEmail: getFromLocalStorage("operatorEmail") && getFromLocalStorage("operatorEmail") != '' ? getFromLocalStorage("operatorEmail") : '',
    operatorPassword: getFromLocalStorage("operatorPassword") && getFromLocalStorage("operatorPassword") != '' ? getFromLocalStorage("operatorPassword") : '',
    qtyTouched:false,
    stockUserData: getFromLocalStorage("stockUserData") && JSON.parse(getFromLocalStorage("stockUserData")) ? JSON.parse(getFromLocalStorage("stockUserData")) : new Array(),

  }

  

  logout = () => {
    deleteFromLocalStorage("stockLoggedIn", "false")
    deleteFromLocalStorage("stockEmail","")
    deleteFromLocalStorage("stockPassword","")
    this.setState({ stockUserLoggedIn: "false"})
    window.location.href="/stock"
  }

  editOrder = () => {
    const {
      quantitiesArray,
      pendingOrder,
      email,
      password,
      operatorEmail,
      operatorPassword
    } = this.state

    
    var orderData = pendingOrder[0].orderData
   
    var stockData = []
    var sub_total = 0
    var total_gst = 0
    var total = 0 
    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0
      stockitem_sub_total = Number(Number(orderData[key].price) * Number(quantitiesArray[key])).toFixed(2)
      var stockitem_total_gst = 0
      if(Number(orderData[key].gst)>0){
        stockitem_total_gst = Number(Number(.10* orderData[key].price) * Number(quantitiesArray[key])).toFixed(3)
      }
      var stockitem_total = Number(Number(stockitem_sub_total)+Number(stockitem_total_gst)).toFixed(3)
      
      sub_total = Number(sub_total) + Number(stockitem_sub_total)
      total_gst = Number(total_gst) + Number(stockitem_total_gst)
      
      
      total = Number(total) + Number(stockitem_sub_total) + Number(stockitem_total_gst) 
      console.log(orderData[key].qty_type)
      var obj = {
        "id": orderData[key].id,
        "stock_list_id":orderData[key].stock_list_id,
        "name": orderData[key].name,
        "qty": quantitiesArray[key],
        "qty_type": orderData[key].qty_type,
        "price": orderData[key].price,
        "sub_total":stockitem_sub_total,
        "gst":stockitem_total_gst,
        "total": stockitem_total

      }
      stockData.push(obj)
    }

    total = Number(total) + Number(pendingOrder[0].delivery_charge)

    var orderData = {
      "kitchaco": pendingOrder[0].kitchaco,
      "order_date": moment(new Date()).format("DD MMM YYYY"),
      "order_time": moment(new Date()).format("hh:mm A"),
      "sub_total":Number(sub_total).toFixed(2),
      "delivery_charge":Number(pendingOrder[0].delivery_charge),
      "gst":Number(total_gst).toFixed(2),
      "total": Number(total).toFixed(2),
      "stockData": stockData,
      "orderId":pendingOrder[0].id,
      
    }

    if (stockData.length > 0 && Number(total) > 0) {
      var data = {}
      if(email!="" && password!=""){
        data = { "email": email, "password": password,"orderData": orderData,"query_type":"user_edit_order" }
     
      }
      else if(operatorEmail!="" && operatorPassword!=""){
        data = { "email": operatorEmail, "password": operatorPassword,"orderData": orderData,"query_type":"user_edit_order" }
      }
      
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(data);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            alert("Updated Succesfully")
            window.location.href="/stock-orders"
          }
          else{
            alert(result.error)
          }
        })
        .catch(error => console.log('error', error));
    }
    else{
      alert("Please select altleast 1 item")
    }
  }

  fetchOrderFromServer = () => {
    const{
      stockUserData,
      email,
      password,
      operatorEmail,
      operatorPassword
    } = this.state
    var orderId = window.location.href.substring(window.location.href.lastIndexOf(":")+1)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = ''
    
    if(email!="" && password!=""){
      raw = JSON.stringify({"email":email,"password":password,"query_type":"get_user_specific_order","orderId":orderId});
    
    }
    else if(operatorEmail!="" && operatorPassword!=""){
      raw = JSON.stringify({"email":operatorEmail,"password":operatorPassword,"query_type":"get_user_specific_order","orderId":orderId});
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(config.BASE_URI+apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.success==true){
          
          this.setState({pendingOrder:result.data},()=>{
            
            var todayDate = moment(new Date());
            var todayDay = moment(todayDate).format('dddd');
            var delivery_days = (stockUserData["delivery_day"].toString()).split(",");
            if(delivery_days.indexOf(todayDay.toString())>-1 || (this.state.pendingOrder[0]).approved){
              window.location.href="/stock-orders/"
            }
            var orderData = (this.state.pendingOrder)[0].orderData
            var tempQtyArray=[]
            for (var i=0;i<orderData.length;i++){
              tempQtyArray[i] = orderData[i].qty

            }
            this.setState({quantitiesArray:tempQtyArray},()=>{
              console.log(this.state.quantitiesArray)
            })
          })

        }
        else{
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }

  handleChange(i,gst,price, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value },
      qtyTouched:true,
      gstArray: { ...this.state.gstArray, [i]: Number(Number(e.target.value) * (.10 * Number(price))).toFixed(3) }
    });
    
      
    
  }


  componentDidMount() {
    const {
      stockUserLoggedIn,
      stockUserData,
      pendingOrder
    } = this.state

    this.fetchOrderFromServer()
    
  

    
   
  }



  render() {
    const {
      stockUserLoggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {  this.renderOrderPage()}
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      stockUserLoggedIn,
      stockUserData
    } = this.state
    return (
      <Navbar bg="light" expand="lg" fixed>
        <Navbar.Brand href="#home">
          <Image src={require('../../assets/img/logo.jpg')} style={{ width: 45 }} fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/stock/">Home</Nav.Link>
            {stockUserLoggedIn == "true" ?
              <Nav.Link href="/stock-orders/">Order History</Nav.Link>
              :
              null
            }

          </Nav>
          
          {stockUserLoggedIn == "true" ?
            <>
              <Nav.Link href="#link">Welcome, {stockUserData["first_name"] + ' ' + stockUserData["last_name"]}</Nav.Link>
              <Nav.Link onClick={() => { this.logout() }}>Logout</Nav.Link>
            </>
            :
            null
          }


        </Navbar.Collapse>
      </Navbar>
    )
  }

 

  renderOrderPage() {
    const {
      pendingOrder,
      qtyTouched,
      stockUserData
    } = this.state

    console.log("Pendinf Order ",pendingOrder[0])
    
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: {stockUserData["kitchaco__location"]}</h3>
              <h4>Order Number {pendingOrder[0] && pendingOrder[0].id}</h4>
              <h4>Order Date {pendingOrder[0] && pendingOrder[0].order_date}</h4>
              <h4>Order Time {pendingOrder[0] && pendingOrder[0].order_time}</h4>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Id
            </Col>
            <Col xs={12} md={3} lg={3} className="table-header-col" >
              Product Name
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
          </Row>
          {pendingOrder[0] && ((pendingOrder[0]).orderData).map((item, i) => {
            var subtotal = 0
            var gst = 0
            if(qtyTouched==false){
              subtotal = item.sub_total
              gst = item.gst
            }
            else{
              if(document.getElementById("qty_" + i)){
                subtotal = Number(Number(document.getElementById("qty_" + i).value) * Number(item.price)).toFixed(2)
                gst = Number(Number(document.getElementById("qty_" + i).value) * (.10*Number(item.price))).toFixed(3) 
              }
              else{
                subtotal = 0.00
                gst = 0.00
              }
            }
            return (
              <Row key={i} className="table-row">
                <Col xs={12} md={1} lg={1} className="table-col">
                  <h4 className="desktop-only">{item.stock_list_id}</h4>
                </Col>
                <Col xs={12} md={3} lg={3} className="table-col">
                  <h4>{item.name}</h4>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <span><b className="mobile-only">Par:</b> {item.par}</span>
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                  <Form.Group>
                    <Form.Control type="number" placeholder="Quantity Needed" id={"qty_" + i} value={this.state.quantitiesArray[i]}
                      name={this.state.quantitiesArray[i]}
                      onChange={this.handleChange.bind(this, i,gst,item.price)} />
                    <Form.Text className="text-muted">
                      {item.qty_type__qty_type}
                    </Form.Text>
                  </Form.Group>
                </Col>

                <Col xs={12} md={2} lg={2} className="table-col">
                  {Number(item.gst)>0?
                  <span><b className="mobile-only">Price:</b> ${item.price + ' + GST'}</span>
                  :
                  <span><b className="mobile-only">Price:</b> ${item.price}</span>
                  }
                </Col>
                <Col xs={12} md={2} lg={2} className="table-col">
                {Number(item.gst)>0?
                  <span><h4><b className="mobile-only">Sub Total:</b> {subtotal + ' +  '+ gst}</h4></span>
                  :
                  <span><h4><b className="mobile-only">Sub Total:</b> {subtotal}</h4></span>
                }
                </Col>

              </Row>

            )
          })}
          <Row className="button-row">
            <Col xs={12} >
              <Button onClick={() => { this.editOrder() }}>Update Order</Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockUserLoggedIn: state.appReducer.stockUserLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockOrderEditScreen);



