import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProfileImage from "@daym3l/react-profile-image";
import OperatorNavBar from '../../components/UI/OperatorNavBar';
const AWS_URL = "https://kitchaco-aws-prod-bucket.s3.amazonaws.com"


class OperatorMenuAddScreen extends Component {
  state = {
    isLoading: false,
    operatorKitchacoLocation: getFromLocalStorage("operatorKitchacoLocation") != "undefined" && getFromLocalStorage("operatorKitchacoLocation") != "" && getFromLocalStorage("operatorKitchacoLocation") != null ? getFromLocalStorage("operatorKitchacoLocation") : 0,
    kitchacoLocation: getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : '',
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    meal_name: '',
    meal_price: '',
    meal_status: '',
    meal_description:'',
    image: '',
    vendor_id: -1,
  }

  componentDidMount() {
    var vendor_id = window.location.href.split('#')[1]
    if(vendor_id==-1){
      alert("Please select a brand and try again.")
      window.location.href = '/operator-admin-menu'
    }
    this.setState({ vendor_id: vendor_id })
    const {
      isOperatorLoggedIn
    } = this.state
    if (isOperatorLoggedIn != 'true') {
      window.location.href = '/operator-admin'
    }
  }







  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  addMenu = () => {
    const { image, meal_name, meal_status, meal_price, vendor_id,meal_description } = this.state
    if(Number(meal_price)<=0 && meal_status=='Enabled'){
      alert('Error! Meal item cannot be added when the price = 0 and status = enabled.');
      return
    }
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "add_menu",
        "meal_name": meal_name,
        "meal_status": meal_status,
        "meal_price": meal_price,
        "description":meal_description,
        "image": image,
        "vendor_id": vendor_id,
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success == true) {
          alert("Add successfully! You can add combo and subitems in the next step")
          window.location.href = "/operator-menu-edit#" + dataResponse.meal_id
        }

      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }


  render() {
    const {
      isOperatorLoggedIn,
      stockUserLoggedIn,
      meal_name,
      meal_price,
      meal_status,
      meal_description,
      image
    } = this.state

    return (
      <div>
        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} stockUserLoggedIn={stockUserLoggedIn} ></OperatorNavBar>
        <Container>
          <Form>
            <Media style={{ padding: 10 }}>
              <ProfileImage
                camera={false}
                returnImage={(base64Image, fileImage) => { this.getImages(base64Image, fileImage) }}
                uploadBtnProps={{ variant: "contained", label: "Upload" }}
                defaultImage={require('../../assets/img/empty_logo.png')}

              />
              <Media.Body style={{ marginLeft: 10 }}>
                <Row>
                  <Col xs={8} md={8} lg={8}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Meal Name</Form.Label>
                      <Form.Control type="text" value={meal_name} onChange={(e) => { this.setState({ meal_name: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                  <Col xs={2} md={2} lg={2}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Price ($)</Form.Label>
                      <Form.Control type="text" value={meal_price} onChange={(e) => { this.setState({ meal_price: e.target.value }) }} />
                    </Form.Group>

                  </Col>
                  <Col xs={2} md={2} lg={2}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Meal Status</Form.Label>
                      <Form.Control as="select" size="sm" custom onChange={(e) => { this.setState({ meal_status: e.target.value }) }}>
                        <option selected={meal_status == 'Waiting for review' ? true : false} value={'Waiting for review'}>Waiting for review</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>

                </Row>
                <Row style={{ paddingBottom: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>
                  <Col xs={12} md={12} lg={12}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Description</Form.Label>
                      <Form.Control as="textarea" rows={3} value={meal_description} onChange={(e) => { this.setState({ meal_description: e.target.value }) }} />
                    </Form.Group>
                  </Col>
                </Row>

              </Media.Body>
            </Media>
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Button variant="primary" type="button" onClick={() => { this.addMenu() }}>
                Submit
              </Button>
            </div>
          </Form>
        </Container>

      </div>
    )
  }


  getImages = (base64Image, fileImage) => {
    this.setState({ image: base64Image })
  }




}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorMenuAddScreen);



