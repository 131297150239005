import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import { deleteFromLocalStorage } from '../../../store';



class AdminNavBar extends Component {
    constructor(props) {
        super(props)
    }

    logout = (isSuperAdminLoggedIn, stockAdminLoggedIn) => {
        if (isSuperAdminLoggedIn == "true") {
            deleteFromLocalStorage("isSuperAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminEmail", "")
            deleteFromLocalStorage("stockAdminPassword", "")

            window.location.href = '/admin'

        }
        else if (stockAdminLoggedIn == "true") {
            deleteFromLocalStorage("stockAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminEmail", "")
            deleteFromLocalStorage("stockAdminPassword", "")
            //this.setState({ stockAdminLoggedIn: "false" })
            window.location.href = '/stock-admin'
        }
    }

    render() {
        const {
            isSuperAdminLoggedIn,
            stockAdminLoggedIn
        } = this.props

        var page = (window.location.href.substring(window.location.href.lastIndexOf("/") + 1))
        return (
            <Navbar bg="light" expand="lg">

                <Navbar.Brand href="#home">{stockAdminLoggedIn == "true" || (isSuperAdminLoggedIn == "false" && page == "stock-admin") ? ' Stock ' : null} Admin Panel</Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {/* SUPER ADMIN ACCESS */}
                    {isSuperAdminLoggedIn == "true" ?
                        <>
                            <Nav className="mr-auto">
                                <Nav.Link active={page == "admin" ? true : false} href="/admin">Dashboard</Nav.Link>
                                <Nav.Link active={page == "admin-orders" ? true : false} href="/admin-orders">Orders</Nav.Link>
                                <Nav.Link active={page == "admin-operators" ? true : false} href="/admin-operators">Operators</Nav.Link>
                                <NavDropdown active={page == "admin-txn-report" || page == "admin-sales-report" ? true : false} title="Sales" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/admin-txn-report">Transactional Report</NavDropdown.Item>
                                    <NavDropdown.Item href="/admin-sales-report">Sales Report</NavDropdown.Item>
                                </NavDropdown>
                                
                                <NavDropdown active={page == "stock-admin" || page == "stock-admin-approved-orders" || page =="stock-admin-stocklist" || page=="stock-admin-stockbundle-add" ? true : false} title="Stock" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/stock-admin">Pending Stock Orders</NavDropdown.Item>
                                    <NavDropdown.Item href="/stock-admin-approved-orders">Past Stock Orders</NavDropdown.Item>
                                    <NavDropdown.Item href="/stock-admin-stocklist">Stock List</NavDropdown.Item>
                                    <NavDropdown.Item href="/stock-admin-bundle">Stock Bundle</NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link active={page == "admin-menu" ? true : false} href="/admin-menu">Menu</Nav.Link>
                                <Nav.Link active={page == "admin-kiosks" ? true : false} href="/admin-kiosks">POS Devices</Nav.Link>
                                {/* <Nav.Link  active={page=="admin-drivers"?true:false} href="/admin-drivers">Drivers</Nav.Link> */}
                            </Nav>

                            <Nav.Link onClick={() => { this.logout(isSuperAdminLoggedIn, stockAdminLoggedIn) }}>Logout</Nav.Link>
                        </>
                        : null}
                    {/* STOCK ADMIN ACCESS */}
                    {stockAdminLoggedIn == "true" ?
                        <>
                            <Nav className="mr-auto">
                                <Nav.Link active={page == "stock-admin" ? true : false} href="/stock-admin">Pending Stock Orders</Nav.Link>
                                <Nav.Link active={page == "stock-admin-approved-orders" ? true : false} href="/stock-admin-approved-orders">Past Stock Orders</Nav.Link>
                                <Nav.Link active={page == "stock-admin-stocklist" ? true : false} href="/stock-admin-stocklist">Stock List</Nav.Link>
                                <Nav.Link active={page == "stock-admin-bundle" ? true : false} href="/stock-admin-bundle">Stock Bundle</Nav.Link>
                            </Nav>
                            <Nav.Link onClick={() => { this.logout(isSuperAdminLoggedIn, stockAdminLoggedIn) }}>Logout</Nav.Link>
                        </>
                        :
                        null
                    }

                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default AdminNavBar;









