import React, { Component } from 'react';
import {
    Col,
    Row,
} from 'react-bootstrap';

import './style.css'
import WhatsTheProcessDesktopCarousel from './WhatsTheProcessDesktopCarousel';
import WhatsTheProcessMobileCarousel from './WhatsTheProcessMobileCarousel';

class WhatsTheProcessComponent extends Component {


    render() {
        return (
            <div id="what-is-the-process">
                <Row style={{ backgroundColor: '#FFFFFF', color: 'black', paddingTop: 40, paddingBottom: 40 }}>
                    <Col xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                        <h2>What is the process?</h2>
                    </Col>
                    <Col xs={12} md={12} lg={12}>
                        <WhatsTheProcessDesktopCarousel></WhatsTheProcessDesktopCarousel>
                        <WhatsTheProcessMobileCarousel></WhatsTheProcessMobileCarousel>
                    </Col>

                </Row>
            </div>
        )
    }




}

export default WhatsTheProcessComponent;