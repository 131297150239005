export const slidesData=[
    {
        'title':'Brand 1',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 2',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 3',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 4',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 5',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 6',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
       
        'title':'Brand 7',
        'tagline':"Lorem Ipsum is simply dummy"
    },
    {
        
        'title':'Brand 8',
        'tagline':"Lorem Ipsum is simply dummy"
    }
]