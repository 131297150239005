import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import {SQUARE_ACCESS_TOKEN,SQUARE_API_VERSION,SQUARE_BASE_API} from '../../assets/Constants'
const AWS_URL = "https://kitchaco-aws-prod-bucket.s3.amazonaws.com"



class AdminKiosksScreen extends Component {
  state = {
    isLoading: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    kiosks: [],
    isOpenCreateDeviceCode:false,
    kioskName:'',
    kioskId:'',
    squareLocationId:'',
    showDeviceCode:false,
    deviceCode:'',

  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn
    } = this.state

    if (isSuperAdminLoggedIn != 'true') {
      window.location.href = '/admin'
    }
    this.getAllKiosks()
  }


  getAllKiosks = () => {
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "admin_get_all_kiosks",
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse)
          this.setState({ kiosks: dataResponse.kiosks })
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }





  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };




  render() {
    const {
      isSuperAdminLoggedIn,
      stockAdminLoggedIn,
      kiosks,
    } = this.state
    console.log(kiosks)
    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} stockAdminLoggedIn={stockAdminLoggedIn}></AdminNavBar>
        <Container style={{ padding: 15 }}>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <h4>FAQs</h4>
            <p><b>1. The verfication code for all the pos listed below is 1111.</b></p>
            <p><b>2. If the Kitchaco POS/Kioks has signed out, please enter the phone number as listed below and enter the verification code as 1111.</b></p>
            <p><b>3. If the square terminal is signed out, please click on the button next to the Kiosk you need to log back the terminal onto.</b></p>
          </Col>
        </Row>

          {kiosks.map((item,i) => {
            return (
              <Row className="table-row" style={{marginBottom:20}}>
                <Col xs={12} md={12} lg={12}>
                  <Row style={{ borderBottomWidth: 1, borderBottomColor: 'solid', borderBottomStyle: 'solid' }}>
                    <Col xs={2} md={2} lg={2}>
                      <h4>{i+1}</h4>
                    </Col>
                    <Col xs={10} md={10} lg={10}>
                      <h4>{item.location}</h4>
                    </Col>
                  </Row>
                  <Row className="table-header-row">
                    <Col xs={12} md={3} lg={3} className="table-header-col" >
                      Kiosk Name
                    </Col>
                    <Col xs={12} md={3} lg={3} className="table-header-col" >
                      Phone
                    </Col>
                    <Col xs={12} md={2} lg={2} className="table-header-col">
                      Square Terminal Device Code
                    </Col>
                    <Col xs={12} md={4} lg={4} className="table-header-col">
                      Square Terminal SN
                    </Col>

                  </Row>
                  {item.kiosk_details.map((kiosk,j) => {
                    return (
                      <Row style={{borderBottomColor:1,borderBottomColor:'black',borderBottomStyle:'dotted'}}>
                        <Col xs={12} md={3} lg={3} className="table-col" >
                          <span><b className="mobile-only">Kiosk Name:</b> {kiosk.username}</span>
                          <p style={{marginBottom:0}}>&nbsp;</p>
                          <Button style={{marginBottom:10}} onClick={()=>{this.setState({isOpenCreateDeviceCode:true,kioskName:kiosk.username,kioskId:kiosk.id,squareLocationId:item.square_location_id})}}>Create Square Code</Button> 
                        </Col>
                        <Col xs={12} md={3} lg={3} className="table-col" >
                          <span><b className="mobile-only">Phone:</b> {'0' + kiosk.phone.substring(3)}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col" >
                          <span><b className="mobile-only">Square Terminal Device Code</b>
                          {item.square_terminal_device_code.split(',')[j]!='0'?item.square_terminal_device_code.split(',')[j]:'Not Connected'}
                          </span>
                        </Col>
                        <Col xs={12} md={4} lg={4} className="table-col" >
                          <span>
                            <b className="mobile-only">Square Terminal SN</b>
                            {item.square_terminal_device_id.split(',')[j] !='0'?item.square_terminal_device_id.split(',')[j]:'Not Connected'}
                          </span>
                        </Col>

                      </Row>
                    )
                  })}

                </Col>

              </Row>
            )
          })}
          {this.renderCreateDeviceCode()}
          
        </Container>

      </div>
    )
  }

  renderCreateDeviceCode() {
    const {
      isOpenCreateDeviceCode,
      kioskName,
      showDeviceCode,
      deviceCode
     
    } = this.state

    return (
      <>
        
          <Modal show={isOpenCreateDeviceCode} onHide={() => { window.location.reload() }}>
            {showDeviceCode==false?
            <>
            <Modal.Header closeButton>
              <Modal.Title>Create Square Terminal Code</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
              <p>You are about to create a new sqaure terminal device code to sign in to a square terminal and connect it with <b>{kioskName}</b> </p>
              <p>Are you sure?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>{this.createSquareTerminalDeviceCode()}}>Yes</Button>
              <Button onClick={()=>{this.setState({isOpenCreateDeviceCode:false})}}>No, Cancel</Button>
            </Modal.Footer>
            </>
            :
            <>
            <Modal.Header closeButton>
              <Modal.Title>Connect Square Terminal </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
               <p>Follow the instructions below-</p>
               <p>1. Enter the device code onto square terminal. Your device code is <b>{deviceCode}</b></p>
               <p>2. Please note the device code is only valid for 5 min.</p>
               <p>3. Once square terminal is signed in, try entering items in your cart and testing out the terminal.</p>
               
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={()=>{window.location.reload()}}>OK</Button>
            </Modal.Footer>
            </>}

           

          </Modal>
          
      </>

    )
  }


  createSquareTerminalDeviceCode = async () => {
    const {kioskId,kioskName,squareLocationId} = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "create_device_code",
        "kiosk_id":kioskId,
        "kiosk_name":kioskName,
        "square_location_id":squareLocationId,
        "url":SQUARE_BASE_API+'/v2/devices/codes',
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        
        if (dataResponse.success) {
          console.log('createdevicecode',JSON.parse(dataResponse.data))
          let device_code = JSON.parse(dataResponse.data)
          device_code = device_code.device_code
        
          this.setState({showDeviceCode:true,deviceCode:device_code.code})
          
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

};


}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(AdminKiosksScreen);



