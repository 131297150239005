import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ProfileImage from "@daym3l/react-profile-image";
import OperatorNavBar from '../../components/UI/OperatorNavBar';
const AWS_URL = "https://kitchaco-aws-prod-bucket.s3.amazonaws.com"



class OperatorMenuEditScreen extends Component {
  state = {
    isLoading: false,
    operatorKitchacoLocation: getFromLocalStorage("operatorKitchacoLocation") != "undefined" && getFromLocalStorage("operatorKitchacoLocation") != "" && getFromLocalStorage("operatorKitchacoLocation") != null ? getFromLocalStorage("operatorKitchacoLocation") : 0,
    kitchacoLocation: getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : '',
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    meal_id: -1,
    menuItem: {},
    addTypeName: '',
    addTypePrice: '',
    addTypeStatus: 'Waiting for review',
    image: '',
  }

  componentDidMount() {
    var meal_id = window.location.href.split('#')[1]
    this.getMenu(meal_id)
    this.setState({ meal_id: meal_id })
    const {
      isOperatorLoggedIn
    } = this.state
    if (isOperatorLoggedIn != 'true') {
      window.location.href = '/operator-admin'
    }
  }






  getMenu(meal_id) {

    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "get_specific_menu_item",
        "meal_id": meal_id,
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse)
          this.setState({ menuItem: dataResponse.menu_item })
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }
  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };


  render() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      menuItem
    } = this.state
    console.log(menuItem)
    return (
      <div>
        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} stockUserLoggedIn={stockUserLoggedIn} ></OperatorNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
          {!this.isEmpty(menuItem) ?
            <Form>
              <Media style={{ padding: 10 }}>
                <ProfileImage
                  camera={false}
                  returnImage={(base64Image, fileImage) => { this.getImages(base64Image, fileImage) }}
                  uploadBtnProps={{ variant: "contained", label: "Upload" }}
                  defaultImage={menuItem.meal_image && menuItem.meal_image != '' && menuItem.meal_image != null && menuItem.meal_image != 'NULL' ? AWS_URL + '/' + menuItem.meal_image : require('../../assets/img/empty_logo.png')}

                />
                <Media.Body style={{ marginLeft: 10 }}>
                  <Row >
                    <Col xs={8} md={8} lg={8}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Meal Name</Form.Label>
                        <Form.Control type="text" value={menuItem.meal_name} onChange={(e) => { this.updateMealItem('meal_name', e.target.value, null, null) }} />
                      </Form.Group>
                    </Col>
                    <Col xs={2} md={2} lg={2}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Price ($)</Form.Label>
                        <Form.Control type="text" value={menuItem.meal_price} onChange={(e) => { this.updateMealItem('meal_price', e.target.value, null, null) }} />
                      </Form.Group>

                    </Col>
                    <Col xs={2} md={2} lg={2}>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Meal Status</Form.Label>
                        <Form.Control as="select" size="sm" custom onChange={(e) => { this.updateMealItem('meal_status', e.target.value, null, null) }}>
                          <option selected={menuItem.status == 'Waiting for review' ? true : false} value={'Waiting for review'}>Waiting for review</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row style={{ paddingBottom: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid',marginBottom:20 }}>
                    <Col xs={12} md={12} lg={12}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" rows={3} value={menuItem.description} onChange={(e) => { this.updateMealItem('meal_description', e.target.value, null, null)  }} />
                      </Form.Group>
                    </Col>
                  </Row>

                  {menuItem.meal_combo.length > 0 ?
                    <div style={{ paddingBottom: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>
                      <p style={{ marginBottom: 0 }}><b>Combos</b></p>
                      {menuItem.meal_combo.map((combo) => {
                        return (
                          <>
                            <Row>
                              <Col xs={8} md={8} lg={8}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>Combo Name</Form.Label>
                                  <Form.Control type="text" value={combo.name} onChange={(e) => { this.updateMealItem('combo', e.target.value, combo.id, 'combo_name') }} />
                                </Form.Group>
                              </Col>
                              <Col xs={2} md={2} lg={2}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>Price($)</Form.Label>
                                  <Form.Control type="text" value={combo.price} onChange={(e) => { this.updateMealItem('combo', e.target.value, combo.id, 'combo_price') }} />
                                </Form.Group>
                              </Col>
                              <Col xs={2} md={2} lg={2}>
                                <Form.Group className="mb-3" controlId="formBasicName">
                                  <Form.Label>Combo Status</Form.Label>
                                  <Form.Control as="select" size="sm" custom onChange={(e) => { this.updateMealItem('combo', e.target.value, combo.id, 'combo_status') }}>
                                    <option selected={combo.status == 'Waiting for review' ? true : false} value={'Waiting for review'}>Waiting for review</option>
                                  </Form.Control>
                                </Form.Group>
                              </Col>
                            </Row>
                          </>
                        )
                      })}
                      <Row>
                        <Col xs={8} md={8} lg={8}>

                        </Col>
                        <Col xs={4} md={4} lg={4}>
                          <Button variant="primary" type="button" onClick={() => { this.setState({ isAddModalOpen: true, addType: 'Combo' }) }}>
                            Add Combo
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    :
                    <Row style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
                      <Col xs={8} md={8} lg={8}>
                        <p>No combos have been uploaded. You can upload the combos now</p>
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        <Button variant="primary" type="button" onClick={() => { this.setState({ isAddModalOpen: true, addType: 'Combo' }) }}>
                          Add Combo
                        </Button>
                      </Col>
                    </Row>
                  }

                  {menuItem.meal_subitem.length > 0 ?
                    <div style={{ paddingBottom: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>
                      <p style={{ marginBottom: 0 }}><b>SubItem</b></p>
                      {menuItem.meal_subitem.map((subitem) => {
                        return (
                          <Row>
                            <Col xs={8} md={8} lg={8}>
                              <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Subitem Name</Form.Label>
                                <Form.Control type="text" value={subitem.name} onChange={(e) => { this.updateMealItem('subitem', e.target.value, subitem.id, 'subitem_name') }} />
                              </Form.Group>
                            </Col>
                            <Col xs={2} md={2} lg={2}>
                              <Form.Group className="mb-3" controlId="formPlaintextName">
                                <Form.Label>Price($)</Form.Label>
                                <Form.Control plaintext type="text" value={'0.00'} readOnly />
                              </Form.Group>
                            </Col>
                            <Col xs={2} md={2} lg={2}>
                              <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Subitem Status</Form.Label>
                                <Form.Control as="select" size="sm" custom onChange={(e) => { this.updateMealItem('subitem', e.target.value, subitem.id, 'subitem_status') }}>
                                  <option selected={subitem.status == 'Waiting for review' ? true : false} value={'Waiting for review'}>Waiting for review</option>
                                </Form.Control>
                              </Form.Group>
                            </Col>
                          </Row>
                        )
                      })}
                      <Row>
                        <Col xs={8} md={8} lg={8}>

                        </Col>
                        <Col xs={4} md={4} lg={4}>
                          <Button variant="primary" type="button" onClick={() => { this.setState({ isAddModalOpen: true, addType: 'SubItem' }) }}>
                            Add Sub Item
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    :
                    <Row style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
                      <Col xs={8} md={8} lg={8}>
                        <p>No subitem have been uploaded. You can upload the combos now</p>
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        <Button variant="primary" type="button" onClick={() => { this.setState({ isAddModalOpen: true, addType: 'SubItem' }) }}>
                          Add Sub Item
                        </Button>
                      </Col>
                    </Row>}
                </Media.Body>
              </Media>
              <div style={{textAlign:'right',width:'100%'}}>
              <Button variant="primary" type="button" onClick={() => { this.updateMenu() }}>
                Submit
              </Button>
              </div>
            </Form>
            : null}
        </Container>
        {this.renderAddModal()}
      </div>
    )
  }

  renderAddModal() {
    const {
      isAddModalOpen,
      addType
    } = this.state
    return (
      <Modal show={isAddModalOpen} onHide={() => {
        this.setState({ isAddModalOpen: false }, () => {
          window.location.reload()
        })
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Add New {addType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            addType == 'Combo' ?
              <p>Combos are extras that the user can add to his main meal for an additional price. User can select more than one combo at a time</p>
              :
              <p>Subitems are like 'Pick One' items that a user can choose from without any addtional costs.  </p>
          }
          <Media style={{ padding: 10 }}>

            <Media.Body style={{ marginLeft: 10 }}>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>{addType} Name</Form.Label>
                    <Form.Control type="text" onChange={(e) => { this.setState({ addTypeName: e.target.value }) }} />
                  </Form.Group>
                </Col>
                {addType == 'Combo' ?
                  <Col xs={12} md={12} lg={12}>
                    <Form.Group className="mb-3" controlId="formBasicName">
                      <Form.Label>Price ($)</Form.Label>
                      <Form.Control type="text" onChange={(e) => { this.setState({ addTypePrice: e.target.value }) }} />
                    </Form.Group>

                  </Col>
                  : null}
                <Col xs={12} md={12} lg={12}>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>{addType} Status</Form.Label>
                    <Form.Control value={'Waiting for review'} as="select" size="sm" custom onChange={(e) => { this.setState({ addTypeStatus: e.target.value }) }}>
                      <option value={'Waiting for review'}>Waiting for review</option>

                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12} lg={12}>
                  <Button type="button" onClick={() => { this.addType() }}>Add</Button>
                </Col>

              </Row>
            </Media.Body>
          </Media>
        </Modal.Body>
      </Modal>
    )
  }

  getImages = (base64Image, fileImage) => {
    this.setState({ image: base64Image })
  }
  updateMenu = () => {
    const { menuItem, image } = this.state
    if(Number(menuItem.meal_price)<=0 && menuItem.status=='Enabled'){
      alert('Error! Meal item cannot be updated with the price = 0 and status = enabled.');
      return
    }
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "update_menu",
        "menu_item": menuItem,
        "image": image,
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success == true) {
          alert("Updated successfully")
          window.location.reload()
        }

      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }

  addType = () => {
    const { meal_id, addType, addTypeName, addTypePrice, addTypeStatus } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "add_menu_subitem_combo",
        "meal_id": meal_id,
        "add_type": addType,
        "type_name": addTypeName,
        "type_price": addTypePrice,
        "type_status": addTypeStatus,
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success == true) {
          alert("Added successfully")
          window.location.reload()
        }

      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }

  updateMealItem = (updateType, newValue, updateTypeId, updateSubType) => {
    //Possible values of updateType = meal_name,meal_price,meal_status,combo,subitem,meal_description
    // Possible values of updateSubType when updateType=combo  =  combo_name,combo_price,combo_status
    // Possible values of updateSubType when updateType=subitem  =  subitem_name,subitem_price,subitem_status
    let obj = this.state.menuItem
    if (updateType == 'meal_name') {
      obj.meal_name = newValue
    }
    else if (updateType == 'meal_price') {
      obj.meal_price = newValue
    }
    else if (updateType == 'meal_status') {
      obj.status = newValue
    }
    else if (updateType == 'meal_description') {
      obj.description = newValue
    }
    else if (updateType == 'combo') {
      for (var i = 0; i < obj.meal_combo.length; i++) {
        if (obj.meal_combo[i].id == updateTypeId) {
          if (updateSubType == 'combo_name') {
            obj.meal_combo[i].name = newValue
          }
          else if (updateSubType == 'combo_price') {
            obj.meal_combo[i].price = newValue
          }
          else if (updateSubType == 'combo_status') {
            obj.meal_combo[i].status = newValue
          }
        }
      }
    }
    else if (updateType == 'subitem') {
      for (var i = 0; i < obj.meal_subitem.length; i++) {
        if (obj.meal_subitem[i].id == updateTypeId) {
          if (updateSubType == 'subitem_name') {
            obj.meal_subitem[i].name = newValue
          }
          else if (updateSubType == 'subitem_status') {
            obj.meal_subitem[i].status = newValue
          }
        }
      }
    }



    this.setState({ menuItem: obj })
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorMenuEditScreen);



