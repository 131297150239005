import React, { Component } from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import './style.css'

class StatsComponent extends Component {


    render() {
        return (
            <Row id="what-customers-say" style={{ backgroundColor: '#FFEEEE', color: 'black', paddingTop: 40, paddingBottom: 40,margin:0 }}>
                <Col xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <h2>
                                Choose from up to 24 brands
                            </h2>
                        </Col>
                    </Row>
                    <Container>
                        <Row style={{marginTop:40}}>
                            <Col xs={12} md={4} lg={4}>
                                <h3>4M</h3>
                                <p>Aussies are online, searching for their next meal, placing their orders via their phone, tablet or PC.</p>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <h3>2.6B</h3>
                                <p>is spent each year by aussies on food and drink delivery.</p>
                            </Col>
                            <Col xs={12} md={4} lg={4}>
                                <h3>7.1%</h3>
                                <p>forcasted revenue growth over the next five years.</p>
                            </Col>
                            

                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }




}

export default StatsComponent;