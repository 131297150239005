import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import FullSizePhoto from '../../components/FullSizePhoto';
import LogoComponent from './components/LogoComponent';


class FranchisingInfoScreen extends Component {
    render() {
        return (
            <div>
                <NavBar></NavBar>
                <FullSizePhoto title={"TEXT HERE"} description={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."}></FullSizePhoto>
                <Footer></Footer>


            </div>
        )
    }
}

export default FranchisingInfoScreen;