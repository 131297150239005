import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"
import OperatorNavBar from '../../components/UI/OperatorNavBar';

class OperatorStockOrderScreen extends Component {


  state = {
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    orderList: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email: getFromLocalStorage("stockEmail"),
    password: getFromLocalStorage("stockPassword"),
    operatorEmail: getFromLocalStorage("operatorEmail") && getFromLocalStorage("operatorEmail") != '' ? getFromLocalStorage("operatorEmail") : '',
    operatorPassword: getFromLocalStorage("operatorPassword") && getFromLocalStorage("operatorPassword") != '' ? getFromLocalStorage("operatorPassword") : '',
    stockUserData: getFromLocalStorage("stockUserData") && JSON.parse(getFromLocalStorage("stockUserData")) ? JSON.parse(getFromLocalStorage("stockUserData")) : new Array(),
  }







  fetchStockOrderListFromServer = () => {
    const {
      email,
      password,
      stockUserData,
      operatorEmail,
      operatorPassword
    } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = ''

    if(email!="" && password!=""){
      raw = JSON.stringify({ "kitchaco": stockUserData["kitchaco__id"], "email": email, "password": password, "query_type": "get_all" });
    }
    else if(operatorEmail!="" && operatorPassword!=""){
      raw = JSON.stringify({ "kitchaco": stockUserData["kitchaco__id"], "email": operatorEmail, "password": operatorPassword, "query_type": "get_all" });
    }
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    console.log(config.BASE_URI + apiPaths.stockOrder)
    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("Get All Orders", result)
        if (result.success) {
          this.setState({ orderList: result.data })
        }


      })
      .catch(error => console.log('error', error));
  }


  containsObject = (obj,list) =>{
    if(list){
      for (var i=0;i<list.length;i++){
        if(list[i].stock_list_id==obj.stock_list_id){
          return true
        }
      }
    }
    return false
  }





  componentDidMount() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn
    } = this.state
    if(stockUserLoggedIn=="true" || isOperatorLoggedIn=="true"){
      this.fetchStockOrderListFromServer()
    }
    else{
      window.location.href="/stock"
    }
    

  }



  render() {
    return (
      <div>
        {this.renderNavBar()}
        {this.renderOrderPage()}
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      stockUserData
    } = this.state
    return (
      <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} stockUserLoggedIn={stockUserLoggedIn} ></OperatorNavBar>
    )
  }


  renderOrderPage() {
    const {
      orderList,
      stockUserData
    } = this.state
    var todayDate = moment(new Date());
    var todayDay = moment(todayDate).format('dddd');
    var delivery_days = (stockUserData["delivery_day"].toString()).split(",");
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: {stockUserData["kitchaco__location"]}</h3>
              <h3>Previous Orders</h3>
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col" >
              Order Date and Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              GST
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">

            </Col>
          </Row>
          {orderList.map((item, i) => {
            
            return (
              <>
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                        <span><b className="mobile-only">Order Number:</b> {item.id}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_date+' '+item.order_time}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                        <span><b className="mobile-only">Sub Total:</b>${Number(item.sub_total)>0?item.sub_total:item.total}</span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                        <span><b className="mobile-only">GST:</b>${item.gst}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Total:</b>${item.total} 
                          {Number(item.delivery_charge)>0 && Number(item.admin_adjustment)+Number(item.delivery_charge)!=0?'(inc below min order fee of $'+Number(item.delivery_charge).toFixed(2)+')':null}
                          {Number(item.admin_adjustment)>0?'(inc admin adjustment of $'+Number(item.admin_adjustment).toFixed(2)+')':null}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.approved?
                            <>
                            <span><b className="mobile-only">Status:</b>{'Approved on ' + item.approved_date + ' ' + item.approved_time}</span>
                            <span style={{fontSize:12,fontStyle:'italic'}}> Approved items might be different from the ones you have ordered. Please check the details. </span>
                            </>
                          :null} 
                          {item.disapproved?<span style={{color:'red'}}><b className="mobile-only">Status:</b>{'Disapproved on ' + item.disapproved_date + ' ' + item.disapproved_time}</span>:null}
                          {item.disapproved && item.disapproved_reason!=""?<span style={{fontSize:12,color:'red'}}> Reason: {item.disapproved_reason} </span>:null}
                          {!item.approved && !item.disapproved ?<span><b className="mobile-only">Status:</b>Pending</span>:null}
                          
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                      </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id} style={{padding:20,backgroundColor:'#eff2f5'}}>
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  >
                          <h5>Products Ordered</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={1} lg={1} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Sub Total
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              GST
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Total
                            </Col>
                        </Row>
                        {item.orderData.map((product, i) => {
                          
                          return (

                            <Row key={i} className="table-row" style={this.containsObject(product,item.adjustedData)?{backgroundColor:'yellow'}:null}>
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.stock_list_id}</span>
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                                {product.name+' ('+product.qty_type__qty_type+')'}
                              </Col>

                              <Col xs={12} md={1} lg={1} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${Number(product.sub_total)>0?Number(product.sub_total):Number(product.total)}</span>
                              </Col>
                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">GST:</b>${product.gst}</span>
                              </Col>
                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Total:</b>${product.total}</span>
                              </Col>

                            </Row>


                          )
                        })}

                      {item.adjustedData && item.adjustedData.length>0?
                      <>
                      <Row>
                      <Col xs={12} md={3} lg={3}  style={{marginTop:20}} >
                          <h5>Adjusted Items</h5>
                      </Col>
                      </Row>
                      <Row className="table-header-row">
                            <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                              Id
                             </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col" >
                              Product Name
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-header-col">
                              Qty
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Price
                            </Col>
                            <Col xs={12} md={3} lg={3} className="table-header-col">
                              Sub Total
                            </Col>
                        </Row>
                        {item.adjustedData && item.adjustedData.map((product, i) => {
                         
                          return (

                            <Row key={i} className="table-row">
                              <Col xs={12} md={1} lg={1} className="table-col">
                                <span className="desktop-only">{product.stock_list_id}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                                {product.name+' ('+product.qty_type__qty_type+')'}
                              </Col>

                              <Col xs={12} md={2} lg={2} className="table-col">
                              <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                              </Col>

                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Price:</b> {product.price}</span>
                              </Col>
                              <Col xs={12} md={3} lg={3} className="table-col">
                              <span><b className="mobile-only">Sub Total:</b>${product.total}</span>
                              </Col>

                            </Row>


                          )
                        })}
                        </>
                        :null}







                        {delivery_days.indexOf(todayDay.toString())<0 && !item.approved && !item.disapproved?
                        <Row className="button-row">
                              <Col xs={12} >
                                <Button onClick={() => { window.location.href="/operator-stock-order-edit:"+item.id }}>Edit</Button>
                              </Col>
                        </Row>
                        :
                        <Row className="button-row">
                              <Col xs={12} >
                                <Button disabled>Edit</Button>
                              </Col>
                              <Col xs={12}>
                                <p><b>Why can't I edit my order?</b></p>
                                <p>You won't be able to edit your order due to one of the following reasons:- </p>
                                <p>1. Orders cannot be edited if they have already been approved or disapproved.</p>
                                <p>2. Orders cannot be edited on delivery days ({stockUserData["delivery_day"]})</p>
                              </Col>
                        </Row>}
                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>
               
              </>

            )
          })}
          

        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockUserLoggedIn: state.appReducer.stockUserLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorStockOrderScreen);



