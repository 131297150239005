import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel'
import { slidesData } from '../data/slidesData'
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import './style.css'

class LogoComponent extends Component {
    render() {
        return (
            <Carousel indicators={false}>
                {slidesData && slidesData.length > 0 && slidesData.map((slide, i) => {
                        return (
                            <Carousel.Item key={i}>
                                <Row>
                                {
                                    slidesData[i] &&
                                    (<Col xs={12} md={6} lg={3} style={{paddingTop:20,paddingRight:20,paddingLeft:20}}>
                                        <Row>
                                            <Image style={{ width: '100%' }} src="https://via.placeholder.com/300x200?text=Brand " />
                                        </Row>
                                        <Row>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body>
                                                    <Card.Title>{slidesData[i].title}</Card.Title>
                                                    <Card.Text>
                                                        {slidesData[i].tagline}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    </Col>)
                                }
                                {
                                    slidesData[i + 1] &&
                                    (<Col xs={12} md={6} lg={3} style={{padding:20}}>
                                        <Row>
                                            <Image style={{width:'100%'}} src="https://via.placeholder.com/300x200?text=Brand " />
                                        </Row>
                                        <Row>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body>
                                                    <Card.Title>{slidesData[i+1].title}</Card.Title>
                                                    <Card.Text>
                                                        {slidesData[i+1].tagline}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                        
                                    </Col>)
                                }
                                {
                                    slidesData[i + 2] &&
                                    (<Col xs={12} md={6} lg={3} style={{padding:20}}>
                                        <Row>
                                            <Image style={{ width: '100%' }} src="https://via.placeholder.com/300x200?text=Brand " />
                                        </Row>
                                        <Row>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body>
                                                    <Card.Title>{slidesData[i+2].title}</Card.Title>
                                                    <Card.Text>
                                                        {slidesData[i+2].tagline}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                    </Col>)
                                }
                                {
                                    slidesData[i + 3] &&
                                    (<Col xs={12} md={6} lg={3} style={{padding:20}}>
                                        <Row>
                                            <Image style={{ width: '100%' }} src="https://via.placeholder.com/300x200?text=Brand " />
                                        </Row>
                                        <Row>
                                            <Card style={{ width: '100%' }}>
                                                <Card.Body>
                                                    <Card.Title>{slidesData[i+3].title}</Card.Title>
                                                    <Card.Text>
                                                        {slidesData[i+3].tagline}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Row>
                                        
                                    </Col>)
                                }
                                </Row>

                            </Carousel.Item>
                        )
                    

                })
                }
            </Carousel>
        )
    }




}

export default LogoComponent;