import React from 'react';


class OverLay extends React.Component {
    render() {
        const {
            height,
            width,
            opacity,
            backgroundColor,
            zIndex,

        } = this.props
        return (
            <div style={{position: 'absolute',backgroundColor: backgroundColor,opacity: opacity,top: 0,left:0,width: width,height:height,zIndex:zIndex }}></div>
        );
    }
}

export default OverLay;