import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import './style.css'

class FullSizePhoto extends Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    state = {
        screenHeight: 0,
        screenWidth: 0
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth, screenHeight: window.innerHeight });
    }


    render() {
        const {
            screenHeight
        } = this.state
        const {
            title,
            description
        } = this.props
        return (
            <div className="fullsizephoto">
                <div style={{ height:screenHeight,paddingTop:screenHeight/3,textAlign:'center',color:'white'}}>
                    <h2>{title}</h2>
                    <h5>{description}</h5>
                </div>
                
                

            </div>
        )
    }
}

export default FullSizePhoto;