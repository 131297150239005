import React, { Component } from 'react';

import { 
    Col,
    Row,
    InputGroup, 
    FormControl, 
    Button,
    Image
} from 'react-bootstrap';

import './style.css'

class LandingComponent extends Component {


    render() {
        return (
            <div>
                <Row className="landing-component-row">
                    <Col className="mobile-only" xs={12} md={6} lg={6}>
                        <Image style={{width:'100%'}} src={require('../../../assets/img/homepage_image_landscape.png')}></Image>
                    </Col>
                    <Col xs={12} md={6} lg={6} style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                        <h1>
                            Australia's largest food community
                        </h1>
                        <p>
                            Spend your time on your food rather than your operations
                        </p>
                        {/* <InputGroup className="mb-3" style={{borderStyle:'solid',borderColor:'#EAEAEA',borderRadius:40,padding:5,borderWidth:1}}>
                            <FormControl
                                style={{borderColor:'transparent'}}
                                placeholder="Enter your email address"
                                aria-label="Enter your email address"
                            />
                             <Button style={{backgroundColor:'#594F9B',padidng:5,color:'white',borderRadius:40,borderWidth:1,borderColor:'#594F9B',borderStyle:'solid'}}>Contact us</Button>
                        </InputGroup> */}
                    </Col>
                    <Col className="desktop-only" xs={12} md={6} lg={6} style={{ display: 'table-cell', verticalAlign: 'middle',textAlign:'center ' }}>
                        <Image style={{width:400}} roundedCircle src={require('../../../assets/img/homepage_image.jpeg')}>

                        </Image>
                    </Col>
                </Row>
            </div>
        )
    }




}

export default LandingComponent;