import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { Bar, Pie } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Modal from 'react-bootstrap/Modal';


class AdminScreen extends Component {

  state = {
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    salesByLocationLabels: [],
    salesByLocationId: [],
    salesByLocationData: [],
    salesByVendorLabels: [],
    salesByVendorData: [],
    salesByHoursLabels: [],
    salesByHoursData: [],
    totalSales: 0,
    avgOrder: 0,
    numOrders: 0,
    numUberEatsOrders: 0,
    numUberEatsWebscrapeOrders: 0,
    numKitchacoOrders: 0,
    numDeliverooOrders: 0,
    numDoordashOrders:0,
    numCanceledOrders: 0,
    cashSales: 0,
    cardSales: 0,
    uberSales: 0,
    uberSalesWebscrape: 0,
    deliverooSales: 0,
    doordashSales:0,
    itemsSold: {},
    adminKitchacoLocation: getFromLocalStorage("adminKitchacoLocation") != "undefined" && getFromLocalStorage("adminKitchacoLocation") != "" && getFromLocalStorage("adminKitchacoLocation") != null ? getFromLocalStorage("adminKitchacoLocation") : 0,
    kitchacoLocations: [],
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    isOperatorLoggedIn: false,
    stockSent: 0,
    isOpenUberMoreDetails: false,
    isOpenKitchacoMoreDetails: false,
    isOpenDeliverooMoreDetails: false,
  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn
    } = this.state
    this.getKitchacoLocationData()
    if (isSuperAdminLoggedIn == "true") {
      this.getDashboardData()
    }

  }
  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }


  onChangeKitchacoLocation(e) {

    this.setState({ 'adminKitchacoLocation': e.target.value }, () => {
      saveToLocalStorage("adminKitchacoLocation", this.state.adminKitchacoLocation)
      this.getDashboardData()
    })


  }

  getBackgroundColor(length) {
    var bgColor = []
    for (var k = 0; k < length; k++) {
      var rgb = [];
      for (var i = 0; i < 3; i++)
        rgb.push(Math.floor(Math.random() * 255));
      bgColor.push('rgb(' + rgb.join(',') + ')')
    }

    return bgColor;
  }

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "kitchaco_locations"
      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        console.log(dataResponse)
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.kitchaco_locations })
        }
      })
      .catch(err => { console.log(err) })
  }

  getDashboardData() {
    const {
      startDate,
      endDate,
      adminKitchacoLocation

    } = this.state

    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "dashboard_data",
        "kitchaco_location": parseInt(adminKitchacoLocation),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        console.log("hello there ", dataResponse)
        if (dataResponse.success) {
          var salesByLocationArray = dataResponse.sales_by_locations
          var salesByVendorArray = dataResponse.sales_by_vendors
          var salesByHoursObj = dataResponse.sales_by_hours

          var salesByLocationLabelsTemp = []
          var salesByLocationDataTemp = []
          var salesByLocationIdTemp = []
          var salesByVendorLabelsTemp = []
          var salesByVendorDataTemp = []
          var salesByHoursLabelsTemp = []
          var salesByHoursDataTemp = []
          for (var i = 0; i < salesByLocationArray.length; i++) {
            salesByLocationLabelsTemp.push(salesByLocationArray[i]["location"])
            salesByLocationIdTemp.push(salesByLocationArray[i]["id"])
            if (salesByLocationArray[i]["total_sales"] != null) {
              salesByLocationDataTemp.push(salesByLocationArray[i]["total_sales"])
            }
            else {
              salesByLocationDataTemp.push(0)
            }

          }
          for (var i = 0; i < salesByVendorArray.length; i++) {
            for (var key in salesByVendorArray[i]) {
              if (salesByVendorArray[i][key] != null) {
                salesByVendorLabelsTemp.push(key)
                salesByVendorDataTemp.push(salesByVendorArray[i][key])
              }
            }
          }
          for (var j = 0; j < salesByVendorDataTemp.length; j++) {
            console.log(salesByVendorDataTemp[j])
          }






          for (let [key, value] of Object.entries(salesByHoursObj)) {
            salesByHoursLabelsTemp.push(key)
          }

          salesByHoursLabelsTemp.sort()
          var len = salesByHoursLabelsTemp.length;
          for (var i = 0; i < len; i++) {
            var k = salesByHoursLabelsTemp[i];
            salesByHoursDataTemp.push(salesByHoursObj[k])
          }

          console.log("salesByHoursLabelsTemp",)

          this.setState({
            salesByLocationLabels: salesByLocationLabelsTemp,
            salesByLocationId: salesByLocationIdTemp,
            salesByLocationData: salesByLocationDataTemp,
            salesByVendorLabels: salesByVendorLabelsTemp,
            salesByVendorData: salesByVendorDataTemp,
            salesByHoursLabels: salesByHoursLabelsTemp,
            salesByHoursData: salesByHoursDataTemp,
            avgOrder: dataResponse.avg_order,
            totalSales: dataResponse.total_sales,
            numOrders: dataResponse.num_orders,
            numCanceledOrders: dataResponse.num_canceled_orders,
            cashSales: dataResponse.cash_sales,
            cardSales: dataResponse.card_sales,
            itemsSold: dataResponse.items_sold,
            uberSales: dataResponse.uber_sales,
            uberSalesWebscrape: dataResponse.uber_sales_webscrape,
            deliverooSales: dataResponse.deliveroo_sales,
            doordashSales: dataResponse.doordash_sales,
            numKitchacoOrders: dataResponse.num_kitchaco_orders,
            numUberEatsOrders: dataResponse.num_uber_eats_orders,
            numUberEatsWebscrapeOrders: dataResponse.num_uber_eats_webscrape_orders,
            numDeliverooOrders: dataResponse.num_deliveroo_orders,
            numDoordashOrders: dataResponse.num_doordash_orders,
            stockSent: dataResponse.total_stock_sent,
          })
        }
      })
      .catch(err => { console.log(err) })

  }


  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "admin_login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          console.log(result)
          if (result.data.is_super_admin) {
            this.setState({ isSuperAdminLoggedIn: "true" }, () => {
              saveToLocalStorage("isSuperAdminLoggedIn", "true")
              saveToLocalStorage("superAdminEmail", this.state.email)
              saveToLocalStorage("superAdminPassword", this.state.password)
              this.getDashboardData()
            })
          }
          else {
            alert("You don't have access to this page! Please try again!")
            deleteFromLocalStorage("isSuperAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminEmail", "")
            deleteFromLocalStorage("stockAdminPassword", "")
            window.location.href = '/admin'
          }


        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }




  render() {
    const {
      isSuperAdminLoggedIn
    } = this.state
    return (
      <>

        {
          isSuperAdminLoggedIn == "true" ?
            this.renderMainComponent()
            :
            this.renderLoginComponent()
        }
      </>
    )
  }

  renderLoginComponent() {
    const {
      email,
      password,
      isSuperAdminLoggedIn
    } = this.state
    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { this.setState({ email: text.target.value }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text.target.value }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
          </Button>

        </Container>
      </div>
    )
  }


  renderMainComponent() {
    const {
      startDate,
      endDate,
      salesByLocationLabels,
      salesByLocationId,
      salesByLocationData,
      salesByVendorLabels,
      salesByVendorData,
      salesByHoursData,
      salesByHoursLabels,
      numOrders,
      numCanceledOrders,
      totalSales,
      avgOrder,
      cashSales,
      cardSales,
      adminKitchacoLocation,
      kitchacoLocations,
      isSuperAdminLoggedIn,
      itemsSold,
      uberSales,
      uberSalesWebscrape,
      stockSent,
      numKitchacoOrders,
      numUberEatsOrders,
      deliverooSales,
      doordashSales,
      numDeliverooOrders,
      numDoordashOrders,
    } = this.state

    var totalKitchacoSales = Number(Number(cashSales) + Number(cardSales)).toFixed(2)
    var kitchacoAvg = 0
    var uberEatsAvg = 0
    var deliverooAvg = 0
    var doordashAvg = 0

    if (numKitchacoOrders > 0) {
      kitchacoAvg = Number(totalKitchacoSales / numKitchacoOrders).toFixed(2)
    }
    if (numUberEatsOrders > 0) {
      uberEatsAvg = Number(uberSales / numUberEatsOrders).toFixed(2)
    }

    if (numDeliverooOrders > 0) {
      deliverooAvg = Number(deliverooSales / numDeliverooOrders).toFixed(2)
    }

    if (numDoordashOrders > 0) {
      doordashAvg = Number(doordashSales / numDoordashOrders).toFixed(2)
    }
    return (
      <div>

        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 10 }}>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={6} lg={6}>
              {salesByLocationLabels ?
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Kitchaco Locations {'(' + kitchacoLocations.length + ')'}</Form.Label>
                  {kitchacoLocations && kitchacoLocations.length > 0 ?
                    <Form.Control as="select" size="sm" custom onChange={this.onChangeKitchacoLocation.bind(this)} defaultValue={adminKitchacoLocation}>
                      <option value={0}>All</option>
                      {kitchacoLocations.map((location, i) => {
                        return (
                          <option key={i} value={kitchacoLocations[i].id}>{kitchacoLocations[i].location}</option>

                        )
                      })}
                    </Form.Control>
                    : null}
                </Form.Group>


                : null}
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button onClick={() => { this.getDashboardData() }}>Apply</Button>
                </Col>

              </Row>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, borderBottomWidth: 1, borderBottomColor: 'grey', borderBottomStyle: 'solid' }}>
            <Col xs={12} md={5} lg={5}>
              <h4>Total Sales: ${Number(totalSales).toFixed(2)}</h4>
              <p style={{ fontSize: 12 }}><i>Include sales from Kitchaco + Uber Eats + Deliveroo + Doordash</i></p>
              <p style={{ fontSize: 12 }}><i>(Doesn't include canceled orders)</i></p>
              <h5>Kitchaco Sales: ${totalKitchacoSales} <span onClick={() => { this.setState({ isOpenKitchacoMoreDetails: true }) }} style={{ fontSize: 12, cursor: 'pointer', color: 'blue' }}>See Details</span></h5>
              <h5>Uber Sales: ${Number(uberSales).toFixed(2)} <span onClick={() => { this.setState({ isOpenUberMoreDetails: true }) }} style={{ fontSize: 12, cursor: 'pointer', color: 'blue' }}>See Details</span></h5>
              <p><i>(Data from APIs)</i></p>
              <h5>Deliveroo Sales: ${Number(deliverooSales).toFixed(2)} <span onClick={() => { this.setState({ isOpenDeliverooMoreDetails: true }) }} style={{ fontSize: 12, cursor: 'pointer', color: 'blue' }}>See Details</span></h5>
              <h5>Doordash Sales: ${Number(doordashSales).toFixed(2)} </h5>
              <h5>Stock Sent: ${Number(stockSent).toFixed(2)}</h5>
              <hr className="mobile-only"></hr>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <h4>Total Completed Orders: {numOrders}</h4>
              <p style={{ fontSize: 12 }}><i>Include orders from Kitchaco + Uber Eats + Deliveroo  + Doordash</i></p>
              <p style={{ fontSize: 12 }}><i>(Doesn't include canceled orders)</i></p>
              <h5>Kitchaco Orders: {numKitchacoOrders} </h5>
              <h5>Uber Orders: {numUberEatsOrders} </h5>
              <p><i>(Data from APIs)</i></p>
              <h5>Deliveroo Orders: {numDeliverooOrders} </h5>
              <h5>Doordash Orders: {numDoordashOrders} </h5>
              <h4>Number of Canceled Orders: {numCanceledOrders}</h4>
              <hr className="mobile-only"></hr>

            </Col>
            <Col xs={12} md={3} lg={3}>
              <h4>Total Avg Order: ${Number(avgOrder).toFixed(2)}</h4>
              <p style={{ fontSize: 12 }}><i>(Doesn't include canceled orders)</i></p>
              <h5>Kitchaco Avg: {kitchacoAvg} </h5>
              <h5>Uber Eats Avg: {uberEatsAvg} </h5>
              <h5>Deliveroo Avg: {deliverooAvg} </h5>
              <h5>Doordash Avg: {doordashAvg} </h5>

            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
              {adminKitchacoLocation == 0 ?
                <Bar
                  data={{
                    labels: salesByLocationLabels,
                    datasets: [{
                      label: 'Sales by locations ($)',
                      data: salesByLocationData,
                      backgroundColor: this.getBackgroundColor(salesByLocationLabels.length),

                      borderWidth: 1
                    }]
                  }}
                  height={400}
                  width={600}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true
                        }
                      }]
                    }
                  }}
                />
                :
                <Pie
                  data={{
                    labels: salesByVendorLabels,
                    datasets: [{
                      label: 'Sales by Vendors ($)',
                      data: salesByVendorData,
                      backgroundColor: this.getBackgroundColor(salesByVendorData.length),

                      borderWidth: 1
                    }]
                  }}
                  height={400}
                  width={600}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true
                        }
                      }]
                    }
                  }}
                />
              }
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={12} lg={12}>
              <Bar
                data={{
                  labels: salesByHoursLabels,
                  datasets: [{
                    label: 'Sales by hours ($)',
                    data: salesByHoursData,
                    backgroundColor: this.getBackgroundColor(salesByHoursLabels.length),

                    borderWidth: 1
                  }]
                }}
                height={400}
                width={600}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  }
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={12} md={12} lg={12}>
              <h4>Sales by Vendor</h4>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Total Sales</th>
                  </tr>
                </thead>
                <tbody>
                  {salesByVendorData && salesByVendorData.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{salesByVendorLabels[i]}</td>
                        <td>{salesByVendorData[i]}</td>
                      </tr>
                    )
                  })
                  }

                </tbody>
              </Table>
            </Col>
          </Row>
          {adminKitchacoLocation != 0 ?
            <>
              <Row style={{ marginTop: 20 }}>
                <Col xs={12} md={12} lg={12}>
                  <h4>Items Sold</h4>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(itemsSold).map((key, i) => {
                        console.log(key, itemsSold[key], i);

                        return (
                          <>

                            <tr key={i}>
                              <td>{key}</td>
                              <td>{itemsSold[key]}</td>
                            </tr>
                          </>
                        )
                      })

                      }

                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
            : null}
          {this.renderOpenUberMoreDetails()}
          {this.renderOpenKitchacoMoreDetails()}
          {this.renderOpenDeliverooMoreDetails()}


        </Container>
      </div>
    )
  }

  renderOpenUberMoreDetails() {
    const { isOpenUberMoreDetails, uberSalesWebscrape, uberSales, numUberEatsOrders, numUberEatsWebscrapeOrders, startDate, endDate } = this.state
    var showUberMoreInfo = false
    var yesterday = moment(new Date())
    var start_date = moment(startDate)
    var diff = yesterday.diff(start_date, 'days')
    start_date = moment(startDate).format("DD MMM YYYY")
    var end_date = moment(endDate).format("DD MMM YYYY")


    if (start_date == end_date && diff > 0) {
      showUberMoreInfo = true
    }
    return (
      <Modal show={isOpenUberMoreDetails} onHide={() => { this.setState({ isOpenUberMoreDetails: false }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Uber Sales Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showUberMoreInfo ?
            <>
              <Row>
                <Col xs={4}>
                  <h6><b>API Data</b></h6>
                </Col>
                <Col xs={4}>
                  <h6><b>Webscrape Data</b></h6>
                </Col>
                <Col xs={4}>
                  <h6><b>Variance</b></h6>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <b>Sales</b> : {uberSales}
                </Col>
                <Col xs={4}>
                  <b>Sales</b> : {uberSalesWebscrape}
                </Col>
                <Col xs={4}>
                  $ {Number(Number(uberSalesWebscrape) - Number(uberSales)).toFixed(2)}
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <b>Orders</b> : {numUberEatsOrders}
                </Col>
                <Col xs={4}>
                  <b>Orders</b> : {numUberEatsWebscrapeOrders}
                </Col>
                <Col xs={4}>
                  {Number(numUberEatsWebscrapeOrders) - Number(numUberEatsOrders)}
                </Col>
              </Row>
              {Number(uberSalesWebscrape) - Number(uberSales) != 0 ?
                <Row>
                  <Col xs={12}>
                    <h4>Analysis:</h4>
                    <h5>{Number(Number(uberSalesWebscrape) - Number(uberSales)) < 0 ? 'Uber Eats webscraping Sales lesser than API sales (Uncommon)' : 'Uber Eats API Sales lesser than Webscraping sales (Common)'}</h5>
                    <p>If the varinace is negative that means Uber Eats webscraping has lesser orders and sales than API (shouldn't be the case normally)</p>
                    <p>If the varinace is positive that means Uber Eats API has lesser orders and sales than Uber Eats Webscraping (can happen often due to canceled orders)</p>

                    <p>Note: Variance is calculated by the following formula:</p>
                    <p>Sales Variance = Uber Eats Webscraping Sales - Uber Eats API Sales</p>
                    <p>Order Variance = Uber Eats Webscraping Orders - Uber Eats API Orders</p>

                  </Col>
                </Row>
                : null}
            </>
            :
            <Row>
              <Col xs={12}>
                <p><b>You can't see more details due to one of the following reasons</b></p>
                <p>1. Available only from 19 June 2021</p>
                <p>2. Available only when start date and end date are equal</p>
                <p>3. Available only uptil yesterday. </p>
              </Col>
            </Row>
          }
        </Modal.Body>
      </Modal>
    )
  }


  renderOpenKitchacoMoreDetails() {
    const { isOpenKitchacoMoreDetails, numKitchacoOrders, cardSales, cashSales } = this.state
    return (
      <Modal show={isOpenKitchacoMoreDetails} onHide={() => { this.setState({ isOpenKitchacoMoreDetails: false }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Kitchaco Sales Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Row>
            <Col xs={6}>
              Total Sales
            </Col>
            <Col xs={6}>
              {Number(Number(cardSales) + Number(cashSales)).toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              Card Sales
            </Col>
            <Col xs={6}>
              {Number(cardSales).toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              Cash Sales
            </Col>
            <Col xs={6}>
              {Number(cashSales).toFixed(2)}
            </Col>
          </Row>

        </Modal.Body>
      </Modal>
    )
  }


  renderOpenDeliverooMoreDetails() {
    const { isOpenDeliverooMoreDetails, numDeliverooOrders, deliverooSales } = this.state
    return (
      <Modal show={isOpenDeliverooMoreDetails} onHide={() => { this.setState({ isOpenDeliverooMoreDetails: false }) }}>
        <Modal.Header closeButton>
          <Modal.Title>Deliveroo Sales Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {Number(deliverooSales) > 0 ?
            <>
              <Row>
                <Col xs={6}>
                  Total Sales
                </Col>
                <Col xs={6}>
                  {deliverooSales}
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  Total Orders
                </Col>
                <Col xs={6}>
                  {numDeliverooOrders}
                </Col>
              </Row>
            </>
            :
            <Row>
              <Col xs={12}>
                <p><b>You can't see sales due to one of the following reasons</b></p>
                <p>1. Sales only available if the start date selected is a monday</p>
                <p>2. Sales only available if a period of 14 days is selected</p>
                <p>3. Sales only available uptil the last week </p>
              </Col>
            </Row>}

        </Modal.Body>
      </Modal>
    )
  }




}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(AdminScreen);



