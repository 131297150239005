// export const SQUARE_APP_ID = 'sandbox-sq0idb-6lhZ0Ie7mfltoJgD9bhViQ';
// export const SQUARE_ACCESS_TOKEN =
// 'EAAAEPblhHRrlf6DWVaKkcWHk0CVPfSE5CYnJy0NRM3vACL4FYTzviJlAULLJ0w6';
// export const PAYMENT_URL = 'https://connect.squareupsandbox.com/v2/payments';
// export const SQUARE_SCRIPT_URL = 'https://js.squareupsandbox.com/v2/paymentform'
// export const SQUARE_BASE_API='https://connect.squareupsandbox.com'
// export const CURRENCY = 'AUD';
// export const SQUARE_API_VERSION = '2020-07-22';
// export const EMAIL_ORDER_NOTIFICATIONS = "sahaj@amfvg.com.au";
// export const LOCATION_ID="SVDR8P6WRGRYE"


// remove console.log from index.html as well

export const SQUARE_APP_ID = 'sq0idp-Q3iC1pvET35B0mZKZs9SFQ'
export const SQUARE_ACCESS_TOKEN='EAAAEIa7JFUnnXzWhx2xobLSQniBkZ-xHOArKdCUKGmgdPjFTsjm9ick5_xwIjnS'
export const PAYMENT_URL='https://connect.squareup.com/v2/payments'
export const SQUARE_BASE_API='https://connect.squareup.com'
export const SQUARE_SCRIPT_URL = 'https://js.squareup.com/v2/paymentform'
export const CURRENCY='AUD'
export const SQUARE_API_VERSION='2020-06-25'
export const EMAIL_ORDER_NOTIFICATIONS = "orders@kitchaco.com";
export const LOCATION_ID="N1X5PR1BE0G7T";


export const TRANSACTION_FEE = 0.0123;
export const KITCHACO_DELIVERY_FEE = 0;
export const APP_FEE = 0;
export const EVENT_FEE = 0.1377;
export const EMAIL_BOOK_CATERING = "hello@wherethetruck.com.au";


export const GOOGLE_API_KEY = "AIzaSyB_1vRZ2BJfcRoETruoolVaBW0qbUqvrH4";


export const KICTHACO_GALLERY = ["gallery1.png","gallery2.png","gallery3.png","gallery4.png","gallery5.png",
"gallery6.png","gallery7.png","gallery8.png","gallery9.png","gallery10.png","gallery11.png","gallery12.png"]

export function consoleLog() {
  const args = [...arguments];
  for (let i = 0; i < args.length; i++) {
    //console.log(args[i]);
  }
}

