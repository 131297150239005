import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Bar } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'



class AdminTxnReportScreen extends Component {

  state = {
    adminKitchacoLocation: getFromLocalStorage("adminKitchacoLocation") != "undefined" && getFromLocalStorage("adminKitchacoLocation") != "" && getFromLocalStorage("adminKitchacoLocation") != null ? getFromLocalStorage("adminKitchacoLocation") : 0,
    kitchacoLocations: [],
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    salesReport:[],
    isLoading: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    deliverooChecked:false,
    uberEatsChecked:false,
    menulogChecked:false,
    doordashChecked:false,


  }

  componentDidMount() {

    this.getKitchacoLocationData()
    console.log(getFromLocalStorage("adminKitchacoLocation"))
    const {
      isSuperAdminLoggedIn
    } = this.state
    if (isSuperAdminLoggedIn != 'true') {
      window.location.href = '/admin'
    }
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })
    var endDate = moment(date).add(13, 'days');
    this.setState({ endDate: endDate }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  onChangeKitchacoLocation(e) {

    this.setState({ 'adminKitchacoLocation': e.target.value }, () => {
      saveToLocalStorage("adminKitchacoLocation", this.state.adminKitchacoLocation)
      
    })


  }

  getSalesReport() {
    const {
      startDate,
      endDate,
      adminKitchacoLocation,

    } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "get_sales_report",
        "kitchaco": parseInt(adminKitchacoLocation),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse)
          this.setState({ salesReport: dataResponse.data })
        }
        else{
          var result = window.confirm("Report for this period is not available. Do you want to generate it now? This will generate the report and send transactional summary to the registered email id.");
          if(result){

          }
          
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })
  }


  getTxnReport() {
    const {
      startDate,
      endDate,
      adminKitchacoLocation,
      deliverooChecked,
      uberEatsChecked,
      menulogChecked,
      doordashChecked,

    } = this.state
    
    if(deliverooChecked==false && uberEatsChecked==false && menulogChecked==false && doordashChecked==false){
      alert("Error! Please select atleast one platform (Deliveroo or Uber Eats)")
      return
    }
    console.log("startDate is ",startDate)
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "get_transactional_report",
        "kitchaco": adminKitchacoLocation,
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date":  moment(endDate).format("DD MMM YYYY"),
        "deliveroo": deliverooChecked,
        "uber_eats": uberEatsChecked,
        "menulog":menulogChecked,
        "doordash":doordashChecked,
        "email_report":true,
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          alert("An email has been sent to the registered email id")
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })
  }


  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "kitchaco_locations"
      }
    );



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        console.log(dataResponse)
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.kitchaco_locations })
        }
      })
      .catch(err => { console.log(err) })
  }







  render() {
    console.log(this.state.startDate)
    return (
      <>
        {this.renderMainComponent()}
      </>
    )
  }




  renderMainComponent() {
    const {
      kitchacoLocations,
      adminKitchacoLocation,
      isLoading,
      isSuperAdminLoggedIn,
      startDate,
      endDate,
    } = this.state
    return (
      <>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        {isLoading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          :
          <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={4} lg={4}>
                {kitchacoLocations && kitchacoLocations.length > 0 ?
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Kitchaco Locations {'(' + kitchacoLocations.length + ')'}</Form.Label>
                    <Form.Control as="select" size="sm" custom onChange={this.onChangeKitchacoLocation.bind(this)} defaultValue={adminKitchacoLocation}>
                      <option value={0}>All</option>
                      {kitchacoLocations.map((location, i) => {
                        return (
                          <option key={i} value={kitchacoLocations[i].id}>{kitchacoLocations[i].location}</option>

                        )
                      })}
                    </Form.Control>
                  </Form.Group>


                  : null}
              </Col>
              <Col xs={12} md={8} lg={8}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={3} md={3} lg={3}>
                    <p>Start Date</p>
                    <DatePicker 
                      dateFormat={'dd/MM/yyyy'} 
                      selected={startDate} 
                      onChange={date => this.setStartDate(date)} 
                      filterDate={(date)=>{
                        const day = date.getDay();
                        return day!==0 && day!==2 && day!==3 && day!==4 && day!==5 && day!==6 
                      }}
                      />
                  </Col>
                  <Col xs={3} md={3} lg={3}>
                    <p>End Date</p>
                    <p style={{borderWidth: 1,borderStyle:'solid',borderColor:'black',padding: '2px 1px 2px 5px',backgroundColor:'#bbbcbd',cursor:'not-allowed'}}>{moment(endDate).format('DD/MM/yyyy').toString()}</p>
                    {/* <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} /> */}
                  </Col>
                  <Col xs={2} md={2} lg={2}>
                  <Form.Group controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Deliveroo" checked={this.state.deliverooChecked} onChange={()=>{
                      if(this.state.deliverooChecked==true){
                        this.setState({deliverooChecked:false})
                      }
                      else{
                        this.setState({deliverooChecked:true})
                      }
                    }} />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Uber Eats" checked={this.state.uberEatsChecked} onChange={()=>{
                      if(this.state.uberEatsChecked==true){
                        this.setState({uberEatsChecked:false})
                      }
                      else{
                        this.setState({uberEatsChecked:true})
                      }
                    }} />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Doordash" checked={this.state.doordashChecked} onChange={()=>{
                      if(this.state.doordashChecked==true){
                        this.setState({doordashChecked:false})
                      }
                      else{
                        this.setState({doordashChecked:true})
                      }
                    }} />
                  </Form.Group>
                  <Form.Group controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Menulog" checked={this.state.menulogChecked} onChange={()=>{
                      if(this.state.menulogChecked==true){
                        this.setState({menulogChecked:false})
                      }
                      else{
                        this.setState({menulogChecked:true})
                      }
                    }} />
                  </Form.Group>
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button onClick={() => { 
                      var result = window.confirm("Are you sure? This will generate the txn report and email it to registered email id")
                      if(result){
                        this.getTxnReport()
                      } 
                      }}>Generate</Button>
                  </Col>

                </Row>
              </Col>
            </Row>
            
          </Container>
        }
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(AdminTxnReportScreen);



