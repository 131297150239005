import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Dropdown from 'react-bootstrap/Dropdown'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ReactSearchBox from 'react-search-box'
import { Redirect } from 'react-router-dom'


import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PaymentIcon from '@material-ui/icons/Payment';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CallIcon from '@material-ui/icons/Call';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalMallIcon from '@material-ui/icons/LocalMall';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import CustomButton from '../Button/index';
import { theme } from '../theme';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { changeTab, 
        loginPhone, 
        sendSms, 
        loginModalToggle, 
        profileModalToggle,
        logout, 
        updateUser,
        getUserProfile, 
        changeSelectedLocation, 
        getAllLocations, 
        redirectFromWebsite,
        getSelectedVendor
        } from '../../../actions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import OtpInput from 'react-otp-input';
import './style.css'
import { store, getFromLocalStorage, deleteFromLocalStorage } from '../../../store'
import { GOOGLE_API_KEY } from '../../../assets/Constants';
import { applyToken } from '../../../services/api';

var distance = require('google-distance-matrix');


const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);


class NavBar extends Component {
    constructor(props) {
        super(props)
    }


 

    searchBoxData=()=>{
        let data=new Array()
        const { appReducer } = store.getState()
        const { selectedLocation,vendorData } = appReducer
        vendorData.map((truck)=>{
            if(truck.kitchaco.location===selectedLocation){
                data.push({"id":truck.id,"value":truck.title})
            }

        })
        //console.log("Actions searchBox Data",data)
        this.setState({searchBoxData:data})
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
       
      }

    componentDidMount() {
      
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        const { appReducer } = store.getState()
       // // console.log("Hello appReducer", appReducer)
        const { location, selectedLocation } = appReducer
        const { dispatch,cartProducts } = this.props;
        // this.setState({kitchacoLocationState:this.props.kitchacoLocation})
        // this.setState({selectedLocationState:selectedLocation})
            //console.log(getFromLocalStorage("selectedLocation"))
            
            
            
            dispatch(getAllLocations()).then((data) => {
              
                var locationStateArray = data["payload"].sort((a,b)=>{
                  
                    if(a.id > b.id)  
                       return 1;  
                    else if(a.id < b.id)  
                       return -1;  
                
                    return 0;  
                 
                 })
                 // console.log("Hello ",locationStateArray)
                this.setState({ kitchacoLocationState: locationStateArray }, () => {
                    dispatch(redirectFromWebsite("false"))
                    var loc =""
                    var locId = ""
                    if(getFromLocalStorage("selectedLocation")=='' || getFromLocalStorage("selectedLocation")==null){
                        loc = this.state.kitchacoLocationState[0].location
                        locId= this.state.kitchacoLocationState[0].id
                        // this.setState({ selectedLocationState: this.state.kitchacoLocationState[0].location })
                        // dispatch(changeSelectedLocation({location:this.state.kitchacoLocationState[0].location,id:this.state.kitchacoLocationState[0].id}))
                        
                    }
                    else{
                        loc = getFromLocalStorage("selectedLocation")
                        locId=getFromLocalStorage("selectedLocationId")
                        // this.setState({ selectedLocationState: getFromLocalStorage("selectedLocation") })
                    }


                    // check if redirected from website?
                    var temp = (window.location.href.substring(window.location.href.lastIndexOf("?")+1))
                    var tempId = temp.substring(temp.lastIndexOf("=")+1)
                    var vendorId = window.location.href.substring(window.location.href.lastIndexOf(":")+1)
                  
                    if(!isNaN(tempId)){
                        locId= this.state.kitchacoLocationState[tempId-1].id
                        loc = this.state.kitchacoLocationState[tempId-1].location
                        dispatch(redirectFromWebsite("true"))
                        dispatch(changeTab('explore'))
                    }
                    // console.log("Hello ",locId,loc,getFromLocalStorage("redirectFromWebsite"))
                    this.setState({ selectedLocationState: loc })
                    dispatch(changeSelectedLocation({location:loc,id:locId}))
                    if(getFromLocalStorage('redirectFromWebsite')=="true"){
                        
                        // var vendorId = window.location.href.substring(window.location.href.lastIndexOf(":")+1)
                        // applyToken(getFromLocalStorage('token'))
                        // dispatch(getSelectedVendor(vendorId)).then(()=>{
                        //     const {appReducer} = store.getState()
                        //     // console.log("Hello ola",getFromLocalStorage("selectedVendorData"))
                        // });
                    }
                    
                })
            });
        this.searchBoxData();
        //this.calculateNearestLocation()

    }
    state = {

        code: '',
        kitchacoLocationState: null,
        selectedLocationState: 'Select Location',
        tempSelectedLocationState:'Selected Location',
        tempSelectedLocationStateId:-1,
        usernameState:'',
        emailState:'',
        searchBoxValue:'',
        searchBoxData:new Array(),
        selectedVendor:-1,
        profileModalToggleState:false,
        width: 0, height: 0,
        buttonPressed:false,
        changeLocationModal:false,
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

    changeTab = (tab) => {
        //console.log("tab is ", tab)
        this.props.dispatch(changeTab(tab))
        if((window.location.href).includes("orders")){
            if(tab=='explore' || tab=='map' || tab=='cart' ){
                window.location.href='/';
            }
            if(tab=="orders"){
                window.location.href='/orders';
            }
        }
        else{
            if(tab=='explore' ){
                window.location.href='/';
            }
            if(tab=="orders"){
                window.location.href='/orders';
            }
        }
        
    }
    loginModalToggle = (toggle) => {
        this.props.dispatch(loginModalToggle(toggle))
    }
    profileModalToggle = (toggle) => {
        this.props.dispatch(profileModalToggle(toggle))
        this.setState({profileModalToggleState:toggle})
    }
    sendSms = () => {
        this.setState({buttonPressed:true},()=>{
            this.props.dispatch(sendSms(this.state.phone)).then(()=>{
                this.setState({buttonPressed:false})
            })
        })
       
    }

    loginPhone = () => {
        this.props.dispatch(loginPhone(this.state.phone, this.state.code)).then((data) => {
            
            if(data){
                this.getUserProfile(data["payload"].userId)
            }
            else{
                alert("Error! Please try again!")
            }   

        })
    }
    getUserProfile = (userId) => {
        this.props.dispatch(getUserProfile(userId)).then((data) => {
           
            if(data.payload.username=="" || data.payload.email==null){
                // console.log("Hello 112 ", data.payload)
                this.profileModalToggle(true)           
            }
            else{
                window.location.reload();
            }
            
        })
    }
    updateUserProfile = (userId) => {
        const {usernameState,emailState} = this.state
        //console.log("Actions updateUserProfile",userId,usernameState,emailState)
        applyToken(getFromLocalStorage('token'))
        this.props.dispatch(updateUser(userId,usernameState,emailState)).then((data) => {
            // console.log("Hello Profile",data)
            this.getUserProfile(data["payload"].id)
        })
    }
    logout = () => {
        this.props.dispatch(changeTab('map'))
        this.props.dispatch(logout())
        window.location.href="/"
    }

    isEmpty = obj => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    };

    getSelectedVendor = (selectedVendor) => {
        //console.log("ola1122")
        this.setState({ selectedVendor: selectedVendor })
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.selectedVendor!=this.state.selectedVendor){
            window.location.reload()
            //console.log("update page",prevState.selectedVendor,this.state.selectedVendor)
        }

    }
    emptyStorage = () => {
        deleteFromLocalStorage("orderStatus", "")
        deleteFromLocalStorage("orderDate", "")
        deleteFromLocalStorage('paymentStatus', "")
        deleteFromLocalStorage('paymentSource', "")
        deleteFromLocalStorage('paymentReceiptNumber', "")
        deleteFromLocalStorage('paymentWttCharges', 0)
        deleteFromLocalStorage('paymentTxnCharges', 0)
        deleteFromLocalStorage('paymentTotalCharges', 0)
        deleteFromLocalStorage('paymentError', "")
        deleteFromLocalStorage('products', "{}")
        deleteFromLocalStorage('productsTotal', 0)
        deleteFromLocalStorage('orderType', '')
        deleteFromLocalStorage('orderLocation', '')
        deleteFromLocalStorage('orderLater', false)
        deleteFromLocalStorage('deliveryCharges', 0)
        deleteFromLocalStorage('applyPromoCode', "")
        deleteFromLocalStorage('discount', 0)
        
      }

   
    render() {
        // console.log("Hello",this.state.width)
        if(this.state.width<1350){
            return (
                <div style={{position:'absolute', zIndex:100,width:this.state.width,height:this.state.height,backgroundColor:'white',display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                    <div style={{textAlign:'center'}}>
                    <Image style={{width:this.state.width/6}} src={require('../../../assets/img/logo.jpg')}></Image>
                    <h4 style={{marginTop:40}}>Still living in browser age?</h4>
                    <h4>Download our app now</h4>
                    <a href="https://apps.apple.com/au/app/kitchaco/id1483188544"><Image style={{width:this.state.width/3}} src={require('../../../assets/img/app-store.png')}></Image></a> <br></br>
                    <a href="https://play.google.com/store/apps/details?id=com.kitchaco.app"><Image  style={{width:this.state.width/2.5,marginTop:20}} src={require('../../../assets/img/play-store.png')}></Image></a>
                    </div>
                    
                </div>
            )
        }
        const { appReducer } = store.getState()
        const { selectedTab,
            loggedIn,
            phone,
            username,
            smsSent,
            loginModalToggle,
            profileModalToggle,
            userId,
            email,
            userAddress,
            userImg,
            products,
            productsTotal } = appReducer



        const { code, kitchacoLocationState, selectedLocationState,usernameState,emailState,searchBoxValue,buttonPressed } = this.state
        //console.log("Actions Location ", kitchacoLocationState)
        const {cartProducts} = this.props;
        const searchButtonStyle = {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            backgroundColor: theme.colors.backgroundColor,
            borderLeft: 0,
        };
        const searchButtoniconButtonStyle = {
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            borderRight: 0,
        };
        const locationButtonStyle = {
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            backgroundColor: theme.colors.backgroundColor,
            borderLeft: 0,
            color: '#6f7780',
            borderColor: '#d2d8dd'
        };
        const navBarStyle = {
            backgroundColor: '#fff!important',
            borderBottom: '2px solid #c4c4c4',
        }
        const iconButtonStyle = {
            backgroundColor: theme.colors.backgroundColor,
            fontSize: 40,
            padding: 8,
            borderRadius: 20,
            color: theme.colors.iconColor,
            marginLeft: 10,
            marginRight: 10,
        }
        const iconStyle = {
            backgroundColor: theme.colors.backgroundColor,
            fontSize: 30,
            padding: 5,
            borderRadius: 20,
            color: theme.colors.iconColor,
        }
        const iconArrowStyle = {
            backgroundColor: 'transparent',
            fontSize: 30,
            padding: 5,
            borderRadius: 20,
            color: theme.colors.iconColor,
        }
        const picStyle = {
            border: '1px solid',
            borderRadius: 20,
            borderColor: theme.colors.textColorDark,
            backgroundColor: theme.colors.backgroundColor,

        };
        const noPadding = {
            padding: 0,
            backgroundColor: 'transparent',
            border: 0,
        };
        const textStyle = {
            marginLeft: 10,
            marginRight: 10,
            width: 170,
            justifyContent: 'left',
            cursor:'pointer',
        };

        const copyrightStyle = {
            fontSize: 10,
            color: theme.colors.borderColor,
            textAlign: 'center',
            marginTop: 20,
        };
        const navTabsStyle = {
            width: 80,
            color: theme.colors.primaryColor
        };

        if (!loggedIn) {
            this.loginModalToggle(true)
        }
        else {
            this.loginModalToggle(false)
        }


        const { dispatch } = this.props

        
        if (this.state.selectedVendor != -1) {
            //console.log("ola")
            return <Redirect push to={{
                pathname: '/vendor:'+this.state.selectedVendor,
            }} />
        }
        else{
            //console.log("ola 1")
        }

        return (
            <>
                <Navbar className=" justify-content-between" style={navBarStyle}>
                    <Form inline>
                        
                        <InputGroup onClick={()=>{this.props.dispatch(changeTab('map')); window.location.href='/'}}>
                            <img
                                alt=""
                                src={require('../../../assets/img/logo-round.jpg')}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                                style={{ marginRight: 10 }}
                            />{' '}
                        </InputGroup>
                        <InputGroup style={{ marginRight: 10 }}>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1" style={searchButtoniconButtonStyle}>
                                    <LocationOnIcon></LocationOnIcon>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Dropdown style={searchButtonStyle}>
                                <Dropdown.Toggle style={locationButtonStyle} id="dropdown-basic">
                                    {selectedLocationState}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {kitchacoLocationState && kitchacoLocationState.map(kitchaco => {
                                        return (
                                            <Dropdown.Item key={kitchaco.id} onClick={() => {
                                                this.setState({ tempSelectedLocationState: kitchaco.location,tempSelectedLocationStateId:kitchaco.id,changeLocationModal:true })
                                                
                                            }}>{kitchaco.location}</Dropdown.Item>
                                        )
                                    })}

                                </Dropdown.Menu>
                            </Dropdown>
                        </InputGroup>
                        {getFromLocalStorage("selectedTab")!='map' && getFromLocalStorage("selectedTab")!='cart'?
                            <InputGroup>
                            <ReactSearchBox
                                className="nav-search-box"
                                placeholder="Search for restaurants"
                                data={this.state.searchBoxData}
                                onSelect={record => this.getSelectedVendor(record.id)}
                                onFocus={() => {
                                    //console.log('This function is called when is focussed')
                                }}
                                onChange={value => console.log(value)}
                                fuseConfigs={{
                                    threshold: 0.05,
                                }}
                                value={searchBoxValue}
                            />
                        </InputGroup>
                        
                        :null
                        }
                        
                    </Form>
                    <Form inline>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={selectedTab}
                            onSelect={this.changeTab.bind(this)}
                        >
                            <Tab eventKey="map" title={"Map"}>
                            </Tab>
                            <Tab eventKey="explore" title={"Restaurants"}>
                            </Tab>
                            <Tab eventKey="orders" title={"Orders"}>
                            </Tab>
                            {!this.isEmpty(products)?
                            <Tab eventKey="cart" title={"Cart"}>
                            </Tab>
                            :null}
                        </Tabs>
                    </Form>
                    <Form inline>

                        <CustomButton
                            backgroundColor={theme.colors.backgroundColor}
                            textColor={theme.colors.textColorDark}
                            borderRadius={20}
                            isProfilePic={loggedIn}
                            buttonText={loggedIn === true ? username : 'Sign In'}
                            handleButtonClick={() => { this.loginModalToggle(true) }}
                        ></CustomButton>

                        {/* <NotificationsIcon style={iconButtonStyle}></NotificationsIcon>
                        <BookmarkIcon style={iconButtonStyle}></BookmarkIcon> */}
                        {cartProducts && !this.isEmpty(cartProducts) ?
                            <IconButton aria-label="cart" onClick={() => {
                                
                                this.changeTab('cart')
                            }}>
                                <StyledBadge badgeContent={Object.keys(cartProducts).length} color="secondary">
                                    <ShoppingCartIcon  style={iconButtonStyle}></ShoppingCartIcon>
                                </StyledBadge>
                            </IconButton>

                        : null}
                        <NavDropdown title="" id="basic-nav-dropdown" alignRight >
                            <NavDropdown.Item href="#action/3.1" style={{ width: '300px' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <img
                                                src={userImg!="null" && userImg ? userImg : require('../../../assets/img/profile_picture_default.png')}
                                                width="40"
                                                height="40"
                                                className="d-inline-block align-top"
                                                alt="Profile Pic"
                                                style={picStyle}
                                            />
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Label style={textStyle}>{username}</Form.Label>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/orders" style={{ width: '300px',cursor:'pointer' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <LocalShippingIcon style={iconStyle}></LocalShippingIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Label style={textStyle}>Orders</Form.Label>
                                    <InputGroup.Append>
                                        <ChevronRightIcon style={iconArrowStyle}></ChevronRightIcon>
                                    </InputGroup.Append>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            {/* <NavDropdown.Item href="#action/3.1" style={{ width: '300px' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <SettingsIcon style={iconStyle}></SettingsIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Label style={textStyle}>Profile Settings</Form.Label>
                                    <InputGroup.Append>
                                        <ChevronRightIcon style={iconArrowStyle}></ChevronRightIcon>
                                    </InputGroup.Append>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1" style={{ width: '300px' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <LocalShippingIcon style={iconStyle}></LocalShippingIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>

                                    <Form.Label style={textStyle}>Orders and Deliveries</Form.Label>
                                    <InputGroup.Append>
                                        <ChevronRightIcon style={iconArrowStyle}></ChevronRightIcon>
                                    </InputGroup.Append>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1" style={{ width: '300px' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <PaymentIcon style={iconStyle}></PaymentIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Label style={textStyle}>Payment</Form.Label>
                                    <InputGroup.Append>
                                        <ChevronRightIcon style={iconArrowStyle}></ChevronRightIcon>
                                    </InputGroup.Append>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.1" style={{ width: '300px' }}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <CallIcon style={iconStyle}></CallIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Label style={textStyle}>Contact & Support</Form.Label>
                                    <InputGroup.Append>
                                        <ChevronRightIcon style={iconArrowStyle}></ChevronRightIcon>
                                    </InputGroup.Append>
                                </InputGroup>
                            </NavDropdown.Item>
                            <NavDropdown.Divider /> */}
                            <NavDropdown.Item style={{ width: '300px' }} onClick={() => this.logout()}>
                                <InputGroup >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="basic-addon1" style={noPadding}>
                                            <ExitToAppIcon style={iconStyle}></ExitToAppIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Label style={textStyle}>Logout</Form.Label>
                                </InputGroup>
                            </NavDropdown.Item>
                            <p style={copyrightStyle}>2020 | KitchaCo. All Rights Reserved</p>
                        </NavDropdown>

                    </Form>
                </Navbar>
                <Modal show={loginModalToggle} onHide={() => { this.loginModalToggle(false) }}>
                    {smsSent === false ?
                        <>
                            <Modal.Header>
                                <Modal.Title>Sign In</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
                                Whether you're creating an account or signing back in, lets's start with your number
                            <PhoneInput
                                    containerClass={'phoneInputStyle'}
                                    country={'au'}
                                    onlyCountries={['au', 'gb']}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                    autoFormat={true}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                        autoFocus: true
                                    }}
                                    isValid={(value, country) => {
                                        if (value.match(/12345/)) {
                                            return 'Invalid value: ' + value + ', ' + country.name;
                                        } else if (value.match(/1234/)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    }}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <CustomButton
                                    backgroundColor={'#646771'}
                                    textColor={theme.colors.textColorLight}
                                    borderRadius={20}
                                    isProfilePic={false}
                                    buttonText={buttonPressed?'Loading...':'Continue'}
                                    handleButtonClick={buttonPressed?null:this.sendSms}
                                    buttonWidth={'90%'}
                                    margin={'auto'}
                                >

                                </CustomButton>
                            </Modal.Footer>
                        </>
                        :
                        <>
                            <Modal.Header >
                                <Modal.Title>Verify Your Number</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: '#adb3bf' }}>
                                Enter the confirmation code we have sent to your number
                            <OtpInput
                                    onChange={code => this.setState({ code: code }, () => { if (code.length === 4) { this.loginPhone() } })}
                                    value={code}
                                    numInputs={4}
                                    separator={<span>&nbsp;</span>}
                                    containerStyle={'otpInputStyle'}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <p>Resend Confirmation Code</p>
                            </Modal.Footer>
                        </>
                    }
                </Modal>
                <Modal show={this.state.profileModalToggleState} onHide={() => { this.profileModalToggle(false) }}>
                    <Modal.Header >
                        <Modal.Title>Profile Info</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: '#adb3bf' }}>
                        Enter your profile details below
                        
                            <Form.Group style={{textAlign:'left'}}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Name" 
                                    defaultValue={usernameState} 
                                    onChange={(event)=>{
                                    this.setState({usernameState:event.target.value})
                                }} />
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail" style={{textAlign:'left'}}>
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" 
                                    defaultValue={emailState} 
                                    onChange={(event)=>{
                                    this.setState({emailState:event.target.value})
                                }} />
                            </Form.Group>
                            <CustomButton
                                backgroundColor={usernameState!="" && emailState!=""?theme.colors.primaryColor:theme.colors.backgroundColor}
                                textColor={usernameState!="" && emailState!=""?theme.colors.textColorLight:theme.colors.textColorDark}
                                borderRadius={20}
                                isProfilePic={false}
                                buttonText={'Continue'}
                                margin={'auto'}
                                buttonWidth={'90%'}
                                handleButtonClick={usernameState!="" && emailState!=""?() => {this.updateUserProfile(userId) }:null}
                            ></CustomButton>
                           
                        
                    </Modal.Body>
                   
                </Modal>
                <Modal show={this.state.changeLocationModal} onHide={() => { this.setState({changeLocationModal:false}) }}>
                    <Modal.Header >
                        <Modal.Title>Change Location?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto', color: 'black' }}>
                            If you change your location now, you'll lose the products you've selected. Are you sure?
                            
                            <Row style={{marginTop:10}}>
                                <Col xs={6}>
                                    <CustomButton
                                    backgroundColor={'#5a18451a'}
                                    textColor={theme.colors.primaryColor}
                                    borderRadius={20}
                                    isProfilePic={false}
                                    buttonText={'Cancel'}
                                    margin={'auto'}
                                    buttonWidth={'90%'}
                                    handleButtonClick={()=>{this.setState({changeLocationModal:false,tempSelectedLocationState:this.state.selectedLocationState,tempSelectedLocationStateId:-1})}}
                                    ></CustomButton>
                                </Col>
                                <Col>
                                    <CustomButton
                                    backgroundColor={theme.colors.primaryColor}
                                    textColor={theme.colors.textColorLight}
                                    borderRadius={20}
                                    isProfilePic={false}
                                    buttonText={'Change Location'}
                                    margin={'auto'}
                                    buttonWidth={'90%'}
                                    handleButtonClick={()=>{
                                        this.emptyStorage()
                                        dispatch(changeSelectedLocation({location:this.state.tempSelectedLocationState,id:this.state.tempSelectedLocationStateId}))
                                        dispatch(changeTab('explore'))
                                        window.location.href="/";
                                        this.setState({changeLocationModal:false,selectedLocationState:this.state.tempSelectedLocationState})
                                    }}
                                ></CustomButton>
                                </Col>
                            </Row>
                            
                            
                           
                        
                    </Modal.Body>
                   
                </Modal>
            </>
        );

    }
}

export default NavBar;









