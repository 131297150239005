import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Form from 'react-bootstrap/Form'

import InputGroup from 'react-bootstrap/InputGroup'
import Nav from 'react-bootstrap/Nav'
import { deleteFromLocalStorage } from '../../../store'



class OperatorNavBar extends Component {
    constructor(props) {
        super(props)
    }

    logout = (isOperatorLoggedIn, stockUserLoggedIn) => {
        if (isOperatorLoggedIn == "true") {
            deleteFromLocalStorage("isOperatorLoggedIn", "false")
            // this.setState({ isOperatorLoggedIn: "false" })
            


            deleteFromLocalStorage("stockLoggedIn", "false")
            deleteFromLocalStorage("stockToken", "")
            deleteFromLocalStorage("stockUserId", -1)
            deleteFromLocalStorage("stockEmail", "")
            deleteFromLocalStorage("stockPassword", "")
            // this.setState({ stockUserLoggedIn: "false", token: '', user_id: -1 })
            window.location.href = "/operator-admin"
        }
        else {
            deleteFromLocalStorage("stockLoggedIn", "false")
            deleteFromLocalStorage("stockToken", "")
            deleteFromLocalStorage("stockUserId", -1)
            deleteFromLocalStorage("stockEmail", "")
            deleteFromLocalStorage("stockPassword", "")
            // this.setState({ stockUserLoggedIn: "false", token: '', user_id: -1 })
            window.location.href = "/stock"
        }
    }

    render() {
        const {
            isOperatorLoggedIn,
            stockUserLoggedIn
        } = this.props

        var page = (window.location.href.substring(window.location.href.lastIndexOf("/") + 1))
        return (
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#home">Operator {stockUserLoggedIn == "true" || (isOperatorLoggedIn == "false" && page == "stock") ? ' Stock ' : null} Admin Panel</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {isOperatorLoggedIn == "true" ?
                        <>
                            <Nav className="mr-auto">
                                <Nav.Link active={page == "operator-admin" ? true : false} href="/operator-admin">Dashboard</Nav.Link>
                                <Nav.Link active={page == "operator-admin-orders" ? true : false} href="/operator-admin-orders">Orders</Nav.Link>
                                <Nav.Link active={page == "operator-admin-stock" ? true : false} href="/operator-admin-stock">New Stock Order</Nav.Link>
                                <Nav.Link active={page == "operator-stock-orders" ? true : false} href="/operator-stock-orders">Stock Order History</Nav.Link>
                                <Nav.Link active={page == "operator-admin-menu" ? true : false} href="/operator-admin-menu">Menu</Nav.Link>
                            </Nav>

                            <Nav.Link onClick={() => { this.logout(isOperatorLoggedIn,stockUserLoggedIn) }}>Logout</Nav.Link>
                        </>
                        : null}
                    {stockUserLoggedIn == "true" ?
                        <>
                            <Nav className="mr-auto">
                                <Nav.Link active={page == "stock" ? true : false} href="/stock">New Stock Order</Nav.Link>
                                <Nav.Link active={page == "stock-orders" ? true : false} href="/stock-orders">Stock Order History</Nav.Link>
                            </Nav>
                            <Nav.Link onClick={() => { this.logout(isOperatorLoggedIn,stockUserLoggedIn) }}>Logout</Nav.Link>
                        </>
                        : null}
                </Navbar.Collapse>
            </Navbar>
        );

    }
}

export default OperatorNavBar;









