import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'

import { store } from './store/index'
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom'
import StockScreen from './screens/StockScreen';
import StockOrderScreen from './screens/StockOrderScreen';
import StockAdminScreen from './screens/StockAdminScreen';
import StockAdminUsageReportScreen from './screens/StockAdminUsageReportScreen';
import StockAdminAddStockBundleScreen from './screens/StockAdminAddStockBundleScreen';
import StockAdminEditStockBundleScreen from './screens/StockAdminEditStockBundleScreen';
import StockAdminOrderEditScreen from './screens/StockAdminOrderEditScreen';
import StockOrderEditScreen from './screens/StockOrderEditScreen';
import StockAdminApprovedOrdersScreen from './screens/StockAdminApprovedOrdersScreen';
import StockAdminStockListScreen from './screens/StockAdminStockListScreen';
import HomeScreen from './screens/HomeScreen';
import OurBrandsScreen from './screens/OurBrandsScreen';
import FranchisingInfoScreen from './screens/FranchisingInfoScreen';
import AdminScreen from './screens/AdminScreen';
import AdminOrdersScreen from './screens/AdminOrdersScreen';
import AdminOperatorsScreen from './screens/AdminOperatorsScreen';
import OperatorScreen from './screens/OperatorScreen';
import OperatorOrdersScreen from './screens/OperatorOrdersScreen';
import AdminTxnReportScreen from './screens/AdminTxnReportScreen';
import AdminSalesReportScreen from './screens/AdminSalesReportScreen';
import AdminMenuScreen from './screens/AdminMenuScreen';
import AdminMenuEditScreen from './screens/AdminMenuEditScreen';
import AdminMenuAddScreen from './screens/AdminMenuAddScreen';
import AdminKiosksScreen from './screens/AdminKiosksScreen';
import OperatorMenuScreen from './screens/OperatorMenuScreen';
import OperatorMenuEditScreen from './screens/OperatorMenuEditScreen';
import OperatorMenuAddScreen from './screens/OperatorMenuAddScreen';
import OperatorStockScreen from './screens/OperatorStockScreen';
import OperatorStockOrderScreen from './screens/OperatorStockOrderScreen';
import OperatorStockOrderEditScreen from './screens/OperatorStockOrderEditScreen';




ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/" exact component={()=><HomeScreen></HomeScreen>} />
        <Route path="/our-brands"  component={()=><OurBrandsScreen></OurBrandsScreen>} />
        <Route path="/franchising-info"  component={()=><FranchisingInfoScreen></FranchisingInfoScreen>} />
        <Route path="/admin" component={() => <AdminScreen></AdminScreen>} />
        <Route path="/admin-menu-edit" component={() => <AdminMenuEditScreen></AdminMenuEditScreen>} />
        <Route path="/admin-menu" component={() => <AdminMenuScreen></AdminMenuScreen>} />
        <Route path="/admin-menu-add" component={() => <AdminMenuAddScreen></AdminMenuAddScreen>} />
        <Route path="/admin-kiosks" component={() => <AdminKiosksScreen></AdminKiosksScreen>} />
        <Route path="/operator-admin" component={() => <OperatorScreen></OperatorScreen>} />
        <Route path="/operator-admin-orders" component={() => <OperatorOrdersScreen></OperatorOrdersScreen>} />
        <Route path="/operator-admin-menu" component={() => <OperatorMenuScreen></OperatorMenuScreen>} />
        <Route path="/operator-menu-add" component={() => <OperatorMenuAddScreen></OperatorMenuAddScreen>} />
        <Route path="/operator-menu-edit" component={() => <OperatorMenuEditScreen></OperatorMenuEditScreen>} />
        <Route path="/operator-admin-stock" component={() => <OperatorStockScreen></OperatorStockScreen>} />
        <Route path="/operator-stock-orders" component={() => <OperatorStockOrderScreen></OperatorStockOrderScreen>} />
        <Route path="/operator-stock-order-edit:id" component={() => <OperatorStockOrderEditScreen></OperatorStockOrderEditScreen>} />
        <Route path="/admin-orders" component={() => <AdminOrdersScreen></AdminOrdersScreen>} />
        <Route path="/admin-txn-report" component={() => <AdminTxnReportScreen></AdminTxnReportScreen>} />
        <Route path="/admin-sales-report" component={() => <AdminSalesReportScreen></AdminSalesReportScreen>} />
        <Route path="/admin-operators" component={() => <AdminOperatorsScreen></AdminOperatorsScreen>} />
        <Route path="/stock" component={()=><StockScreen></StockScreen>} />
        <Route path="/stock-orders" component={()=><StockOrderScreen></StockOrderScreen>} />
        <Route path="/stock-order-edit:id" component={()=><StockOrderEditScreen></StockOrderEditScreen>} />
        <Route path="/stock-admin" component={()=><StockAdminScreen></StockAdminScreen>} />
        <Route path="/stock-admin-order-edit:id" component={()=><StockAdminOrderEditScreen></StockAdminOrderEditScreen>} />
        <Route path="/stock-admin-approved-orders" component={()=><StockAdminApprovedOrdersScreen></StockAdminApprovedOrdersScreen>} />        
        <Route path="/stock-admin-stocklist" component={()=><StockAdminStockListScreen></StockAdminStockListScreen>} />        
        <Route path="/stock-admin-stockbundle-add" component={()=><StockAdminAddStockBundleScreen></StockAdminAddStockBundleScreen>} />
        <Route path="/stockbundle-edit" component={()=><StockAdminEditStockBundleScreen></StockAdminEditStockBundleScreen>} />        
        <Route path="/stock-admin-bundle" component={()=><StockAdminUsageReportScreen></StockAdminUsageReportScreen>} />        
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
