import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import LandingComponent from './components/LandingComponent';
import StatsComponent from './components/StatsComponent';
import WhyChooseComponent from './components/WhyChooseComponent';
import TestimonialComponent from './components/TestimonialComponent';
import WhatsTheProcessComponent from './components/WhatsTheProcessComponent';

class HomeScreen extends Component {
    render() {
        return (
            <div>
                <Container>
                    <NavBar></NavBar>
                    <LandingComponent style={{ marginTop: 30 }}></LandingComponent>
                </Container>
                <StatsComponent></StatsComponent>
                <Container>
                    <WhyChooseComponent></WhyChooseComponent>
                </Container>
                <TestimonialComponent></TestimonialComponent>
                <Container>
                    <WhatsTheProcessComponent></WhatsTheProcessComponent>
                </Container>
                <Footer></Footer>
                {/* <LandingComponent style={{marginTop:30}}></LandingComponent>
                <Container>
                    <StatsComponent></StatsComponent>
                    <LearnMoreComponent></LearnMoreComponent>
                    
                </Container>
                <Footer></Footer> */}
            </div>

        )
    }
}

export default HomeScreen;