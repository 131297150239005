import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import OperatorNavBar from '../../components/UI/OperatorNavBar/index';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

const AWS_URL = "https://kitchaco-aws-prod-bucket.s3.amazonaws.com"


class OperatorMenuScreen extends Component {
  state = {
    operatorKitchacoLocation: getFromLocalStorage("operatorKitchacoLocation") != "undefined" && getFromLocalStorage("operatorKitchacoLocation") != "" && getFromLocalStorage("operatorKitchacoLocation") != null ? getFromLocalStorage("operatorKitchacoLocation") : 0,
    kitchacoLocation: getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : '',
    isLoading: false,
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    menuDetails: [],
    selectedBrandMeals: [],
    tempSelectedBrandMeals: [],
    selectedVendorId: -1,
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),

  }

  componentDidMount() {


    this.getMenu()
    const {
      isOperatorLoggedIn
    } = this.state
    if (isOperatorLoggedIn != 'true') {
      window.location.href = '/operator-admin'
    }
  }



  getMenu() {
    const {
      operatorKitchacoLocation,
      kitchacoLocation


    } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "admin_menu",
        "kitchaco_location": parseInt(kitchacoLocation),
      }
    );
    console.log(raw)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse)
          this.setState({ menuDetails: dataResponse.menu_details, tempMenuDetails: dataResponse.menu_details })
        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })

  }


  render() {
    const {
      
      operatorKitchacoLocation,
      menuDetails,
      isOperatorLoggedIn,
      selectedBrandMeals,
      selectedVendorId,
      isLoading,
      stockUserLoggedIn
    } = this.state

    return (
      <div>
        <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} stockUserLoggedIn={stockUserLoggedIn} ></OperatorNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
              <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                <Form.Label>Kitchaco Locations {operatorKitchacoLocation}</Form.Label>
              </Form.Group>
            </Col>
          </Row>
          {isLoading == false ?
            <Row>
              <Col xs={12} md={3} lg={3} style={{ backgroundColor: '#f1f1f1', borderRightWidth: 1, borderLeftWidth: 0, borderTopWidth: 0, borderBottomWidth: 0, borderStyle: 'solid', borderColor: 'black', padding: 0 }}>
                <div><h3 style={{ padding: 20, borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>Brands</h3></div>
                {menuDetails.map((item) => {
                  return (
                    <div
                      key={item.id}
                      style={selectedVendorId == item.id ? { backgroundColor: 'white', cursor: 'pointer', padding: 20, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' } : { cursor: 'pointer', padding: 20, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}
                      onClick={() => {
                        this.setState({ selectedBrandMeals: item.meals, tempSelectedBrandMeals: item.meals, selectedVendorId: item.id })
                      }}
                    >
                      {item.title}
                    </div>
                  )
                })}
              </Col>
              <Col xs={12} md={9} lg={9}>
                <Row>
                  <Col xs={4} md={4} lg={4}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Search" onChange={(e) => { this.onSearch(e) }} />
                    </Form.Group>

                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <Form.Control as="select" size="sm" custom onChange={(e) => { this.onSort(e) }}>
                      <option value={1}>A - Z</option>
                      <option value={2}>Z - A</option>
                      <option value={3}>Status: Enabled - Disabled</option>
                      <option value={4}>Status: Disabled - Enabled</option>

                    </Form.Control>

                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <Button onClick={() => { window.location.href = '/operator-menu-add#' + this.state.selectedVendorId }}>Add</Button>
                  </Col>
                </Row>
                {selectedBrandMeals.length > 0 ?
                  <div>

                    {selectedBrandMeals.map((meal, i) => {
                      return (
                        <Media key={i} style={{ padding: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>
                          <Image style={{ width: 120 }} src={meal.meal_image && meal.meal_image != '' && meal.meal_image != null && meal.meal_image != 'NULL' ? AWS_URL + '/' + meal.meal_image : require('../../assets/img/empty_logo.png')}></Image>
                          <Media.Body style={{ marginLeft: 10 }}>
                            <Row>
                              <Col xs={8} md={8} lg={8}>
                                <h4>{meal.meal_name}</h4>
                              </Col>
                              <Col xs={2} md={2} lg={2}>
                                <h4>${Number(meal.meal_price).toFixed(2)}</h4>
                              </Col>
                              <Col xs={2} md={2} lg={2}>
                                <h4>{meal.status}</h4>
                              </Col>

                            </Row>
                            {meal.meal_combo.length > 0 ?
                              <div style={{ paddingBottom: 10, borderBottomWidth: 1, borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, borderColor: 'black', borderStyle: 'solid' }}>
                                <p style={{ marginBottom: 0 }}><b>Combos</b></p>
                                {meal.meal_combo.map((combo) => {
                                  return (
                                    <Row>
                                      <Col xs={8} md={8} lg={8}>
                                        {combo.name}
                                      </Col>
                                      <Col xs={2} md={2} lg={2}>
                                        ${Number(combo.price).toFixed(2)}
                                      </Col>
                                      <Col xs={2} md={2} lg={2}>
                                        {combo.status}
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                              : null}
                            {meal.meal_subitem.length > 0 ?
                              <div style={{ paddingBottom: 10 }}>
                                <p style={{ marginBottom: 0 }}><b>SubItem</b></p>
                                {meal.meal_subitem.map((subitem) => {
                                  return (
                                    <Row>
                                      <Col xs={8} md={8} lg={8}>
                                        {subitem.name}
                                      </Col>
                                      <Col xs={2} md={2} lg={2}>

                                      </Col>
                                      <Col xs={2} md={2} lg={2}>
                                        {subitem.status}
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                              : null}
                            <Row>
                              <Col xs={8} md={8} lg={8}>

                              </Col>
                              <Col xs={2} md={2} lg={2}>

                              </Col>
                              <Col xs={2} md={2} lg={2}>
                                <Button onClick={() => { window.location.href = '/operator-menu-edit#' + meal.id }}>Edit</Button>
                              </Col>
                            </Row>

                          </Media.Body>
                        </Media>
                      )
                    })}

                  </div>
                  : <div>No results</div>
                }
              </Col>
            </Row>
            :
            <p>Loading...</p>
          }
        </Container>
      </div>
    )
  }

  onSearch = (e) => {
    let menuItems = this.state.tempSelectedBrandMeals
    menuItems = menuItems.filter((el) => {
      return el.name.search(e.target.value) != -1
    })
    this.setState({ selectedBrandMeals: menuItems })
  }


  onSort = (e) => {

    let menuItems = this.state.tempSelectedBrandMeals
    if (e.target.value == 1) {
      menuItems.sort((a, b) => a.name > b.name && 1 || -1)
    }
    if (e.target.value == 2) {
      menuItems.sort((a, b) => a.name < b.name && 1 || -1)
    }
    if (e.target.value == 3) {
      menuItems.sort((a, b) => a.status < b.status && 1 || -1)
    }
    if (e.target.value == 4) {
      menuItems.sort((a, b) => a.status > b.status && 1 || -1)
    }



    this.setState({ selectedBrandMeals: menuItems })
  }


}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(OperatorMenuScreen);



