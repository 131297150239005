import React from 'react';
import {
  Col,
  Row,
  Card,
  Carousel,
  Image
} from 'react-bootstrap';

class WhatsTheProcessDesktopCarousel extends React.Component {

  render() {
    return (
      <Carousel
        interval={500000}
        className="desktop-only"
        indicators={false}
        nextIcon={<img id="myCarouselNext" src={require("../../../assets/img/next-arrow.png")}></img>}
        prevIcon={<img src={require("../../../assets/img/prev-arrow.png")}></img>}
      >
        <Carousel.Item>
          <Row>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/1-comp.png')} />
                    <Card.Body>
                      <Card.Title>1. Take you pick of the brands</Card.Title>
                      <Card.Text>
                        Determine which brands best fit your deomographics, competitors, market, facilities & equipment; then submit your completed application form.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  <div style={{ position: 'absolute', top: '40%' }}>
                    <Image src={require('../../../assets/img/right-vector-1.png')}></Image>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/2-comp.png')} />
                    <Card.Body>
                      <Card.Title>2. Operations consultation</Card.Title>
                      <Card.Text>
                        An Operations Consultant will assess your application and take into account your facilities, advise on set up structure & sign off on operational viability.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  <div style={{ position: 'absolute', top: '40%' }}>
                    <Image src={require('../../../assets/img/right-vector-2.png')}></Image>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/3-comp.png')} />
                    <Card.Body>
                      <Card.Title>3. Legalities and Paperwork</Card.Title>
                      <Card.Text>
                        After a confidentiality agreement is signed, your Account Success Manager will provide you with all necessary paperwork & financial figures.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  {/* <div style={{ position: 'absolute', top: '40%' }}>
                                  <Image src={require('../../../assets/img/right-vector-1.png')}></Image>
                              </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/4-comp.png')} />
                    <Card.Body>
                      <Card.Title>4. Launch plan</Card.Title>
                      <Card.Text>
                        Once the legalities have been signed, an Operations Consultant will assist with creating a launch plan, any operational needs & guide you through your initial stock order.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  <div style={{ position: 'absolute', top: '40%' }}>
                    <Image src={require('../../../assets/img/right-vector-1.png')}></Image>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/5-comp.png')} />
                    <Card.Body>
                      <Card.Title>5. Operations training</Card.Title>
                      <Card.Text>
                        A staff trainer can either conduct ‘in - house’ training at your venue, providing the ability to earn revenue immediately or you can opt for ‘on- site’ training, where your staff visit another location.


                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  <div style={{ position: 'absolute', top: '40%' }}>
                    <Image src={require('../../../assets/img/right-vector-2.png')}></Image>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/6-comp.png')} />
                    <Card.Body>
                      <Card.Title>6. Tech Consultation</Card.Title>
                      <Card.Text>
                        You will be provided with a ‘ready to use’ integrated tablet. A Tech Consultant will train you on all features of the tablet such as stock tracking, stock ordering & accessing revenue figures in real time.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  {/* <div style={{ position: 'absolute', top: '40%' }}>
                                  <Image src={require('../../../assets/img/right-vector-1.png')}></Image>
                              </div> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Carousel.Item>
        <Carousel.Item>
          <Row>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/7-comp.png')} />
                    <Card.Body>
                      <Card.Title>7. Hard launch opening</Card.Title>
                      <Card.Text>
                        Your ‘Multi Brands’ shopfronts will go live on the ‘food delivery’ platforms & customers can then place their orders online opting for delivery or pickup.*
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={3} md={3} lg={3} style={{ overflow: 'hidden' }}>
                  <div style={{ position: 'absolute', top: '40%' }}>
                    <Image src={require('../../../assets/img/right-vector-1.png')}></Image>
                  </div>
                </Col>
              </Row>

            </Col>
            <Col xs={4} md={4} lg={4}>
              <Row>
                <Col xs={9} md={9} lg={9}>
                  <Card style={{ border: 'none' }}>
                    <Card.Img style={{ height: 260 }} variant="top" src={require('../../../assets/img/8-comp.png')} />
                    <Card.Body>
                      <Card.Title>8. Cook, delivery & pickup</Card.Title>
                      <Card.Text>
                        All that is left to do is cook the orders! They will then be delivered using 3rd party drivers, your own drivers or customers can pickup the order from the front counter.*



                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

              </Row>
            </Col>
            <Col xs={4} md={4} lg={4}>

            </Col>
          </Row>
        </Carousel.Item>
      </Carousel>


    );
  }
}

export default WhatsTheProcessDesktopCarousel;