import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class StockAdminApprovedOrdersScreen extends Component {


  state = {
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    pendingOrder: new Array(),
    user_id: getFromLocalStorage("stockUserId"),
    token: getFromLocalStorage("stockToken"),
    quantitiesArray: [],
    email: getFromLocalStorage("stockAdminEmail") && getFromLocalStorage("stockAdminEmail") != '' ? getFromLocalStorage("stockAdminEmail") : '',
    password: getFromLocalStorage("stockAdminPassword") && getFromLocalStorage("stockAdminPassword") != '' ? getFromLocalStorage("stockAdminPassword") : '',
    superAdminEmail:getFromLocalStorage("superAdminEmail") && getFromLocalStorage("superAdminEmail") != '' ? getFromLocalStorage("superAdminEmail") : '',
    superAdminPassword:getFromLocalStorage("superAdminPassword") && getFromLocalStorage("superAdminPassword") != '' ? getFromLocalStorage("superAdminPassword") : '',
    stockAdminUserData: getFromLocalStorage("stockAdminUserData") && JSON.parse(getFromLocalStorage("stockAdminUserData")) ? JSON.parse(getFromLocalStorage("stockAdminUserData")) : new Array(),
    kitchacoLocations: [],
    kitchacoLocation: getFromLocalStorage("kitchacoLocation") != "undefined" && getFromLocalStorage("kitchacoLocation") != "" && getFromLocalStorage("kitchacoLocation") != null ? getFromLocalStorage("kitchacoLocation") : 1,
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    sub_total:0,
    total:0,
    gst:0,
    admin_adjustment:0,
    delivery_charge:0
  }

  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockAdmin, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          this.setState({ stockAdminLoggedIn: "true" }, () => {
            saveToLocalStorage("stockAdminLoggedIn", "true")
            saveToLocalStorage("stockAdminEmail", email)
            saveToLocalStorage("stockAdminPassword", password)
          })
        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }

  logout = () => {
    deleteFromLocalStorage("stockAdminLoggedIn", "false")
    deleteFromLocalStorage("stockAdminEmail", "")
    deleteFromLocalStorage("stockAdminPassword", "")
    this.setState({ stockAdminLoggedIn: "false" })
    window.location.href = '/stock-admin'
  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })
    this.setState({ endDate: date },()=>{
      saveToLocalStorage("endDate",this.state.endDate)
    })
  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.kitchacoLocations;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "kitchaco_locations"
      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        console.log("KitchacoLocations=> ", dataResponse)
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.kitchaco_locations })
        }
      })
      .catch(err => { console.log(err) })
  }



  fetchApprovedOrderFromServer = () => {
    const { email, password, kitchacoLocation,superAdminEmail,superAdminPassword,startDate,endDate } = this.state

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = ''
    if(email!='' && password !=''){
      raw = JSON.stringify({ "email": email, "password": password, "query_type": "get_admin_approved_disapproved", "kitchaco_location": parseInt(kitchacoLocation),"start_date": moment(startDate).format("DD MMM YYYY"),
      "end_date": moment(endDate).format("DD MMM YYYY") });

    }
    else if(superAdminEmail!='' && superAdminPassword !=''){
      raw = JSON.stringify({ "email": superAdminEmail, "password": superAdminPassword, "query_type": "get_admin_approved_disapproved", "kitchaco_location": parseInt(kitchacoLocation), "start_date": moment(startDate).format("DD MMM YYYY"),
      "end_date": moment(endDate).format("DD MMM YYYY") });
    }

    

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("order result "+JSON.stringify(result))
        if (result.success) {
          this.setState({ pendingOrder: result.data,sub_total:result.sub_total,gst:result.gst,total:result.total,admin_adjustment:result.admin_adjustment,delivery_charge:result.delivery_charge })
        }
        else{
          alert(result.error)
        }
      })
      .catch(error => {
        alert("Error! Please try again")
      });
  }

  containsObject = (obj, list) => {
    if (list) {
      for (var i = 0; i < list.length; i++) {
        if (list[i].stock_list_id == obj.stock_list_id) {
          return true
        }
      }
    }
    return false
  }

  handleChange(i, e) {
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value }
    });
  }

  onChangeKitchacoLocation(e) {

    this.setState({ 'kitchacoLocation': e.target.value }, () => {
      saveToLocalStorage("kitchacoLocation", this.state.kitchacoLocation)
      window.location.reload()
    })


  }


  componentDidMount() {
    const {
      stockAdminLoggedIn
    } = this.state
    this.getKitchacoLocationData()
    this.fetchApprovedOrderFromServer()

  }



  render() {
    const {
      stockAdminLoggedIn,
      isSuperAdminLoggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {
          stockAdminLoggedIn == "true" || isSuperAdminLoggedIn=="true" ?
            this.renderHomePage()
            :
            this.renderLoginPage()
        }
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      stockAdminLoggedIn,
      isSuperAdminLoggedIn,
      stockAdminUserData
    } = this.state
    console.log("NavBar is ",isSuperAdminLoggedIn)
    return (
      <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn}  stockAdminLoggedIn={stockAdminLoggedIn}></AdminNavBar>
    )
  }

  renderLoginPage() {
    const {
      email,
      password
    } = this.state
    return (
      <div>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { this.setState({ email: text }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
            </Button>

        </Container>
      </div>
    )
  }

  renderHomePage() {
    const {
      pendingOrder,
      kitchacoLocation,
      kitchacoLocations,
      startDate,
      endDate,
      sub_total,
      delivery_charge,
      gst,
      total,
      admin_adjustment,
    } = this.state
    
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Past Orders</h3>
            </Col>
            <Col xs={12} md={6} lg={6}>
              {kitchacoLocations && kitchacoLocations.length > 0 ?
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>Kitchaco Locations {'(' + kitchacoLocations.length + ')'}</Form.Label>
                  <Form.Control as="select" size="sm" custom onChange={this.onChangeKitchacoLocation.bind(this)} defaultValue={kitchacoLocation}>
                    <option value={0}>All</option>
                    {kitchacoLocations.map((location, i) => {
                      return (
                        <option key={i} value={kitchacoLocations[i].id}>{kitchacoLocations[i].location}</option>

                      )
                    })}
                  </Form.Control>
                </Form.Group>
                : null}
            </Col>
            <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={4} md={4} lg={4}>
                    <p>Approved Start Date</p>
                    <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>Approved End Date</p>
                    <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                  </Col>
                  <Col xs={4} md={4} lg={4}>
                    <p>&nbsp;</p>
                    <Button onClick={() => { this.fetchApprovedOrderFromServer() }}>Apply</Button>
                  </Col>

                </Row>
              </Col>
          </Row>
          <Row style={{marginBottom:10}}>
            <Col xs={12} md={12} lg={12}>
                  <Row>
                      <Col xs={12} md={4} lg={4}>
                        <b><h4>Stock Ordered (ex GST): ${sub_total}</h4></b>
                      </Col>
                      <Col xs={12} md={4} lg={4}>
                        <b><h4>GST: ${Number(gst).toFixed(2)}</h4></b>
                      </Col>
                      <Col xs={12} md={4} lg={4}>
                        <b><h4>Stock Ordered (inc GST): ${total}</h4></b>
                        <b><h6>Below Min Order Fee: ${delivery_charge}</h6></b>
                        <b><h6>Admin Adjustment: ${admin_adjustment}</h6></b>
                      </Col>
                  </Row>
                  
            </Col>
          </Row>
          <Row className="table-header-row">
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Order #
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col" >
              Order Date and Time
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Location
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Sub Total
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              GST
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Total
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Status
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">

            </Col>
          </Row>
          {pendingOrder.map((item, i) => {
            console.log(item)
            return (
              <>
                <Accordion key={item.id}>
                  <>
                    <Accordion.Toggle as={Card.Header} eventKey={item.id}>
                      <Row className="table-row">
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span><b className="mobile-only">Order Number:</b> {item.id}</span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.order_date + ' ' + item.order_time}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          {item.kitchaco__location}
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span><b className="mobile-only">Sub Total:</b>${item.sub_total}</span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span><b className="mobile-only">GST:</b>${item.gst}</span>
                        </Col>
                        <Col xs={12} md={1} lg={1} className="table-col">
                          <span><b className="mobile-only">Total:</b>${item.total}</span>
                          {Number(item.delivery_charge)>0 && Number(item.admin_adjustment)+Number(item.delivery_charge)!=0?'(inc below min order fee of $'+Number(item.delivery_charge).toFixed(2)+')':null}
                          {Number(item.admin_adjustment)>0?'(inc admin adjustment of $'+Number(item.admin_adjustment).toFixed(2)+')':null}
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <span><b className="mobile-only">Status:</b>
                            {item.approved ? 'Approved on ' + item.approved_date + ' ' + item.approved_time : null}
                          </span>
                          <span style={{color:'red'}}><b className="mobile-only">Status:</b>
                            {item.disapproved ? 'Disapproved on ' + item.disapproved_date + ' ' + item.disapproved_time + ' Reason: ' + item.disapproved_reason : null}
                          </span>
                          <span><b className="mobile-only">Status:</b>
                            {!item.disapproved && !item.approved ? 'Pending' : null}
                          </span>
                        </Col>
                        <Col xs={12} md={2} lg={2} className="table-col">
                          <div className="details-btn">Details</div>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={item.id} style={{ padding: 20, backgroundColor: '#eff2f5' }}>
                      <>
                        <Row>
                          <Col xs={12} md={3} lg={3}  >
                            <h5>Products Ordered</h5>
                          </Col>
                        </Row>
                        <Row className="table-header-row">
                          <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                            Id
                             </Col>
                          <Col xs={12} md={2} lg={2} className="table-header-col" >
                            Product Name
                            </Col>
                          <Col xs={12} md={1} lg={1} className="table-header-col">
                            Qty
                            </Col>
                          <Col xs={12} md={2} lg={2} className="table-header-col">
                            Price
                            </Col>
                          <Col xs={12} md={2} lg={2} className="table-header-col">
                            Sub Total
                            </Col>
                          <Col xs={12} md={2} lg={2} className="table-header-col">
                            GST
                            </Col>
                          <Col xs={12} md={2} lg={2} className="table-header-col">
                            Total
                            </Col>
                        </Row>
                        {item.orderData.map((product, i) => {

                          return (
                            <>
                              <Row key={i} className="table-row" style={this.containsObject(product, item.adjustedData) ? { backgroundColor: 'yellow' } : null}>
                                <Col xs={12} md={1} lg={1} className="table-col">
                                  <span className="desktop-only">{product.id}</span>
                                </Col>

                                <Col xs={12} md={2} lg={2} className="table-col">
                                  {product.name}
                                </Col>

                                <Col xs={12} md={1} lg={1} className="table-col">
                                  <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                                </Col>

                                <Col xs={12} md={2} lg={2} className="table-col">
                                  <span><b className="mobile-only">Price:</b> {product.price}</span>
                                </Col>
                                <Col xs={12} md={2} lg={2} className="table-col">
                                  <span><b className="mobile-only">Sub Total:</b>${product.sub_total}</span>
                                </Col>
                                <Col xs={12} md={2} lg={2} className="table-col">
                                  <span><b className="mobile-only">GST:</b>${product.gst}</span>
                                </Col>
                                <Col xs={12} md={2} lg={2} className="table-col">
                                  <span><b className="mobile-only">Total:</b>${product.total}</span>
                                </Col>

                              </Row>

                            </>


                          )
                        })}
                        {item.adjustedData && item.adjustedData.length > 0 ?
                          <>
                            <Row>
                              <Col xs={12} md={3} lg={3} style={{ marginTop: 20 }} >
                                <h5>Adjusted Items</h5>
                              </Col>
                            </Row>
                            <Row className="table-header-row">
                              <Col className="desktop-only" xs={12} md={1} lg={1} className="table-header-col" >
                                Id
                             </Col>
                              <Col xs={12} md={2} lg={2} className="table-header-col" >
                                Product Name
                            </Col>
                              <Col xs={12} md={1} lg={1} className="table-header-col">
                                Qty
                            </Col>
                              <Col xs={12} md={2} lg={2} className="table-header-col">
                                Price
                            </Col>
                              <Col xs={12} md={2} lg={2} className="table-header-col">
                                Sub Total
                            </Col>
                              <Col xs={12} md={2} lg={2} className="table-header-col">
                                GST
                            </Col>
                              <Col xs={12} md={2} lg={2} className="table-header-col">
                                Total
                            </Col>
                            </Row>
                            {item.adjustedData && item.adjustedData.map((product, i) => {

                              return (

                                <Row key={i} className="table-row">
                                  <Col xs={12} md={1} lg={1} className="table-col">
                                    <span className="desktop-only">{product.stock_list_id}</span>
                                  </Col>

                                  <Col xs={12} md={2} lg={2} className="table-col">
                                    {product.name + ' (' + product.qty_type__qty_type + ')'}
                                  </Col>

                                  <Col xs={12} md={1} lg={1} className="table-col">
                                    <span><b className="mobile-only">Qty:</b> {product.qty}</span>
                                  </Col>

                                  <Col xs={12} md={2} lg={2} className="table-col">
                                    <span><b className="mobile-only">Price:</b> {product.price}</span>
                                  </Col>
                                  <Col xs={12} md={2} lg={2} className="table-col">
                                    <span><b className="mobile-only">Sub Total:</b>${product.sub_total}</span>
                                  </Col>
                                  <Col xs={12} md={2} lg={2} className="table-col">
                                    <span><b className="mobile-only">GST:</b>${product.gst}</span>
                                  </Col>
                                  <Col xs={12} md={2} lg={2} className="table-col">
                                    <span><b className="mobile-only">Total:</b>${product.sub_total}</span>
                                  </Col>

                                </Row>


                              )
                            })}
                          </>
                          : null}

                      </>
                    </Accordion.Collapse>
                  </>
                </Accordion>

              </>

            )
          })}

        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockAdminLoggedIn: state.appReducer.stockAdminLoggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockAdminApprovedOrdersScreen);



