import React, { Component } from 'react';

import {
    Col,
    Row,
    InputGroup,
    FormControl,
    Button,
    Image
} from 'react-bootstrap';

import './style.css'

class TestimonialComponent extends Component {


    render() {
        return (
            <div style={{overflow:'hidden'}}>
                <Row className="landing-component-row" style={{backgroundColor:'#ffeeee'}}>
                    <Col xs={12} md={5} lg={5} className="testimonial-image">
                        <Image style={{ width:400,borderRadius:40,borderColor:'white',borderWidth:10,borderStyle:'solid' }}  src={require('../../../assets/img/testimonial-image-1.jpeg')}>

                        </Image>
                    </Col>
                    <Col xs={12} md={7} lg={7} style={{ display: 'table-cell', verticalAlign: 'middle',padding:30 }}>
                        <h3 style={{marginRight:60}}>
                            "With Multibrand, I have access to a kitchen where I can prepare 20+ different types of food and reach to 10k customers around VIC."
                        </h3>
                        <p style={{marginRight:60}}>
                           Aimee, Manger Doreen General Store
                        </p>
                    </Col>

                </Row>

            </div>
        )
    }




}

export default TestimonialComponent;