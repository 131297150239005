import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Bar } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage,deleteFromLocalStorage } from '../../store'




class AdminOperatorsScreen extends Component {
  state = {
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    totalSales: 0,
    avgOrder: 0,
    numOrders: 0,
    adminExternalOperator: getFromLocalStorage("adminExternalOperator") != "undefined" && getFromLocalStorage("adminExternalOperator") != "" && getFromLocalStorage("adminExternalOperator") != null ? getFromLocalStorage("adminExternalOperator") : 0,
    salesByOperatorsData: [],
    externalOperators:new Array(),
    salesByLocationLables: [],
    salesByLocationId: [],
    salesByLocationData: [],
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
  }

  

  componentDidMount() {
    this.getExternalOperators()
    this.getReportsData()
    const {
      isSuperAdminLoggedIn
    } = this.state
    if(isSuperAdminLoggedIn!='true'){
      window.location.href='/admin'
    }
    
  }

  setStartDate = (date) => {
    this.setState({ startDate: date },()=>{
      saveToLocalStorage("startDate",this.state.startDate)
    })
    this.setState({ endDate: date },()=>{
      saveToLocalStorage("endDate",this.state.endDate)
    })
    
  }

  setEndDate = (date) => {
    this.setState({ endDate: date },()=>{
      saveToLocalStorage("endDate",this.state.endDate)
    })
    
  }


  getReportsData() {
    const {
      startDate,
      endDate,
      adminExternalOperator

    } = this.state

    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify(
      {
        "query_type": "reports_data",
        "external_operator": parseInt(adminExternalOperator),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        
        console.log(dataResponse)
        if (dataResponse.success) {
          console.log(dataResponse.sales_by_operator)
          var salesByLocationArray = dataResponse.sales_by_operator.sales_by_location
          var salesByLocationLablesTemp = []
          var salesByLocationDataTemp = []
          var salesByLocationIdTemp = []
         
          for (var i = 0; i < salesByLocationArray.length; i++) {
            salesByLocationLablesTemp.push(salesByLocationArray[i]["location"])
            salesByLocationIdTemp.push(salesByLocationArray[i]["id"])
            if (salesByLocationArray[i]["total_sales"] != null) {
              salesByLocationDataTemp.push(salesByLocationArray[i]["total_sales"])
            }
            else {
              salesByLocationDataTemp.push(0)
            }

          }

          this.setState({
            salesByOperatorsData:dataResponse.sales_by_operator,
            avgOrder: dataResponse.sales_by_operator.avg_order,
            totalSales: dataResponse.sales_by_operator.total_sales,
            numOrders: dataResponse.sales_by_operator.num_orders,
            salesByLocationLables: salesByLocationLablesTemp,
            salesByLocationId: salesByLocationIdTemp,
            salesByLocationData: salesByLocationDataTemp,
          })
        }
        else{
          this.setState({
            salesByOperatorsData:new Array(),
            avgOrder: 0,
            totalSales: 0,
            numOrders: 0
          })
        }
      })
      .catch(err => { console.log(err) })

  }

  getExternalOperators(){
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify(
      {
        "query_type": "external_operators"
      }
    );
   

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        console.log(dataResponse)
        if (dataResponse.success) {
          this.setState({externalOperators:dataResponse.external_operators})
        }
      })
      .catch(err => { console.log(err) })
  }

  onChangeExternalOperator(e){
    
    this.setState({'adminExternalOperator':e.target.value},()=>{
      saveToLocalStorage("adminExternalOperator",this.state.adminExternalOperator)
      this.getReportsData()
    })
    
  
  }

  getBackgroundColor(length){
    var bgColor=[]
    for (var k=0;k<length;k++){
      var rgb = [];
      for(var i = 0; i < 3; i++)
        rgb.push(Math.floor(Math.random() * 255));
      bgColor.push('rgb('+ rgb.join(',') +')')
    }
    
    return bgColor;
  }




  render() {
    const {
      salesByOperatorsData,
      externalOperators,
      adminExternalOperator,
      startDate,
      endDate,
      totalSales,
      numOrders,
      avgOrder,
      salesByLocationId,
      salesByLocationLables,
      salesByLocationData,
      isSuperAdminLoggedIn,
      stockAdminLoggedIn
    } = this.state

    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn}  stockAdminLoggedIn={stockAdminLoggedIn}></AdminNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 10 }}>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={6} lg={6}>
              
                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label>External Operators {'(' + externalOperators.length + ')'}</Form.Label>
                  {externalOperators && externalOperators.length > 0 ?
                    <Form.Control as="select" size="sm" custom onChange={this.onChangeExternalOperator.bind(this)} defaultValue={adminExternalOperator}>
                      <option value={0}>Select an operator</option>
                      {externalOperators.map((location, i) => {
                        return (
                          <option key={i} value={externalOperators[i].id}>{externalOperators[i].business_name}</option>

                        )
                      })}
                    </Form.Control>
                    : null}
                </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button onClick={() => { this.getReportsData() }}>Apply</Button>
                </Col>

              </Row>
            </Col>
          </Row>
          {salesByOperatorsData && salesByLocationData?
          <>
          <Row style={{ marginTop: 10, borderBottomWidth: 1, borderBottomColor: 'grey', borderBottomStyle: 'solid' }}>
            <Col xs={12} md={4} lg={4}>
              <h4>Total Sales: ${Number(totalSales).toFixed(2)}</h4>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <h4>Number of Orders: {numOrders}</h4>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <h4>Avg Order: ${Number(avgOrder).toFixed(2)}</h4>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={12} lg={12}>
             
              <Bar
                data={{
                  labels: salesByLocationLables,
                  datasets: [{
                    label: 'Sales by locations ($)',
                    data: salesByLocationData,
                    backgroundColor: this.getBackgroundColor(salesByLocationLables.length),
                   
                    borderWidth: 1
                  }]
                }}
                height={400}
                width={600}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [{
                      ticks: {
                        beginAtZero: true
                      }
                    }]
                  }
                }}
              />
              
              
            </Col>
          </Row>
          </>
          
          :null}
        </Container>
      </div>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(AdminOperatorsScreen);



