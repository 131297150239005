import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


class StockAdminUsageReportScreen extends Component {

  state = {
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    adminKitchacoLocation: getFromLocalStorage("adminKitchacoLocation") != "undefined" && getFromLocalStorage("adminKitchacoLocation") != "" && getFromLocalStorage("adminKitchacoLocation") != null ? getFromLocalStorage("adminKitchacoLocation") : 0,
    kitchacoLocations: [],
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    stockAdminLoggedIn: getFromLocalStorage("stockAdminLoggedIn"),
    isOperatorLoggedIn: false,
    mealsSold: [],
    qtyTypes: [],
    isLoading: false,
    deletedBundle: [],
    isUpdatingItem: false,

  }

  componentDidMount() {
    const {
      isSuperAdminLoggedIn
    } = this.state
    this.getKitchacoLocationData()
    if (isSuperAdminLoggedIn == "true") {
      this.getStockUsageData()
    }
    else {
      window.location.href = '/admin'
    }

  }

  getStockUsageData() {
    const {
      startDate,
      endDate,
      adminKitchacoLocation

    } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "stock_usage_report",
        "kitchaco_location": parseInt(adminKitchacoLocation),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY")
      }
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        if (dataResponse.success) {
          var mealSoldArray = dataResponse.meal_sold.filter((v, i, a) => a.findIndex(t => (t.meal_name === v.meal_name)) === i)
          this.setState({ mealsSold: mealSoldArray, qtyTypes: dataResponse.qty_type, stockList: dataResponse.stock_list })
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
      })

  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }


  onChangeKitchacoLocation(e) {

    this.setState({ 'adminKitchacoLocation': e.target.value }, () => {
      saveToLocalStorage("adminKitchacoLocation", this.state.adminKitchacoLocation)
      this.getStockUsageData()
    })


  }





  getKitchacoLocationData() {
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "kitchaco_locations"
      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        if (dataResponse.success) {
          this.setState({ kitchacoLocations: dataResponse.kitchaco_locations })
        }
      })
      .catch(err => { console.log(err) })
  }




  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "admin_login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          if (result.data.is_super_admin) {
            this.setState({ isSuperAdminLoggedIn: "true" }, () => {
              saveToLocalStorage("isSuperAdminLoggedIn", "true")
              saveToLocalStorage("superAdminEmail", this.state.email)
              saveToLocalStorage("superAdminPassword", this.state.password)
              this.getStockUsageData()
            })
          }
          else {
            alert("You don't have access to this page! Please try again!")
            deleteFromLocalStorage("isSuperAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminLoggedIn", "false")
            deleteFromLocalStorage("stockAdminEmail", "")
            deleteFromLocalStorage("stockAdminPassword", "")
            window.location.href = '/admin'
          }


        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }


  deleteBundle = (id) => {
    const { deletedBundle } = this.state
    var tempDeletedBundle = deletedBundle
    tempDeletedBundle.push(id)
    this.setState({ deletedBundle: tempDeletedBundle })
  }

  restoreBundle = (id) => {
    const { deletedBundle } = this.state
    var tempDeletedBundle = deletedBundle
    tempDeletedBundle = tempDeletedBundle.filter(function (item) {
      return item !== id
    })
    this.setState({ deletedBundle: tempDeletedBundle })
  }



  render() {
    const {
      isSuperAdminLoggedIn
    } = this.state
    console.log("called ")
    return (
      <>

        {
          isSuperAdminLoggedIn == "true" ?
            this.renderMainComponent()
            :
            this.renderLoginComponent()
        }
      </>
    )
  }

  renderLoginComponent() {
    const {
      email,
      password,
      isSuperAdminLoggedIn
    } = this.state
    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { this.setState({ email: text.target.value }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text.target.value }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
          </Button>

        </Container>
      </div>
    )
  }


  renderMainComponent() {
    const {
      startDate,
      endDate,
      adminKitchacoLocation,
      kitchacoLocations,
      isSuperAdminLoggedIn,
      mealsSold,
      qtyTypes,
      stockList,
      isLoading,
      deletedBundle,
      isUpdatingItem
    } = this.state
    console.log("called maincomponent")
    return (
      <div>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 10 }}>
          <Row style={{ marginTop: 10 }}>
            <Col xs={12} md={6} lg={6}>
              <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                <Form.Label>Kitchaco Locations {'(' + kitchacoLocations.length + ')'}</Form.Label>
                {kitchacoLocations && kitchacoLocations.length > 0 ?
                  <Form.Control as="select" size="sm" custom onChange={this.onChangeKitchacoLocation.bind(this)} defaultValue={adminKitchacoLocation}>
                    <option value={0}>All</option>
                    {kitchacoLocations.map((location, i) => {
                      return (
                        <option key={i} value={kitchacoLocations[i].id}>{kitchacoLocations[i].location}</option>

                      )
                    })}
                  </Form.Control>
                  : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Row style={{ marginTop: 10 }}>
                <Col xs={4} md={4} lg={4}>
                  <p>Start Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={date => this.setStartDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>End Date</p>
                  <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={date => this.setEndDate(date)} />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <p>&nbsp;</p>
                  <Button onClick={() => { }}>Apply</Button>
                </Col>

              </Row>
            </Col>
          </Row>
          {console.log("called maincomponent kitchaolocations 1")}
          {isLoading == false ?
            <div>
              <Row className="table-header-row">
                <Col xs={12} md={2} lg={2} className="table-header-col" >
                  Meal Id
                </Col>
                <Col xs={12} md={8} lg={8} className="table-header-col" >
                  Meal Name
                </Col>
                <Col xs={12} md={2} lg={2} className="table-header-col">
                  Qty
                </Col>
              </Row>
              {console.log('called maincomponent total meals ', mealsSold.length)}
              {mealsSold.map((item, index) => {
                console.log('called meal index', index)
                return (
                  <div key={item.id + '_' + index}>
                    <Accordion>
                      <>
                        <Accordion.Toggle as={Card.Header} eventKey={'meal_' + item.id}>
                          <Row className="table-row">
                            <Col xs={12} md={2} lg={2} className="table-col">
                              <span style={item.id == -1 ? { color: 'red' } : null}><b className="mobile-only">Meal Id:</b>
                                {item.id}
                                {item.id == -1 ?
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                      This meal item was not found in the Kitchaco System. This means that the name of this item is not same across different delivery platforms. To fix it please change the name across all the delivery platforms
                                    </Tooltip>}
                                  >
                                    <img style={{ width: 15, marginLeft: 5 }} src={require('../../assets/img/icon_info.png')}></img>
                                  </OverlayTrigger>

                                  : null}

                              </span>
                            </Col>
                            <Col xs={12} md={8} lg={8} className="table-col">
                              <span style={item.id == -1 ? { color: 'red' } : null}><b className="mobile-only">Meal Name:</b> {item.meal_name}</span>
                            </Col>
                            <Col xs={12} md={2} lg={2} className="table-col">
                              <span style={item.id == -1 ? { color: 'red' } : null}><b className="mobile-only">Qty:</b> {item.quantity}</span>
                            </Col>
                          </Row>

                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={'meal_' + item.id} style={{ padding: 20, backgroundColor: '#eff2f5' }}>
                          <div>
                            {isUpdatingItem == false ?
                              <>
                                <Row>
                                  <Col xs={12} md={12} lg={12}  >
                                    <h5>Stock Bundle</h5>
                                    <p>1. If you feel that the stock Bundle for the selected item is incorrect you ca fix it by changing it below.</p>
                                    <p>2. Keep in mind that the stock bundle that is added is for 1x {item.meal_name} </p>
                                  </Col>
                                </Row>
                                <Row className="table-header-row">
                                  <Col className="desktop-only" xs={12} md={2} lg={2} className="table-header-col" >
                                    Qty
                                  </Col>
                                  <Col xs={12} md={2} lg={2} className="table-header-col" >
                                    Qty Type
                                  </Col>
                                  <Col xs={12} md={6} lg={6} className="table-header-col">
                                    Stock Item Name
                                  </Col>
                                  <Col xs={12} md={1} lg={1} className="table-header-col">

                                  </Col>
                                  <Col xs={12} md={1} lg={1} className="table-header-col">

                                  </Col>
                                </Row>
                                {console.log("called meal stock bundle length", item.stock_bundle.length)}
                                {item.stock_bundle.map((stock, i) => {
                                  console.log('called meal stock bundle ', i)
                                  return (
                                    <div key={'bundle_' + index + '' + i}>
                                      {deletedBundle.indexOf('bundle_' + index + '' + i) == -1 ?
                                        <>
                                          <Row className="table-row">

                                            <Col xs={12} md={2} lg={2} className="table-col">
                                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="Enter qty" value={stock.qty} readOnly  />
                                              </Form.Group>

                                            </Col>
                                            <Col xs={12} md={2} lg={2} className="table-col">
                                              <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Control type="text" placeholder="-" value={stock.stock_list__qty_type} readOnly />
                                              </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6} lg={6} className="table-col">
                                              <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                  <Form.Control type="text" placeholder="-" value={stock.stock_list__name} readOnly onChange={() => { }} />
                                                </Form.Group>
                                              </Form.Group>

                                            </Col>

                                            <Col xs={12} md={1} lg={1} className="table-col" style={{cursor:'pointer'}} onClick={() => { window.location.href=window.location.href = '/stockbundle-edit?stock_id=' + stock.stock_list__id+'&qty='+stock.qty+'&meal_id='+item.id+'&meal_name='+item.meal_name+'&stock_bundle_id='+stock.id}}>
                                              <img  style={{ width: 15, marginLeft: 5 }} src={require('../../assets/img/icon_edit.png')}></img>
                                            </Col>
                                            <Col xs={12} md={1} lg={1} className="table-col" style={{cursor:'pointer'}} onClick={() => { this.deleteBundle('bundle_' + index + '' + i) }}>
                                              <img  style={{ width: 15, marginLeft: 5 }} src={require('../../assets/img/icon_delete.png')}></img>
                                            </Col>
                                          </Row>
                                        </>
                                        :
                                        null
                                      }

                                    </div>
                                  )
                                })}

                                <Row style={{ marginTop: 10 }}>
                                  <Col xs={10} md={10} lg={10}>
                                    
                                  </Col>
                                  <Col xs={2} md={2} lg={2}>
                                    {item.id != -1 ? <Button onClick={() => { window.location.href = '/stock-admin-stockbundle-add/?meal_id=' + item.id + '&meal_name=' + item.meal_name }}>Add</Button> : null}

                                  </Col>
                                </Row>
                              </>
                              : <div style={{ textAlign: 'center' }}>
                                <Spinner animation="border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              </div>}
                          </div>
                        </Accordion.Collapse>
                      </>
                    </Accordion>
                  </div>

                )
              })}
            </div>
            :
            <div style={{ textAlign: 'center' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>}
        </Container>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockAdminUsageReportScreen);



