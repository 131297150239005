import React, { Component } from 'react';
import { connect } from 'react-redux'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import config from '../../services/apiConfig';
import { apiPaths } from '../../services/apiPath';
import './style.css'
import { getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store';
import moment from "moment"
import OperatorNavBar from '../../components/UI/OperatorNavBar';
import Modal from 'react-bootstrap/Modal'


class StockScreen extends Component {


  state = {
    stockUserLoggedIn: getFromLocalStorage("stockLoggedIn"),
    isOperatorLoggedIn: getFromLocalStorage("isOperatorLoggedIn"),
    stockList: new Array(),
    stockUserData: getFromLocalStorage("stockUserData") && JSON.parse(getFromLocalStorage("stockUserData")) ? JSON.parse(getFromLocalStorage("stockUserData")) : new Array(),
    quantitiesArray: [],
    email: getFromLocalStorage("stockEmail") && getFromLocalStorage("stockEmail") != '' ? getFromLocalStorage("stockEmail") : '',
    password: getFromLocalStorage("stockPassword") && getFromLocalStorage("stockPassword") != '' ? getFromLocalStorage("stockPassword") : '',
    operatorEmail: getFromLocalStorage("operatorEmail") && getFromLocalStorage("operatorEmail") != '' ? getFromLocalStorage("operatorEmail") : '',
    operatorPassword: getFromLocalStorage("operatorPassword") && getFromLocalStorage("operatorPassword") != '' ? getFromLocalStorage("operatorPassword") : '',
    isPlacingOrder: false,
    reminderData: new Array(),
    gstArray: [],
    showMinOrderDeliveryChargeModal: false,
    deliveryChargeOverride: false,
  }




  login = () => {
    const { email, password } = this.state
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({ "email": email, "password": password, "query_type": "admin_login" });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.admin.adminData, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("stockUserData", result)
        if (result.success) {
          if (result.data.is_stock_user) {
            this.setState({ stockUserLoggedIn: "true", stockUserData: result.data }, () => {
              saveToLocalStorage("stockLoggedIn", "true")
              saveToLocalStorage("stockEmail", email)
              saveToLocalStorage("stockPassword", password)
              saveToLocalStorage("stockUserData", JSON.stringify(result.data))
              this.fetchStockListFromServer()
            })
          }
          else if (result.data.is_super_admin == true) {
            alert("Error! Please login using the super admin login page!")
          }
          else {
            alert("Authentication Error! Please try again.")
          }
        }
        else {
          alert(result.error)
        }
      })
      .catch(error => console.log('error', error));
  }




  placeOrder = () => {
    const { isPlacingOrder, quantitiesArray,
      stockList,
      stockUserData,
      gstArray,
      email,
      password,
      operatorEmail,
      operatorPassword,
      deliveryChargeOverride,
    } = this.state
    this.setState({ isPlacingOrder: true })
    var todayDate = moment(new Date());
    var todayDay = moment(todayDate).format('dddd');






    var delivery_days = (stockUserData["delivery_day"].toString()).split(",");

    console.log(delivery_days)




    var stockData = []
    var sub_total = 0
    var delivery_charge = 0
    var total_gst = 0
    var total = 0

    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0
      stockitem_sub_total = Number(Number(stockList[key].price) * Number(quantitiesArray[key])).toFixed(2)
      var stockitem_total_gst = 0
      if (stockList[key].has_gst) {
        stockitem_total_gst = Number(Number(.10 * stockList[key].price) * Number(quantitiesArray[key])).toFixed(3)
      }
      var stockitem_total = Number(Number(stockitem_sub_total) + Number(stockitem_total_gst)).toFixed(3)

      sub_total = Number(sub_total) + Number(stockitem_sub_total)
      if (stockList[key].has_gst) {
        total_gst = Number(total_gst) + Number(stockitem_total_gst)
      }

      total = Number(total) + Number(stockitem_sub_total) + Number(stockitem_total_gst)






      var obj = {
        "id": stockList[key].id,
        "stock_list_id": stockList[key].id,
        "name": stockList[key].name,
        "qty": quantitiesArray[key],
        "qty_type": stockList[key].qty_type__id,
        "price": stockList[key].price,
        "par": stockList[key].par,
        "sub_total": stockitem_sub_total,
        "gst": stockitem_total_gst,
        "total": stockitem_total

      }
      stockData.push(obj)
    }



    var orderData = {
      "kitchaco": stockUserData["kitchaco__id"],
      "order_date": moment(new Date()).format("DD MMM YYYY"),
      "order_time": moment(new Date()).format("hh:mm A"),
      "sub_total": Number(sub_total).toFixed(2),
      "gst": Number(total_gst).toFixed(2),
      "total": Number(total).toFixed(2),
      "stockData": stockData
    }

    console.log("Order Data", orderData)

    if (stockData.length > 0 && Number(total) > 0) {
      var data = {}

      if (email != "" && password != "") {
        data = { "email": email, "password": password, "orderData": orderData, "query_type": "place_order" }
      }
      else if (operatorEmail != "" && operatorPassword != "") {
        data = { "email": operatorEmail, "password": operatorPassword, "orderData": orderData, "query_type": "place_order" }
      }

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(data);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(config.BASE_URI + apiPaths.stockOrder, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({ isPlacingOrder: false })
          if (result.success) {
            alert("Order placed successfully! Your delivery will be done on next " + stockUserData["delivery_day"] + "." + " We will send you an email once it is approved.")
            window.location.reload()
          }
          else {
            alert(result.error)
            if (result.error_code == 1) {
              this.setState({ showMinOrderDeliveryChargeModal: true })
            }

          }
        })
        .catch(error => {
          this.setState({ isPlacingOrder: false })
          console.log('error', error)
        });
    }
    else {
      this.setState({ isPlacingOrder: false })
      alert("Please select altleast 1 item to order")
    }

  }

  fetchStockListFromServer = () => {
    const { stockUserData, email, password, operatorEmail, operatorPassword } = this.state
    console.log(stockUserData["kitchaco__id"])
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = ''

    if (email != "" && password != "") {
      raw = JSON.stringify({ "email": email, "password": password, "kitchaco": stockUserData["kitchaco__id"] });
    }
    else if (operatorEmail != "" && operatorPassword != "") {
      raw = JSON.stringify({ "email": operatorEmail, "password": operatorPassword, "kitchaco": stockUserData["kitchaco__id"] });
    }

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(config.BASE_URI + apiPaths.stockList, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          console.log('stock ', result)
          this.setState({ stockList: result.data, reminderData: result.reminder_data })
        }
      })
      .catch(error => console.log('error', error));
  }

  handleChange(i, hasGST, price, e) {
    console.log(price)
    this.setState({
      quantitiesArray: { ...this.state.quantitiesArray, [i]: e.target.value }
    });

    if (hasGST) {
      this.setState({
        gstArray: { ...this.state.gstArray, [i]: Number(Number(e.target.value) * (.10 * Number(price))).toFixed(3) }
      });
    }

  }



  componentDidMount() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn
    } = this.state
    if (stockUserLoggedIn == "true" || isOperatorLoggedIn == "true") {
      this.fetchStockListFromServer()
    }


  }



  render() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn
    } = this.state
    return (
      <div>
        {this.renderNavBar()}
        {
          stockUserLoggedIn == "true" || isOperatorLoggedIn == "true" ?
            this.renderHomePage()
            :
            this.renderLoginPage()
        }
        <Container>

        </Container>
      </div>
    )
  }

  renderNavBar() {
    const {
      stockUserLoggedIn,
      isOperatorLoggedIn,
      stockUserData
    } = this.state
    return (
      <OperatorNavBar isOperatorLoggedIn={isOperatorLoggedIn} stockUserLoggedIn={stockUserLoggedIn} ></OperatorNavBar>
    )
  }

  renderLoginPage() {
    const {
      email,
      password
    } = this.state
    return (
      <div>
        <Container>
          <Form.Group >
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" value={email} onChange={(text) => { console.log("hello ", text.target.value); this.setState({ email: text.target.value }) }} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" value={password} onChange={(text) => { this.setState({ password: text.target.value }) }} />
          </Form.Group>

          <Button onClick={() => { this.login() }} variant="primary" type="submit">
            Login
          </Button>

        </Container>
      </div>
    )
  }

  renderHomePage() {
    const {
      stockList,
      isPlacingOrder,
      reminderData,
      stockUserData,
      quantitiesArray,
      deliveryChargeOverride
    } = this.state
    console.log("reminder ", reminderData)
    var total = 0
    var sub_total = 0
    var total_gst = 0

    for (var key in quantitiesArray) {
      var stockitem_sub_total = 0
      stockitem_sub_total = Number(Number(stockList[key].price) * Number(quantitiesArray[key])).toFixed(2)
      var stockitem_total_gst = 0
      if (stockList[key].has_gst) {
        stockitem_total_gst = Number(Number(.10 * stockList[key].price) * Number(quantitiesArray[key])).toFixed(3)
      }


      sub_total = Number(sub_total) + Number(stockitem_sub_total)
      if (stockList[key].has_gst) {
        total_gst = Number(total_gst) + Number(stockitem_total_gst)
      }

      total = Number(total) + Number(stockitem_sub_total) + Number(stockitem_total_gst)


      if (deliveryChargeOverride == true) {
        total = Number(total) + Number(stockUserData["min_stock_delivery_charge"])
      }
    }


    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} className="header">
              <h2>Stock Ordering System</h2>
              <h3>Kitchaco Location: {stockUserData["kitchaco__location"]}</h3>
            </Col>
          </Row>
          {reminderData.length > 0 ?
            <>
              <Row>
                <Col xs={12}>
                  <h6>Following items are about to be expired. Please order them in your next order - </h6>
                </Col>
              </Row>
              {reminderData.map((item) => {
                return (
                  <Row>
                    <Col xs={12}>
                      <h6>{item["name"] + ' expiring on ' + item["expiring_on"]} </h6>
                    </Col>
                  </Row>
                )
              })}
              <Row>
                <Col xs={12}>
                  <p>&nbsp;</p>
                  <h6><b>Recommended quantities are shown based on your previous order over 16 week period.</b> </h6>
                </Col>
              </Row>

            </>
            : null
          }
          <Row className="table-header-row" style={{ marginTop: 10 }}>
            <Col xs={12} md={1} lg={1} className="table-header-col" >
              Id
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col" >
              Product Name
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Par
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Qty
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Recommended
            </Col>
            <Col xs={12} md={1} lg={1} className="table-header-col">
              Price
            </Col>
            <Col xs={12} md={2} lg={2} className="table-header-col">
              Sub Total
            </Col>
          </Row>
          {stockList.map((item, i) => {
            var gst = 0.00
            if (this.state.gstArray[i]) {
              gst = this.state.gstArray[i]
            }

            return (
              <>
                <Row key={i} className="table-row">
                  <Col xs={12} md={1} lg={1} className="table-col">
                    <h4 className="desktop-only">{item.id}</h4>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <h4>{item.name + " (" + item.qty_type__qty_type + ") "}</h4>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <span><b className="mobile-only">Par:</b> {item.par}</span>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <Form.Group>
                      <Form.Control type="email" placeholder="Quantity Needed" id={"qty_" + i} value={this.state.quantitiesArray[i]}
                        name={this.state.quantitiesArray[i]}
                        onChange={this.handleChange.bind(this, i, item.has_gst, item.price)} />
                      <Form.Text className="text-muted">
                        {item.qty_type__qty_type}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    <span><b className="mobile-only">Recommended Qty:</b> {item.recommended_order?item.recommended_order:'Ordered before '+moment(new Date()).subtract(112,'days').format('DD MMM YYYY')}</span>
                  </Col>

                  <Col xs={12} md={1} lg={1} className="table-col">
                    {item.has_gst ?
                      <span><b className="mobile-only">Price:</b> ${item.price + ' + GST'} </span>
                      :
                      <span><b className="mobile-only">Price:</b> ${item.price} </span>}
                  </Col>
                  <Col xs={12} md={2} lg={2} className="table-col">
                    {item.has_gst ?
                      <span><h4><b className="mobile-only">Sub Total:</b> ${document.getElementById("qty_" + i) ? Number(Number(document.getElementById("qty_" + i).value) * Number(item.price)).toFixed(2) + ' +  ' + gst : 0.00}</h4></span>
                      :
                      <span><h4><b className="mobile-only">Sub Total:</b> ${document.getElementById("qty_" + i) ? Number(Number(document.getElementById("qty_" + i).value) * Number(item.price)).toFixed(2) : 0.00}</h4></span>}
                  </Col>

                </Row>

              </>

            )
          })}
          <Row className="table-row">
            <Col xs={12} md={1} lg={1} className="table-col">

            </Col>
            <Col xs={12} md={3} lg={3} className="table-col">

            </Col>
            <Col xs={12} md={2} lg={2} className="table-col">

            </Col>
            <Col xs={12} md={2} lg={2} className="table-col">

            </Col>

            <Col xs={12} md={2} lg={2} className="table-col">
              <span><h4><b className="desktop-only">Total:</b></h4></span>
            </Col>
            <Col xs={12} md={2} lg={2} className="table-col">
              <span><h4><b className="mobile-only">Total:</b> ${Number(total).toFixed(2)}</h4></span>
            </Col>

          </Row>
          <Row className="button-row">
            <Col xs={12}>
              <p>Any order below ${Number(stockUserData["min_stock_order"]).toFixed(2)} will incur a below minimum order fee of ${Number(stockUserData["min_stock_delivery_charge"]).toFixed(2)}  </p>
            </Col>
            <Col xs={12} >
              <Button
                onClick={() => {
                  if (!isPlacingOrder) {
                    this.placeOrder()
                  }
                }}>
                {!isPlacingOrder ? 'Place Order' : 'Loading...'}
              </Button>
            </Col>
          </Row>

        </Container>
      </div>
    )
  }


  renderMinOrderDeliveryChargeModal() {
    const {
      showMinOrderDeliveryChargeModal,
      stockUserData,
      isPlacingOrder,
    } = this.state
    return (
      <Modal show={showMinOrderDeliveryChargeModal} onHide={() => { this.setState({ showMinOrderDeliveryChargeModal: false }) }}>
        <Modal.Header>
          <Modal.Title>Place order with addtional delivery charges?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', width: '90%', margin: 'auto' }}>
          <p>You can still place the order by paying an additional delivery charge of ${Number(stockUserData["min_stock_delivery_charge"]).toFixed(2)}.</p>
          <p>Do you want to add the delivery charge and place order?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!isPlacingOrder) {
                this.placeOrder()
              }
            }}>
            {!isPlacingOrder ? 'Place Order with addtional charges' : 'Loading...'}
          </Button>

          <Button
            onClick={() => {
              this.setState({ showMinOrderDeliveryChargeModal: false })
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }



}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    stockUserLoggedIn: state.appReducer.stockUserLoggedIn,
    smsSent: state.appReducer.smsSent,
    showMinOrderDeliveryChargeModal: state.appReducer.showMinOrderDeliveryChargeModal,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(StockScreen);



