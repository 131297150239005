export const apiPaths = {
    onboarding: '/api/v1/onboarding-screens/',
    login: {
      sendCode: '/api/v1/send-sms/',
      verifyNumber: '/api/v1/login-phone/',
      createUser: '/api/v1/customers/',
      tokenAuth: '/api/v1/login-facebook/',
    },
    profile: '/api/v1/customers/',
    logOut: '/api/v1/logout/',
    explore: {
      trucksList: '/api/v1/vendors/',
      filterList: '/api/v1/vendor-filters/',
      orders: '/api/v1/orders/',
      cateringPackages: '/api/v1/catering-package',
      events: "/api/v1/public-event/",
      combo: "/api/v1/meal-combo/",
      delivery: "/api/v1/delivery-charge/",
      location: "/api/v1/kitchaco-location/",
    },
    calculateDelivery:"/api/v1/calculate-distance/",
    payment:"/api/v1/payment/",
    promoCode:"/api/v1/promo-code/",
    deliveryDriver:"/api/v1/delivery-driver/",
    validatePromoCode:"/api/v1/validate-promo/",
    trackDriver:"/api/v1/driver-delivery-journey/",
    stockList:"/api/v1/stock-list/",
    loginVendor:"/api/v1/login-vendor/",
    stockOrder:"/api/v1/stock-order-list/",
    stockAdmin:"/api/v1/stock-admin-user/",
    kitchacoLocations:"/api/v1/admin-data/",
    admin: {
      adminData:"/api/v1/admin-data/",
      salesReport:"/api/v1/generate-sales-invoice/",
    }

  };
  