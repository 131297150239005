import React from 'react';
import ReactDOM from 'react-dom';
import Navbar from 'react-bootstrap/Navbar'
import OverLay from './OverLay';
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'

class NavBar extends React.Component {
  onLinkClick = (id) => {
    document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });;
}

  render() {
        return (
            <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: 'white', color: 'black' }}>
           
            <Navbar.Brand href="/" style={{color:'#594F9B',textTransform:'uppercase'}}><img style={{width:200}} src={require('../assets/img/logo-landscape.png')}></img></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link style={{color:'black'}}  onClick={()=>{this.onLinkClick('why-multibrands')}}>Why Multibrands</Nav.Link>
                <Nav.Link style={{color:'black'}}  onClick={()=>{this.onLinkClick('what-customers-say')}}>What customers say</Nav.Link>
                <Nav.Link style={{color:'black'}}  onClick={()=>{this.onLinkClick('what-is-the-process')}}>What's the process</Nav.Link>
                <Nav.Link style={{color:'black'}} className="margin-left-button" href="#pricing" onClick={()=>{this.onLinkClick('join-us')}} style={{backgroundColor:'#594F9B',padidng:5,color:'white',borderRadius:40,borderWidth:1,borderColor:'#594F9B',borderStyle:'solid',paddingLeft:15,paddingRight:15,textAlign:'center'}}>Join Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
           
          </Navbar>


        );
    }
}

export default NavBar;