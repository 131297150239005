import React, { Component } from 'react';

import {
    Col,
    Row,
    InputGroup,
    FormControl,
    Button,
    Image
} from 'react-bootstrap';

import './style.css'

class WhyChooseComponent extends Component {


    render() {
        return (
            <div id="why-multibrands" style={{ backgroundColor: '#FFFFFF', color: 'black', paddingTop: 40, paddingBottom: 40 }}>
                <Row>
                    <Col xs={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                        <h2>Why customers choose Multibrands</h2>
                    </Col>
                </Row>
                <Row style={{ marginTop: 40 }}>
                    <Col xs={12} md={6} lg={6} className="why-choose-ellipse">
                        <div style={{ paddingTop: 20,paddingBottom: 20, backgroundColor: '#DEDCEB', borderRadius: 20, borderWidth: 1, borderColor: 'transparent', borderStyle: 'solid' }}>
                            <Row style={{margin:0, display: 'table', height: '100%', overflow: 'hidden'}}>
                                <Col xs={8} md={8} lg={8} style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft:24 }}>
                                    <h4>
                                        Low risk and Low Cost

                                    </h4>
                                    <p>Set up & ready to serve within weeks by utilising your current venue, staff, facilities & equipment.</p>
                                </Col>
                                <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                                    <div style={{ width:'100%' }}>
                                        <Image className="why-choose-ellipse-img" src={require('../../../assets/img/why-choose-ellipse-1.png')}></Image>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} >
                        <div style={{ paddingTop: 20,paddingBottom: 20, backgroundColor: '#FBEBE8', borderRadius: 20, borderWidth: 1, borderColor: 'transparent', borderStyle: 'solid' }}>
                            <Row style={{margin:0, display: 'table', height: '100%', overflow: 'hidden'}}>
                                <Col xs={8} md={8} lg={8} style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft:24 }}>
                                    <h4>
                                    Lower cost of goods

                                    </h4>
                                    <p>By accessing our stock chain, you retain the pricing & margins of consistent COGs - increasing your ROI per item.</p>
                                </Col>
                                <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                                    <div style={{ width:'100%' }}>
                                        <Image className="why-choose-ellipse-img" src={require('../../../assets/img/why-choose-ellipse-2.png')}></Image>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </Col>

                </Row>
                <Row style={{ marginTop: 40 }}>
                    <Col xs={12} md={6} lg={6}  className="why-choose-ellipse">
                        <div style={{ paddingTop: 20,paddingBottom: 20, backgroundColor: '#FBEBE8', borderRadius: 20, borderWidth: 1, borderColor: 'transparent', borderStyle: 'solid' }}>
                            <Row style={{margin:0, display: 'table', height: '100%', overflow: 'hidden'}}>
                                <Col xs={8} md={8} lg={8} style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft:24 }}>
                                    <h4>
                                    Increase revenue

                                    </h4>
                                    <p>By multiplying your cuisine offerings you automatically strenghten your ability to increase your ROI, without neccesarily increasing labour costs.</p>
                                </Col>
                                <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                                    <div style={{ width:'100%' }}>
                                        <Image className="why-choose-ellipse-img" src={require('../../../assets/img/why-choose-ellipse-1.png')}></Image>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6}  className="why-choose-ellipse">
                        <div style={{ paddingTop: 20,paddingBottom: 20, backgroundColor: '#DEDCEB', borderRadius: 20, borderWidth: 1, borderColor: 'transparent', borderStyle: 'solid' }}>
                            <Row style={{margin:0, display: 'table', height: '100%', overflow: 'hidden'}}>
                                <Col xs={8} md={8} lg={8} style={{ display: 'table-cell', verticalAlign: 'middle', paddingLeft:24 }}>
                                    <h4>
                                    Multiple platforms
                                    </h4>
                                    <p>Your shopfronts will be accessible via UberEats, Deliveroo & KitchaCo; with Menulog & DoorDash joining soon.*</p>
                                </Col>
                                <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                                    <div style={{ width:'100%' }}>
                                        <Image className="why-choose-ellipse-img" src={require('../../../assets/img/why-choose-ellipse-2.png')}></Image>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                    </Col>

                </Row>
            </div>
        )
    }




}

export default WhyChooseComponent;