import React, { Component } from 'react';
import { connect } from 'react-redux'
import './style.css';
import 'date-fns';
import AdminNavBar from '../../components/UI/AdminNavBar/index';
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import { Bar } from 'react-chartjs-2'
import DatePicker from "react-datepicker";
import moment from 'moment';
import config from '../../services/apiConfig';
import "react-datepicker/dist/react-datepicker.css";
import { apiPaths } from '../../services/apiPath';
import { store, getFromLocalStorage, saveToLocalStorage, deleteFromLocalStorage } from '../../store'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'



class AdminTxnReportScreen extends Component {

  state = {
    superAdminEmail: getFromLocalStorage("superAdminEmail") && getFromLocalStorage("superAdminEmail") != '' ? getFromLocalStorage("superAdminEmail") : '',
    adminExternalOperator: getFromLocalStorage("adminExternalOperator") != "undefined" && getFromLocalStorage("adminExternalOperator") != "" && getFromLocalStorage("adminExternalOperator") != null ? getFromLocalStorage("adminExternalOperator") : 0,
    adminSalesInvoiceFortnightPeriod: getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "undefined" && getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != "" && getFromLocalStorage("adminSalesInvoiceFortnightPeriod") != null ? getFromLocalStorage("adminSalesInvoiceFortnightPeriod") : 0,
    externalOperators: [],
    salesInvoiceFornightPeriod: [],
    startDate: getFromLocalStorage("startDate") != "undefined" && getFromLocalStorage("startDate") != "" && getFromLocalStorage("startDate") != null ? new Date(getFromLocalStorage("startDate")) : new Date,
    endDate: getFromLocalStorage("endDate") != "undefined" && getFromLocalStorage("endDate") != "" && getFromLocalStorage("endDate") != null ? new Date(getFromLocalStorage("endDate")) : new Date,
    salesReport: [],
    isLoading: false,
    isSuperAdminLoggedIn: getFromLocalStorage("isSuperAdminLoggedIn"),
    grossKitchacoInStoreCashSales: 0,
    grossKitchacoInStoreCardSales: 0,
    grossKitchacoOnlineDeliverySales: 0,
    grossKitchacoOnlinePickupSales: 0,
    grossUberEatsSales: 0,
    grossDoordashSales: 0,
    grossDeliverooSales: 0,
    grossMenulogSales: 0,
    grossStockPurchased: 0,
    gstStockPurchased: 0,
    netStockPurchased: 0,
    adminAdjustmentStockPurchased: 0,
    deliveryChargeStockPurchased: 0,
    labelMiscCharges1: '',
    grossMiscCharges1: 0,
    netMiscCharges1: 0,
    gstMiscCharges1: 0,

    isGstMiscCharges1: false,
    labelMiscCharges2: '',
    grossMiscCharges2: 0,
    netMiscCharges2: 0,
    gstMiscCharges2: 0,

    isGstMiscCharges2: false,
    labelMiscCharges3: '',
    grossMiscCharges3: 0,
    netMiscCharges3: 0,
    gstMiscCharges3: 0,

    isGstMiscCharges3: false,
    labelMiscCharges4: '',
    grossMiscCharges4: 0,
    netMiscCharges4: 0,
    gstMiscCharges4: 0,

    isGstMiscCharges4: false,
    labelMiscCharges5: '',
    grossMiscCharges5: 0,
    isGstMiscCharges5: false,
    netMiscCharges5: 0,
    gstMiscCharges5: 0,

    in_store_cash_commission: 0,
    in_store_card_commission: 0,
    online_pickup_commission: 0,
    online_delivery_commission: 0,
    uber_eats_commission: 0,
    doordash_commission: 0,
    deliveroo_commission: 0,
    menulog_commission: 0,

    sendEmailAs: 'kitchaco',
    approved_by: '',
    approved_on: '',



    grossKitchacoInStoreCashSalesArray: [],
    grossKitchacoInStoreCardSalesArray: [],
    grossKitchacoOnlineDeliverySalesArray: [],
    grossKitchacoOnlinePickupSalesArray: [],
    grossUberEatsSalesArray: [],
    grossDoordashSalesArray: [],
    grossDeliverooSalesArray: [],
    grossMenulogSalesArray: [],
    salesApprovedInvoiceDetails: [],
    errorMessage: 'Please select a fortnight period above to show reports',
    lessCashSalesSelectedCheckbox: true,
    lessCardSalesSelectedCheckbox: false,


  }

  componentDidMount() {

    this.getExternalOperatorData()
    const {
      isSuperAdminLoggedIn,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      adminSalesInvoiceFortnightPeriod
    } = this.state
    if (isSuperAdminLoggedIn != 'true') {
      window.location.href = '/admin'
    }



  }

  setStartDate = (date) => {
    this.setState({ startDate: date }, () => {
      saveToLocalStorage("startDate", this.state.startDate)
    })
    var endDate = moment(date).add(13, 'days');
    this.setState({ endDate: endDate }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  setEndDate = (date) => {
    this.setState({ endDate: date }, () => {
      saveToLocalStorage("endDate", this.state.endDate)
    })

  }

  onChangeExternalOperator(e) {

    this.setState({ 'adminExternalOperator': e.target.value }, () => {
      saveToLocalStorage("adminExternalOperator", this.state.adminExternalOperator)
      this.getSalesReport()
    })


  }

  onChangeDatePeriod(e) {

    this.setState({ 'adminSalesInvoiceFortnightPeriod': e.target.value }, () => {
      const { salesInvoiceFornightPeriod, adminSalesInvoiceFortnightPeriod } = this.state
      try {
        var date = salesInvoiceFornightPeriod[adminSalesInvoiceFortnightPeriod - 1].start_date

        this.setState({ startDate: date }, () => {
          saveToLocalStorage("startDate", this.state.startDate)
        })
        var endDate = moment(date).add(13, 'days');
        this.setState({ endDate: endDate }, () => {
          saveToLocalStorage("endDate", this.state.endDate)
          saveToLocalStorage("adminSalesInvoiceFortnightPeriod", adminSalesInvoiceFortnightPeriod)
          this.getSalesReport()
        })
      }
      catch (e) {
        console.log(e)
        this.setState({ 'adminSalesInvoiceFortnightPeriod': 0 }, () => {
          saveToLocalStorage("adminSalesInvoiceFortnightPeriod", adminSalesInvoiceFortnightPeriod)

        })
      }

    })


  }

  getSalesReport() {

    const {
      startDate,
      endDate,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod

    } = this.state
    this.setState({ isLoading: true })
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "get_sales_report",
        "external_operator": parseInt(adminExternalOperator),
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY"),
        "sales_invoice_fortnight_period": parseInt(adminSalesInvoiceFortnightPeriod)
      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        this.setState({ isLoading: false })
        console.log("salesReport ", dataResponse)
        if (dataResponse.success) {

          this.setState({
            salesReport: dataResponse.sales_data,
            grossStockPurchased: dataResponse.gross_stock_purchased,
            netStockPurchased: dataResponse.net_stock_purchased,
            gstStockPurchased: dataResponse.gst_stock_purchased,
            adminAdjustmentStockPurchased: dataResponse.admin_adjustment_stock_purchased,
            deliveryChargeStockPurchased: dataResponse.delivery_charge_stock_purchased,
            in_store_cash_commission: dataResponse.commission_rate_data.in_store_cash_commission,
            in_store_card_commission: dataResponse.commission_rate_data.in_store_card_commission,
            online_pickup_commission: dataResponse.commission_rate_data.online_pickup_commission,
            online_delivery_commission: dataResponse.commission_rate_data.online_delivery_commission,
            uber_eats_commission: dataResponse.commission_rate_data.uber_eats_commission,
            doordash_commission: dataResponse.commission_rate_data.doordash_commission,
            deliveroo_commission: dataResponse.commission_rate_data.deliveroo_commission,
            menulog_commission: dataResponse.commission_rate_data.menulog_commission,


          }, () => {
            const { salesReport } = this.state

            var grossUberEatsSales = 0
            var grossDoordashSales = 0
            var grossDeliverooSales = 0
            var grossMenulogSales = 0
            for (var i = 0; i < salesReport.length; i++) {
              const { grossKitchacoInStoreCashSalesArray, grossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlinePickupSalesArray, grossKitchacoOnlineDeliverySalesArray, grossUberEatsSalesArray,
                grossDoordashSalesArray, grossDeliverooSalesArray, grossMenulogSalesArray,
              } = this.state
              var deliveroo_adjustment = 0
              if(!isNaN(salesReport[i].deliveroo_adjustments)){
                deliveroo_adjustment=salesReport[i].deliveroo_adjustments
              }
              grossUberEatsSales = Number(grossUberEatsSales) + salesReport[i].uber_total_sales
              grossDoordashSales = Number(grossDoordashSales) + salesReport[i].doordash_total_sales
              grossDeliverooSales = Number(grossDeliverooSales) + salesReport[i].deliveroo_total_sales + deliveroo_adjustment
              grossMenulogSales = Number(grossMenulogSales) + salesReport[i].menulog_total_sales
              var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray
              var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray
              var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray
              var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray
              var tempGrossUberEatsSalesArray = grossUberEatsSalesArray
              var tempGrossDoordashSalesArray = grossDoordashSalesArray
              var tempGrossDeliverooSalesArray = grossDeliverooSalesArray
              var tempGrossMenulogSalesArray = grossMenulogSalesArray

              tempGrossKitchacoInStoreCashSalesArray[i] = salesReport[i].in_store_cash_sales
              tempGrossKitchacoInStoreCardSalesArray[i] = salesReport[i].in_store_card_sales
              tempGrossOnlinePickupSalesArray[i] = salesReport[i].online_pickup_sales
              tempGrossOnlineDeliverySalesArray[i] = salesReport[i].online_delivery_sales
              tempGrossUberEatsSalesArray[i] = salesReport[i].uber_total_sales
              tempGrossDoordashSalesArray[i] = salesReport[i].doordash_total_sales
              tempGrossDeliverooSalesArray[i] = Number(salesReport[i].deliveroo_total_sales) + Number(salesReport[i].deliveroo_adjustments)
              tempGrossMenulogSalesArray[i] = salesReport[i].menulog_total_sales




            }
            this.setState({
              grossUberEatsSales: grossUberEatsSales, grossDoordashSales: grossDoordashSales,
              grossDeliverooSales: grossDeliverooSales, grossMenulogSales: grossMenulogSales,
              grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
              grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
              grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
              grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
              grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
              grossDoordashSalesArray: tempGrossDoordashSalesArray,
              grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
              grossMenulogSalesArray: tempGrossMenulogSalesArray,
            })

            if (dataResponse.sales_data[0].status != "unavailable" && dataResponse.sales_data[0].status != "generated") {
              this.setState({
                grossKitchacoInStoreCashSales: dataResponse.sales_data[0].kitchaco_in_store_cash_sales,
                grossKitchacoInStoreCardSales: dataResponse.sales_data[0].kitchaco_in_store_card_sales,
                grossKitchacoOnlinePickupSales: dataResponse.sales_data[0].kitchaco_online_pickup_sales,
                grossKitchacoOnlineDeliverySales: dataResponse.sales_data[0].kitchaco_online_delivery_sales,
                approved_by: dataResponse.approved_by,
                approved_on: dataResponse.approved_on,
                labelMiscCharges1: dataResponse.sales_data[0].label_misc_charges1,
                grossMiscCharges1: dataResponse.sales_data[0].gross_misc_charges1,
                gstMiscCharges1: dataResponse.sales_data[0].gst_misc_charges1,
                netMiscCharges1: dataResponse.sales_data[0].net_misc_charges1,

                labelMiscCharges2: dataResponse.sales_data[0].label_misc_charges2,
                grossMiscCharges2: dataResponse.sales_data[0].gross_misc_charges2,
                gstMiscCharges2: dataResponse.sales_data[0].gst_misc_charges2,
                netMiscCharges2: dataResponse.sales_data[0].net_misc_charges2,

                labelMiscCharges3: dataResponse.sales_data[0].label_misc_charges3,
                grossMiscCharges3: dataResponse.sales_data[0].gross_misc_charges3,
                gstMiscCharges3: dataResponse.sales_data[0].gst_misc_charges3,
                netMiscCharges3: dataResponse.sales_data[0].net_misc_charges3,

                labelMiscCharges4: dataResponse.sales_data[0].label_misc_charges4,
                grossMiscCharges4: dataResponse.sales_data[0].gross_misc_charges4,
                gstMiscCharges4: dataResponse.sales_data[0].gst_misc_charges4,
                netMiscCharges4: dataResponse.sales_data[0].net_misc_charges4,

                labelMiscCharges5: dataResponse.sales_data[0].label_misc_charges5,
                grossMiscCharges5: dataResponse.sales_data[0].gross_misc_charges5,
                gstMiscCharges5: dataResponse.sales_data[0].gst_misc_charges5,
                netMiscCharges5: dataResponse.sales_data[0].net_misc_charges5,
                lessCardSalesSelectedCheckbox:dataResponse.sales_data[0].less_in_store_card_sales,
                lessCashSalesSelectedCheckbox:dataResponse.sales_data[0].less_in_store_cash_sales,
              }, () => {
                const { gstMiscCharges1, gstMiscCharges2, gstMiscCharges3, gstMiscCharges4, gstMiscCharges5 } = this.state
                if (Number(gstMiscCharges1) > 0) {
                  this.setState({ isGstMiscCharges1: true })
                }
                if (Number(gstMiscCharges2) > 0) {
                  this.setState({ isGstMiscCharges2: true })
                }
                if (Number(gstMiscCharges3) > 0) {
                  this.setState({ isGstMiscCharges3: true })
                }
                if (Number(gstMiscCharges4) > 0) {
                  this.setState({ isGstMiscCharges4: true })
                }
                if (Number(gstMiscCharges5) > 0) {
                  this.setState({ isGstMiscCharges5: true })
                }
              })
              const { grossKitchacoInStoreCashSalesArray, grossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlinePickupSalesArray, grossKitchacoOnlineDeliverySalesArray, grossUberEatsSalesArray,
                grossDoordashSalesArray, grossDeliverooSalesArray, grossMenulogSalesArray,
              } = this.state
              for (var i = 0; i < dataResponse.sales_approved_invoice_details.length; i++) {
                var sales = dataResponse.sales_approved_invoice_details[i]
                console.log(sales)

                var tempGrossKitchacoInStoreCashSalesArray = grossKitchacoInStoreCashSalesArray
                var tempGrossKitchacoInStoreCardSalesArray = grossKitchacoInStoreCardSalesArray
                var tempGrossOnlinePickupSalesArray = grossKitchacoOnlinePickupSalesArray
                var tempGrossOnlineDeliverySalesArray = grossKitchacoOnlineDeliverySalesArray
                var tempGrossUberEatsSalesArray = grossUberEatsSalesArray
                var tempGrossDoordashSalesArray = grossDoordashSalesArray
                var tempGrossDeliverooSalesArray = grossDeliverooSalesArray
                var tempGrossMenulogSalesArray = grossMenulogSalesArray

                tempGrossKitchacoInStoreCashSalesArray[i] = sales.kitchaco_in_store_cash_sales
                tempGrossKitchacoInStoreCardSalesArray[i] = sales.kitchaco_in_store_card_sales
                tempGrossOnlinePickupSalesArray[i] = sales.kitchaco_online_pickup_sales
                tempGrossOnlineDeliverySalesArray[i] = sales.kitchaco_online_delivery_sales
                tempGrossUberEatsSalesArray[i] = sales.uber_eats_sales
                tempGrossDoordashSalesArray[i] = sales.doordash_sales
                tempGrossDeliverooSalesArray[i] = sales.deliveroo_sales
                tempGrossMenulogSalesArray[i] = sales.menulog_sales


              }
              this.setState({
                grossUberEatsSales: grossUberEatsSales, grossDoordashSales: grossDoordashSales,
                grossDeliverooSales: grossDeliverooSales, grossMenulogSales: grossMenulogSales,
                grossKitchacoInStoreCashSalesArray: tempGrossKitchacoInStoreCashSalesArray,
                grossKitchacoInStoreCardSalesArray: tempGrossKitchacoInStoreCardSalesArray,
                grossKitchacoOnlineDeliverySalesArray: tempGrossOnlineDeliverySalesArray,
                grossKitchacoOnlinePickupSalesArray: tempGrossOnlinePickupSalesArray,
                grossUberEatsSalesArray: tempGrossUberEatsSalesArray,
                grossDoordashSalesArray: tempGrossDoordashSalesArray,
                grossDeliverooSalesArray: tempGrossDeliverooSalesArray,
                grossMenulogSalesArray: tempGrossMenulogSalesArray,
                salesApprovedInvoiceDetails: dataResponse.sales_approved_invoice_details
              })

            }
            else {
              this.setState({
                grossKitchacoInStoreCashSales: dataResponse.gross_kitchaco_in_store_cash_sales,
                grossKitchacoInStoreCardSales: dataResponse.gross_kitchaco_in_store_card_sales,
                grossKitchacoOnlinePickupSales: dataResponse.gross_kitchaco_online_pickup_sales,
                grossKitchacoOnlineDeliverySales: dataResponse.gross_kitchaco_online_delivery_sales,
                approved_by: '',
                approved_on: '',
                labelMiscCharges1: '',
                grossMiscCharges1: 0,
                netMiscCharges1: 0,
                gstMiscCharges1: 0,

                isGstMiscCharges1: false,
                labelMiscCharges2: '',
                grossMiscCharges2: 0,
                netMiscCharges2: 0,
                gstMiscCharges2: 0,

                isGstMiscCharges2: false,
                labelMiscCharges3: '',
                grossMiscCharges3: 0,
                netMiscCharges3: 0,
                gstMiscCharges3: 0,

                isGstMiscCharges3: false,
                labelMiscCharges4: '',
                grossMiscCharges4: 0,
                netMiscCharges4: 0,
                gstMiscCharges4: 0,

                isGstMiscCharges4: false,
                labelMiscCharges5: '',
                grossMiscCharges5: 0,
                isGstMiscCharges5: false,
                netMiscCharges5: 0,
                gstMiscCharges5: 0,
                lessCashSalesSelectedCheckbox: true,
                lessCardSalesSelectedCheckbox: false,
              })



            }
          })
        }
        else {
          this.setState({ adminSalesInvoiceFortnightPeriod: 0, errorMessage: 'Report for this location and period hasn\'t been generated yet. Please select a different location or period or come back later for this report.' })
          // window.location.reload()

        }
      })
      .catch(err => { console.log(err); this.setState({ isLoading: false }) })
  }




  getExternalOperatorData() {
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "external_operators"
      }
    );



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {

        if (dataResponse.success) {
          this.setState({ externalOperators: dataResponse.external_operators }, () => {
            this.getSalesInvoiceFornightPeriod()
          })
        }
      })
      .catch(err => { console.log(err) })
  }


  getSalesInvoiceFornightPeriod() {
    var url = config.BASE_URI + apiPaths.admin.adminData;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "sales_invoice_fortnight_period"
      }
    );



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {
        if (dataResponse.success) {
          this.setState({ salesInvoiceFornightPeriod: dataResponse.sales_invoice_fortnight_period }, () => {
            const { salesInvoiceFornightPeriod, adminSalesInvoiceFortnightPeriod, startDate, endDate } = this.state
            try {
              var start_date = moment(startDate).format("DD MMM YYYY")
              var end_date = moment(endDate).format("DD MMM YYYY")
              if (salesInvoiceFornightPeriod[adminSalesInvoiceFortnightPeriod - 1].start_date != start_date || salesInvoiceFornightPeriod[adminSalesInvoiceFortnightPeriod - 1].end_date != end_date) {

                this.setState({ 'adminSalesInvoiceFortnightPeriod': 0 }, () => {
                  saveToLocalStorage("adminSalesInvoiceFortnightPeriod", adminSalesInvoiceFortnightPeriod)

                })
              }

            }
            catch (e) {
              console.log(e)
              this.setState({ 'adminSalesInvoiceFortnightPeriod': 0 }, () => {
                saveToLocalStorage("adminSalesInvoiceFortnightPeriod", adminSalesInvoiceFortnightPeriod)

              })
            }
            this.getSalesReport()
          })
        }
      })
      .catch(err => { console.log(err) })
  }







  render() {

    return (
      <>
        {this.renderMainComponent()}
      </>
    )
  }


  saveSalesInvoice = (grossTotalRevenue, gst, netUberEatsFee, netDoordashFee, netDeliverooFee, netMenulogFee, grossKitchacoInStoreCashFee, netKitchacoInStoreCardFee, netKitchacoOnlinePickupFee, netKitchacoOnlineDeliveryFee, payoutResult,grossTotalSalesLessExpenditure) => {
    const {
      startDate,
      endDate,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      netStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      superAdminEmail,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      salesReport,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox,
      

    } = this.state
  

    var kitchacoTotalSales = Number(grossKitchacoInStoreCashSales) + Number(grossKitchacoInStoreCardSales) + Number(grossKitchacoOnlineDeliverySales) + Number(grossKitchacoOnlinePickupSales)
    var kitchacoOnlineSales = Number(grossKitchacoOnlinePickupSales) + Number(grossKitchacoOnlineDeliverySales)
    var kitchacoInStoreSales = Number(grossKitchacoInStoreCashSales) + Number(grossKitchacoInStoreCardSales)

    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "update_and_approve_sales_report",
        "external_operator": parseInt(adminExternalOperator),
        "approved_by": superAdminEmail,
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY"),
        "sales_invoice_fortnight_period": parseInt(adminSalesInvoiceFortnightPeriod),
        'kitchaco_total_sales': Number(kitchacoTotalSales).toFixed(2),
        'kitchaco_online_sales': kitchacoOnlineSales,
        'kitchaco_instore_sales': kitchacoInStoreSales,
        'uber_total_sales': grossUberEatsSales,
        'deliveroo_total_sales': grossDeliverooSales,
        'doordash_total_sales': grossDoordashSales,
        'menulog_total_sales': grossMenulogSales,
        'total_revenue': Number(grossTotalRevenue).toFixed(2),
        'total_gst': gst,
        'stock_ex_gst': netStockPurchased,
        'uber_commission_ex_gst': netUberEatsFee,
        'doordash_commission_ex_gst': netDoordashFee,
        'deliveroo_commission_ex_gst': netDeliverooFee,
        'menulog_commission_ex_gst': netMenulogFee,
        'in_store_cash_commission': grossKitchacoInStoreCashFee,
        'in_store_card_commission_ex_gst': netKitchacoInStoreCardFee,
        'online_pickup_commission_ex_gst': netKitchacoOnlinePickupFee,
        'online_delivery_commission_ex_gst': netKitchacoOnlineDeliveryFee,
        'kitchaco_in_store_cash_sales': grossKitchacoInStoreCashSales,
        'kitchaco_in_store_card_sales': grossKitchacoInStoreCardSales,
        'kitchaco_online_pickup_sales': grossKitchacoOnlinePickupSales,
        'kitchaco_online_delivery_sales': grossKitchacoOnlineDeliverySales,

        'label_misc_charges1': labelMiscCharges1,
        'gross_misc_charges1': grossMiscCharges1,
        'net_misc_charges1': netMiscCharges1,
        'gst_misc_charges1': gstMiscCharges1,
        'label_misc_charges2': labelMiscCharges2,
        'gross_misc_charges2': grossMiscCharges2,
        'net_misc_charges2': netMiscCharges2,
        'gst_misc_charges2': gstMiscCharges2,
        'label_misc_charges3': labelMiscCharges3,
        'gross_misc_charges3': grossMiscCharges3,
        'net_misc_charges3': netMiscCharges3,
        'gst_misc_charges3': gstMiscCharges3,
        'label_misc_charges4': labelMiscCharges4,
        'gross_misc_charges4': grossMiscCharges4,
        'net_misc_charges4': netMiscCharges4,
        'gst_misc_charges4': gstMiscCharges4,
        'label_misc_charges5': labelMiscCharges5,
        'gross_misc_charges5': grossMiscCharges5,
        'net_misc_charges5': netMiscCharges5,
        'gst_misc_charges5': gstMiscCharges5,

        'total_payout_inc_gst': payoutResult,

        'uber_total_sales_array': grossUberEatsSalesArray,
        'deliveroo_total_sales_array': grossDeliverooSalesArray,
        'doordash_total_sales_array': grossDoordashSalesArray,
        'menulog_total_sales_array': grossMenulogSalesArray,
        'kitchaco_in_store_cash_sales_array': grossKitchacoInStoreCashSalesArray,
        'kitchaco_in_store_card_sales_array': grossKitchacoInStoreCardSalesArray,
        'kitchaco_online_pickup_sales_array': grossKitchacoOnlinePickupSalesArray,
        'kitchaco_online_delivery_sales_array': grossKitchacoOnlineDeliverySalesArray,
        'sales_report': salesReport,
        'sales_approved_invoice_details': this.state.salesApprovedInvoiceDetails,
        'total_earnings':grossTotalSalesLessExpenditure,
        'less_in_store_cash_sales':lessCashSalesSelectedCheckbox,
        'less_in_store_card_sales':lessCardSalesSelectedCheckbox

      }
    );


    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {

        if (dataResponse.success == true) {
          alert("Approved Successfully")
          window.location.reload()
        }

      })

  }



  sendEmail = () => {
    const {
      adminExternalOperator,
      startDate,
      endDate,
      adminSalesInvoiceFortnightPeriod,
      superAdminEmail
    } = this.state
    var url = config.BASE_URI + apiPaths.admin.salesReport;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
      {
        "query_type": "email_sales_report",
        "external_operator": parseInt(adminExternalOperator),
        "approved_by": superAdminEmail,
        "start_date": moment(startDate).format("DD MMM YYYY"),
        "end_date": moment(endDate).format("DD MMM YYYY"),
        "sales_invoice_fortnight_period": parseInt(adminSalesInvoiceFortnightPeriod),
      }
    );
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      .then(dataResponse => {

        if (dataResponse.success == true) {
          alert("Email has been sent to your registered email address.")
        }
      })
      .catch(err => { console.log(err) })
  }


  handleKitchacoInStoreCashSalesArray = (e, index) => {
    const {
      grossKitchacoInStoreCashSalesArray
    } = this.state
    let tempArray = grossKitchacoInStoreCashSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossKitchacoInStoreCashSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossKitchacoInStoreCashSalesArray
      } = this.state
      for (var i = 0; i < grossKitchacoInStoreCashSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoInStoreCashSalesArray[i])
      }
      this.setState({ grossKitchacoInStoreCashSales: temp })
    })
  }




  handleKitchacoInStoreCardSalesArray = (e, index) => {
    const {
      grossKitchacoInStoreCardSalesArray
    } = this.state
    let tempArray = grossKitchacoInStoreCardSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossKitchacoInStoreCardSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossKitchacoInStoreCardSalesArray
      } = this.state
      for (var i = 0; i < grossKitchacoInStoreCardSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoInStoreCardSalesArray[i])
      }
      this.setState({ grossKitchacoInStoreCardSales: temp })
    })




  }


  handleKitchacoOnlinePickupSalesArray = (e, index) => {
    const {
      grossKitchacoOnlinePickupSalesArray
    } = this.state
    let tempArray = grossKitchacoOnlinePickupSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossKitchacoOnlinePickupSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossKitchacoOnlinePickupSalesArray
      } = this.state
      for (var i = 0; i < grossKitchacoOnlinePickupSalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlinePickupSalesArray[i])
      }
      this.setState({ grossKitchacoOnlinePickupSales: temp })
    })



  }

  handleKitchacoOnlineDeliverySalesArray = (e, index) => {
    const {
      grossKitchacoOnlineDeliverySalesArray
    } = this.state
    let tempArray = grossKitchacoOnlineDeliverySalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossKitchacoOnlineDeliverySalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossKitchacoOnlineDeliverySalesArray
      } = this.state
      for (var i = 0; i < grossKitchacoOnlineDeliverySalesArray.length; i++) {
        temp = Number(temp) + Number(grossKitchacoOnlineDeliverySalesArray[i])
      }
      this.setState({ grossKitchacoOnlineDeliverySales: temp })
    })
  }

  handleUberEatsSalesArray = (e, index) => {
    const {
      grossUberEatsSalesArray
    } = this.state
    let tempArray = grossUberEatsSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossUberEatsSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossUberEatsSalesArray
      } = this.state
      for (var i = 0; i < grossUberEatsSalesArray.length; i++) {
        temp = Number(temp) + Number(grossUberEatsSalesArray[i])
      }
      this.setState({ grossUberEatsSales: temp })
    })
  }

  handleDoordashSalesArray = (e, index) => {
    const {
      grossDoordashSalesArray
    } = this.state
    let tempArray = grossDoordashSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossDoordashSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossDoordashSalesArray
      } = this.state
      for (var i = 0; i < grossDoordashSalesArray.length; i++) {
        temp = Number(temp) + Number(grossDoordashSalesArray[i])
      }
      this.setState({ grossDoordashSales: temp })
    })
  }

  handleDeliverooSalesArray = (e, index) => {
    const {
      grossDeliverooSalesArray
    } = this.state
    let tempArray = grossDeliverooSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossDeliverooSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossDeliverooSalesArray
      } = this.state
      for (var i = 0; i < grossDeliverooSalesArray.length; i++) {
        temp = Number(temp) + Number(grossDeliverooSalesArray[i])
      }
      this.setState({ grossDeliverooSales: temp })
    })
  }


  handleMenulogSalesArray = (e, index) => {
    const {
      grossMenulogSalesArray
    } = this.state
    let tempArray = grossMenulogSalesArray
    for (var i = 0; i < tempArray.length; i++) {
      if (i == index) {
        tempArray[i] = e
      }
    }
    this.setState({ grossMenulogSalesArray: tempArray }, () => {
      var temp = 0
      const {
        grossMenulogSalesArray
      } = this.state
      for (var i = 0; i < grossMenulogSalesArray.length; i++) {
        temp = Number(temp) + Number(grossMenulogSalesArray[i])
      }
      this.setState({ grossMenulogSales: temp })
    })
  }


  renderMainComponent() {
    const {
      salesReport,
      externalOperators,
      adminExternalOperator,
      adminSalesInvoiceFortnightPeriod,
      isLoading,
      isSuperAdminLoggedIn,
      startDate,
      endDate,
      salesInvoiceFornightPeriod,
      grossKitchacoInStoreCashSales,
      grossKitchacoInStoreCardSales,
      grossKitchacoOnlineDeliverySales,
      grossKitchacoOnlinePickupSales,
      grossUberEatsSales,
      grossDoordashSales,
      grossDeliverooSales,
      grossMenulogSales,
      grossStockPurchased,
      gstStockPurchased,
      netStockPurchased,
      adminAdjustmentStockPurchased,
      deliveryChargeStockPurchased,
      labelMiscCharges1,
      grossMiscCharges1,
      isGstMiscCharges1,
      netMiscCharges1,
      gstMiscCharges1,

      labelMiscCharges2,
      grossMiscCharges2,
      isGstMiscCharges2,
      netMiscCharges2,
      gstMiscCharges2,

      labelMiscCharges3,
      grossMiscCharges3,
      isGstMiscCharges3,
      netMiscCharges3,
      gstMiscCharges3,

      labelMiscCharges4,
      grossMiscCharges4,
      isGstMiscCharges4,
      netMiscCharges4,
      gstMiscCharges4,

      labelMiscCharges5,
      grossMiscCharges5,
      isGstMiscCharges5,
      netMiscCharges5,
      gstMiscCharges5,
      in_store_cash_commission,
      in_store_card_commission,
      online_pickup_commission,
      online_delivery_commission,
      uber_eats_commission,
      doordash_commission,
      deliveroo_commission,
      menulog_commission,
      approved_by,
      approved_on,
      grossKitchacoInStoreCashSalesArray,
      grossKitchacoInStoreCardSalesArray,
      grossKitchacoOnlineDeliverySalesArray,
      grossKitchacoOnlinePickupSalesArray,
      grossUberEatsSalesArray,
      grossDoordashSalesArray,
      grossDeliverooSalesArray,
      grossMenulogSalesArray,
      errorMessage,
      lessCardSalesSelectedCheckbox,
      lessCashSalesSelectedCheckbox


    } = this.state


    var grossKitchacoInStoreCashFee = 0
    var grossKitchacoInStoreCardFee = 0
    var grossKitchacoOnlineDeliveryFee = 0
    var grossKitchacoOnlinePickupFee = 0
    var grossUberEatsFee = 0
    var grossDoordashFee = 0
    var grossDeliverooFee = 0
    var grossMenulogFee = 0

    var gstKitchacoInStoreCashSales = 0
    var gstKitchacoInStoreCardSales = 0
    var gstKitchacoOnlineDeliverySales = 0
    var gstKitchacoOnlinePickupSales = 0
    var gstUberEatsSales = 0
    var gstDoordashSales = 0
    var gstDeliverooSales = 0
    var gstMenulogSales = 0

    var gstKitchacoInStoreCashFee = 0
    var gstKitchacoInStoreCardFee = 0
    var gstKitchacoOnlineDeliveryFee = 0
    var gstKitchacoOnlinePickupFee = 0
    var gstUberEatsFee = 0
    var gstDoordashFee = 0
    var gstDeliverooFee = 0
    var gstMenulogFee = 0

    var netKitchacoInStoreCashSales = 0
    var netKitchacoInStoreCardSales = 0
    var netKitchacoOnlineDeliverySales = 0
    var netKitchacoOnlinePickupSales = 0
    var netUberEatsSales = 0
    var netDoordashSales = 0
    var netDeliverooSales = 0
    var netMenulogSales = 0
    var netKitchacoInStoreCashFee = 0
    var netKitchacoInStoreCardFee = 0
    var netKitchacoOnlineDeliveryFee = 0
    var netKitchacoOnlinePickupFee = 0
    var netUberEatsFee = 0
    var netDoordashFee = 0
    var netDeliverooFee = 0
    var netMenulogFee = 0

    var grossTotalRevenue = 0
    var netTotalRevenue = 0
    var gstTotalRevenue = 0

    var grossTotalRevenueWithoutCashSales = 0
    var netTotalRevenueWithoutCashSales = 0
    var gstTotalRevenueWithoutCashSales = 0

    var grossTotalPurchases = 0
    var netTotalPurchases = 0
    var gstTotalPurchases = 0

    var grossTotalSalesLessExpenditure = 0
    var gstTotalSalesLessExpenditure = 0
    var netTotalSalesLessExpenditure = 0

    var payoutResult = 0
    var gst = 0
    var netPayment = 0
    var operatorIsPaid = 0
    var gstToBePaidToATO = 0
    var operatorReceivesFromFrank = 0
    var operatorPaysATO = 0

    if (lessCardSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCardSales = Number(Number(grossKitchacoInStoreCardSales) / 11).toFixed(2)
      netKitchacoInStoreCardSales = Number(Number(grossKitchacoInStoreCardSales) / 1.1).toFixed(2)
    }
    else {
      gstKitchacoInStoreCardSales = 0
      netKitchacoInStoreCardSales = 0
    }

    if (lessCashSalesSelectedCheckbox == false) {
      gstKitchacoInStoreCashSales = Number(Number(grossKitchacoInStoreCashSales) / 11).toFixed(2)
      netKitchacoInStoreCashSales = Number(Number(grossKitchacoInStoreCashSales) / 1.1).toFixed(2)
    }
    else {
      gstKitchacoInStoreCashSales = 0
      netKitchacoInStoreCashSales = 0
    }

    gstKitchacoOnlineDeliverySales = Number(Number(grossKitchacoOnlineDeliverySales) / 11).toFixed(2)
    netKitchacoOnlineDeliverySales = Number(Number(grossKitchacoOnlineDeliverySales) / 1.1).toFixed(2)

    gstKitchacoOnlinePickupSales = Number(Number(grossKitchacoOnlinePickupSales) / 11).toFixed(2)
    netKitchacoOnlinePickupSales = Number(Number(grossKitchacoOnlinePickupSales) / 1.1).toFixed(2)

    gstUberEatsSales = Number(Number(grossUberEatsSales) / 11).toFixed(2)
    netUberEatsSales = Number(Number(grossUberEatsSales) / 1.1).toFixed(2)

    gstDoordashSales = Number(Number(grossDoordashSales) / 11).toFixed(2)
    netDoordashSales = Number(Number(grossDoordashSales) / 1.1).toFixed(2)

    gstDeliverooSales = Number(Number(grossDeliverooSales) / 11).toFixed(2)
    netDeliverooSales = Number(Number(grossDeliverooSales) / 1.1).toFixed(2)

    gstMenulogSales = Number(Number(grossMenulogSales) / 11).toFixed(2)
    netMenulogSales = Number(Number(grossMenulogSales) / 1.1).toFixed(2)


    grossTotalRevenue = Number(grossKitchacoInStoreCashSales) + Number(grossKitchacoInStoreCardSales) + Number(grossKitchacoOnlineDeliverySales) + Number(grossKitchacoOnlinePickupSales) + Number(grossUberEatsSales) + Number(grossDoordashSales) + Number(grossDeliverooSales) + Number(grossMenulogSales)
    gstTotalRevenue = Number(gstKitchacoInStoreCashSales) + Number(gstKitchacoInStoreCardSales) + Number(gstKitchacoOnlineDeliverySales) + Number(gstKitchacoOnlinePickupSales) + Number(gstUberEatsSales) + Number(gstDoordashSales) + Number(gstDeliverooSales) + Number(gstMenulogSales)
    netTotalRevenue = Number(netKitchacoInStoreCashSales) + Number(netKitchacoInStoreCardSales) + Number(netKitchacoOnlineDeliverySales) + Number(netKitchacoOnlinePickupSales) + Number(netUberEatsSales) + Number(netDoordashSales) + Number(netDeliverooSales) + Number(netMenulogSales)
      
    grossTotalRevenueWithoutCashSales = grossTotalRevenue 
    netTotalRevenueWithoutCashSales = netTotalRevenue 
    gstTotalRevenueWithoutCashSales = gstTotalRevenue 
    
    if(lessCashSalesSelectedCheckbox==true){
      grossTotalRevenueWithoutCashSales = grossTotalRevenueWithoutCashSales - grossKitchacoInStoreCashSales
      netTotalRevenueWithoutCashSales = netTotalRevenueWithoutCashSales - netKitchacoInStoreCashSales
      gstTotalRevenueWithoutCashSales = gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCashSales
    }
    if(lessCardSalesSelectedCheckbox==true){
      grossTotalRevenueWithoutCashSales = grossTotalRevenueWithoutCashSales - grossKitchacoInStoreCardSales
      netTotalRevenueWithoutCashSales = netTotalRevenueWithoutCashSales - netKitchacoInStoreCardSales
      gstTotalRevenueWithoutCashSales = gstTotalRevenueWithoutCashSales - gstKitchacoInStoreCardSales
    }
   

    grossKitchacoInStoreCashFee = Number(Number(in_store_cash_commission) * Number(grossKitchacoInStoreCashSales)).toFixed(2)
    gstKitchacoInStoreCashFee = Number(Number(grossKitchacoInStoreCashFee) / 11).toFixed(2)
    netKitchacoInStoreCashFee = Number(Number(grossKitchacoInStoreCashFee) / 1.1).toFixed(2)
    
    grossKitchacoInStoreCardFee = Number(Number(in_store_card_commission) * Number(grossKitchacoInStoreCardSales)).toFixed(2)
    gstKitchacoInStoreCardFee = Number(Number(grossKitchacoInStoreCardFee) / 11).toFixed(2)
    netKitchacoInStoreCardFee = Number(Number(grossKitchacoInStoreCardFee) / 1.1).toFixed(2)

    grossKitchacoOnlineDeliveryFee = Number(Number(online_delivery_commission) * Number(grossKitchacoOnlineDeliverySales)).toFixed(2)
    gstKitchacoOnlineDeliveryFee = Number(Number(grossKitchacoOnlineDeliveryFee) / 11).toFixed(2)
    netKitchacoOnlineDeliveryFee = Number(Number(grossKitchacoOnlineDeliveryFee) / 1.1).toFixed(2)

    grossKitchacoOnlinePickupFee = Number(Number(online_pickup_commission) * Number(grossKitchacoOnlinePickupSales)).toFixed(2)
    gstKitchacoOnlinePickupFee = Number(Number(grossKitchacoOnlinePickupFee) / 11).toFixed(2)
    netKitchacoOnlinePickupFee = Number(Number(grossKitchacoOnlinePickupFee) / 1.1).toFixed(2)

    grossUberEatsFee = Number(Number(uber_eats_commission) * Number(grossUberEatsSales)).toFixed(2)
    gstUberEatsFee = Number(Number(grossUberEatsFee) / 11).toFixed(2)
    netUberEatsFee = Number(Number(grossUberEatsFee) / 1.1).toFixed(2)

    grossDoordashFee = Number(Number(doordash_commission) * Number(grossDoordashSales)).toFixed(2)
    gstDoordashFee = Number(Number(grossDoordashFee) / 11).toFixed(2)
    netDoordashFee = Number(Number(grossDoordashFee) / 1.1).toFixed(2)

    grossDeliverooFee = Number(Number(deliveroo_commission) * Number(grossDeliverooSales)).toFixed(2)
    gstDeliverooFee = Number(Number(grossDeliverooFee) / 11).toFixed(2)
    netDeliverooFee = Number(Number(grossDeliverooFee) / 1.1).toFixed(2)

    grossMenulogFee = Number(Number(menulog_commission) * Number(grossMenulogSales)).toFixed(2)
    gstMenulogFee = Number(Number(grossMenulogFee) / 11).toFixed(2)
    netMenulogFee = Number(Number(grossMenulogFee) / 1.1).toFixed(2)


    grossTotalPurchases = Number(Number(grossKitchacoInStoreCashFee) +
      Number(grossKitchacoInStoreCardFee) +
      Number(grossKitchacoOnlineDeliveryFee) +
      Number(grossKitchacoOnlinePickupFee) +
      Number(grossUberEatsFee) +
      Number(grossDoordashFee) +
      Number(grossDeliverooFee) +
      Number(grossMenulogFee) +
      Number(grossStockPurchased) +
      Number(grossMiscCharges1) +
      Number(grossMiscCharges2) +
      Number(grossMiscCharges3) +
      Number(grossMiscCharges4) +
      Number(grossMiscCharges5)
    ).toFixed(2)


    netTotalPurchases =
      Number(Number(netKitchacoInStoreCashFee) +
        Number(netKitchacoInStoreCardFee) +
        Number(netKitchacoOnlineDeliveryFee) +
        Number(netKitchacoOnlinePickupFee) +
        Number(netUberEatsFee) +
        Number(netDoordashFee) +
        Number(netDeliverooFee) +
        Number(netMenulogFee) +
        Number(netStockPurchased) +
        Number(netMiscCharges1) +
        Number(netMiscCharges2) +
        Number(netMiscCharges3) +
        Number(netMiscCharges4) +
        Number(netMiscCharges5)
      ).toFixed(2)

    gstTotalPurchases = Number(Number(gstKitchacoInStoreCashFee) +
      Number(gstKitchacoInStoreCardFee) +
      Number(gstKitchacoOnlineDeliveryFee) +
      Number(gstKitchacoOnlinePickupFee) +
      Number(gstUberEatsFee) +
      Number(gstDoordashFee) +
      Number(gstDeliverooFee) +
      Number(gstMenulogFee) +
      Number(gstStockPurchased) +
      Number(gstMiscCharges1) +
      Number(gstMiscCharges2) +
      Number(gstMiscCharges3) +
      Number(gstMiscCharges4) +
      Number(gstMiscCharges5)
    ).toFixed(2)


    grossTotalSalesLessExpenditure = Number(Number(grossTotalRevenue) - Number(grossTotalPurchases)).toFixed(2)
    netTotalSalesLessExpenditure = Number(Number(netTotalRevenue) - Number(netTotalPurchases)).toFixed(2)
    gstTotalSalesLessExpenditure = Number(Number(gstTotalRevenue) - Number(gstTotalPurchases)).toFixed(2)

    payoutResult = Number(Number(grossTotalRevenueWithoutCashSales) - Number(grossTotalPurchases)).toFixed(2)
    gst = Number(gstTotalSalesLessExpenditure).toFixed(2)
    netPayment = Number(Number(payoutResult) - Number(gst)).toFixed(2)

    return (
      <>
        <AdminNavBar isSuperAdminLoggedIn={isSuperAdminLoggedIn} ></AdminNavBar>
        {isLoading ?
          <div style={{ textAlign: 'center' }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
          :
          <Container style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
            <Row style={{ marginTop: 10 }}>
              <Col xs={12} md={6} lg={6}>
                {externalOperators && externalOperators.length > 0 ?
                  <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                    <Form.Label>Operators {'(' + externalOperators.length + ')'}</Form.Label>
                    <Form.Control as="select" size="sm" custom onChange={this.onChangeExternalOperator.bind(this)} defaultValue={adminExternalOperator}>
                      <option value={0}>All</option>
                      {externalOperators.map((operator, i) => {
                        return (
                          <option key={i} value={externalOperators[i].id}>{externalOperators[i].business_name}</option>

                        )
                      })}
                    </Form.Control>
                  </Form.Group>


                  : null}
              </Col>
              <Col xs={12} md={6} lg={6}>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={8} md={8} lg={8}>
                    {salesInvoiceFornightPeriod && salesInvoiceFornightPeriod.length > 0 ?
                      <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                        <Form.Label>Date Period</Form.Label>
                        <Form.Control as="select" size="sm" custom onChange={this.onChangeDatePeriod.bind(this)} defaultValue={adminSalesInvoiceFortnightPeriod}>
                          <option value={0}>Select Date Period</option>
                          {/* <option value={-1}>Last Month</option>
                          <option value={-2}>Last 12 Weeks</option>
                          <option value={-3}>Last Year</option> */}
                          {salesInvoiceFornightPeriod.map((salesInvoicePeriod, i) => {
                            return (
                              <option key={i} value={salesInvoiceFornightPeriod[i].id}>{salesInvoiceFornightPeriod[i].start_date + ' - ' + salesInvoiceFornightPeriod[i].end_date}</option>

                            )
                          })}
                        </Form.Control>
                      </Form.Group>
                      : null}
                  </Col>
                </Row>
              </Col>
            </Row>
            {adminSalesInvoiceFortnightPeriod != 0 ?
              <Row style={{ marginTop: 10 }}>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>You can generate the report using the form below and email it to any email id.</p>
                  <div style={{ borderWidth: 1, borderColor: 'grey', borderStyle: 'solid', borderRadius: 5, padding: 15 }}>
                    <h5><b>Revenue</b></h5>
                    {salesReport && salesReport.length > 0 ?
                      <div style={{ padding: 10 }}>
                        <Row style={{ marginBottom: 10 }}>
                          <Col xs={3} md={3} lg={3}>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <b>Gross ($)</b>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <b>Net($)</b>
                          </Col>
                          <Col xs={3} md={3} lg={3}>
                            <b>GST($)</b>
                          </Col>
                        </Row>
                      </div>
                      : null
                    }
                    {salesReport && salesReport.map((sales, index) => {
                      var totalRevenueForLocation = Number(grossKitchacoInStoreCardSalesArray[index]) + Number(grossKitchacoInStoreCashSalesArray[index]) + Number(grossKitchacoOnlinePickupSalesArray[index]) + Number(grossKitchacoOnlineDeliverySalesArray[index]) + Number(grossUberEatsSalesArray[index]) + Number(grossDoordashSalesArray[index]) + Number(grossDeliverooSalesArray[index]) + Number(grossMenulogSalesArray[index])

                      return (
                        <div key={index} style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', padding: 10 }}>
                          <p>Kitchaco Location:{sales.kitchaco__suburb}</p>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> In Store Cash Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossKitchacoInStoreCashSalesArray && grossKitchacoInStoreCashSalesArray[index]} onChange={(event) => { this.handleKitchacoInStoreCashSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {
                                lessCashSalesSelectedCheckbox == true ?
                                  0
                                  :
                                  grossKitchacoInStoreCashSalesArray && Number(Number(grossKitchacoInStoreCashSalesArray[index]) / 1.1).toFixed(2)
                              }
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {
                                lessCashSalesSelectedCheckbox == true ?
                                  0
                                  :
                                  grossKitchacoInStoreCashSalesArray && Number(Number(grossKitchacoInStoreCashSalesArray[index]) / 11).toFixed(2)
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> In Store Card Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossKitchacoInStoreCardSalesArray && grossKitchacoInStoreCardSalesArray[index]} onChange={(event) => { this.handleKitchacoInStoreCardSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {
                                lessCardSalesSelectedCheckbox == true ?
                                  0
                                  :
                                  grossKitchacoInStoreCardSalesArray && Number(Number(grossKitchacoInStoreCardSalesArray[index]) / 1.1).toFixed(2)
                              }
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {
                                lessCardSalesSelectedCheckbox == true ?
                                  0
                                  :
                                  grossKitchacoInStoreCardSalesArray && Number(Number(grossKitchacoInStoreCardSalesArray[index]) / 11).toFixed(2)
                              }
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Online Pickup Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossKitchacoOnlinePickupSalesArray && grossKitchacoOnlinePickupSalesArray[index]} onChange={(event) => { this.handleKitchacoOnlinePickupSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossKitchacoOnlinePickupSalesArray && Number(Number(grossKitchacoOnlinePickupSalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossKitchacoOnlinePickupSalesArray && Number(Number(grossKitchacoOnlinePickupSalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Online Delivery Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossKitchacoOnlineDeliverySalesArray && grossKitchacoOnlineDeliverySalesArray[index]} onChange={(event) => { this.handleKitchacoOnlineDeliverySalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossKitchacoOnlineDeliverySalesArray && Number(Number(grossKitchacoOnlineDeliverySalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossKitchacoOnlineDeliverySalesArray && Number(Number(grossKitchacoOnlineDeliverySalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Uber Eats Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossUberEatsSalesArray && grossUberEatsSalesArray[index]} onChange={(event) => { this.handleUberEatsSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossUberEatsSalesArray && Number(Number(grossUberEatsSalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossUberEatsSalesArray && Number(Number(grossUberEatsSalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Doordash Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossDoordashSalesArray && grossDoordashSalesArray[index]} onChange={(event) => { this.handleDoordashSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossDoordashSalesArray && Number(Number(grossDoordashSalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossDoordashSalesArray && Number(Number(grossDoordashSalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Deliveroo Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossDeliverooSalesArray && grossDeliverooSalesArray[index]} onChange={(event) => { this.handleDeliverooSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossDeliverooSalesArray && Number(Number(grossDeliverooSalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossDeliverooSalesArray && Number(Number(grossDeliverooSalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <p> Menulog Sales</p>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              <Form.Group className="mb-3">
                                <Form.Control type="number" value={grossMenulogSalesArray && grossMenulogSalesArray[index]} onChange={(event) => { this.handleMenulogSalesArray(event.target.value, index) }} />
                              </Form.Group>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossMenulogSalesArray && Number(Number(grossMenulogSalesArray[index]) / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {grossMenulogSalesArray && Number(Number(grossMenulogSalesArray[index]) / 11).toFixed(2)}
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={3} md={3} lg={3}>
                              <h6><b>Total Revenue</b></h6>
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {Number(totalRevenueForLocation).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {Number(totalRevenueForLocation / 1.1).toFixed(2)}
                            </Col>
                            <Col xs={3} md={3} lg={3}>
                              {Number(totalRevenueForLocation / 11).toFixed(2)}
                            </Col>
                          </Row>
                        </div>
                      )
                    })}






                    <Row style={{ marginTop: 20, marginBottom: 10 }}>
                      <Col xs={3} md={3} lg={3}>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <b>Gross ($)</b>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <b>Net($)</b>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <b>GST($)</b>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco In Store Cash Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossKitchacoInStoreCashSales} onChange={(event) => { this.setState({ grossKitchacoInStoreCashSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoInStoreCashSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoInStoreCashSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco In Store Card Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossKitchacoInStoreCardSales} onChange={(event) => { this.setState({ grossKitchacoInStoreCardSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoInStoreCardSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoInStoreCardSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco Online Pickup Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossKitchacoOnlinePickupSales} onChange={(event) => { this.setState({ grossKitchacoOnlinePickupSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoOnlinePickupSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoOnlinePickupSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco Online Delivery Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossKitchacoOnlineDeliverySales} onChange={(event) => { this.setState({ grossKitchacoOnlineDeliverySales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoOnlineDeliverySales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoOnlineDeliverySales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Uber Eats Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossUberEatsSales} onChange={(event) => { this.setState({ grossUberEatsSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netUberEatsSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstUberEatsSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Doordash Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossDoordashSales} onChange={(event) => { this.setState({ grossDoordashSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netDoordashSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstDoordashSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Deliveroo Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossDeliverooSales} onChange={(event) => { this.setState({ grossDeliverooSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netDeliverooSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstDeliverooSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Menulog Sales</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" plaintext readOnly value={grossMenulogSales} onChange={(event) => { this.setState({ grossMenulogSales: event.target.value }) }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMenulogSales}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMenulogSales}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6><b>Total Revenue</b></h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(grossTotalRevenue).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(netTotalRevenue).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(gstTotalRevenue).toFixed(2)}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Check type="checkbox" label="Less Cash Sales Collected?" checked={lessCashSalesSelectedCheckbox} onChange={() => {
                          if (this.state.lessCashSalesSelectedCheckbox == true) {
                            this.setState({ lessCashSalesSelectedCheckbox: false })
                          }
                          else {
                            this.setState({ lessCashSalesSelectedCheckbox: true, })
                          }
                        }} />

                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        -{Number(grossKitchacoInStoreCashSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Check type="checkbox" label="Less Card Sales Collected?" checked={lessCardSalesSelectedCheckbox} onChange={() => {
                          if (this.state.lessCardSalesSelectedCheckbox == true) {
                            this.setState({ lessCardSalesSelectedCheckbox: false })
                          }
                          else {
                            this.setState({ lessCardSalesSelectedCheckbox: true, })
                          }
                        }} />
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        -{Number(grossKitchacoInStoreCardSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6><b>Total Revenue Without Cash {lessCardSalesSelectedCheckbox?' and Card ':''} Sales</b></h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(grossTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(netTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(gstTotalRevenueWithoutCashSales).toFixed(2)}
                      </Col>
                    </Row>
                    <h5 style={{ marginBottom: 10, marginTop: 10 }}><b>Less</b></h5>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco In Store Cash Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossKitchacoInStoreCashFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                      {netKitchacoInStoreCashFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                      {gstKitchacoInStoreCashFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco In Store Card Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossKitchacoInStoreCardFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoInStoreCardFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoInStoreCardFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco Online Pickup Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossKitchacoOnlinePickupFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoOnlinePickupFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoOnlinePickupFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Kitchaco Online Delivery Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossKitchacoOnlineDeliveryFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netKitchacoOnlineDeliveryFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstKitchacoOnlineDeliveryFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Uber Eats Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossUberEatsFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netUberEatsFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstUberEatsFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Doordash Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossDoordashFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netDoordashFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstDoordashFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Deliveroo Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossDeliverooFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netDeliverooFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstDeliverooFee}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Total Menulog Fee</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossMenulogFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMenulogFee}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMenulogFee}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <p>Stock Purchased</p>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {grossStockPurchased}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netStockPurchased}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstStockPurchased}
                      </Col>
                    </Row>
                    <p><b>Add Misc Charges Below</b></p>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3" >
                          <Row>
                            <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                              <Form.Check type="checkbox" label="GST?" checked={isGstMiscCharges1} onChange={() => {
                                if (this.state.isGstMiscCharges1 == true) {
                                  this.setState({ isGstMiscCharges1: false, gstMiscCharges1: 0, netMiscCharges1: grossMiscCharges1 })
                                }
                                else {
                                  this.setState({ isGstMiscCharges1: true, gstMiscCharges1: Number(Number(grossMiscCharges1) / 11).toFixed(2), netMiscCharges1: Number(Number(grossMiscCharges1) / 1.1).toFixed(2) })
                                }
                              }} />
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ paddingRight: 0 }}>
                              <Form.Control type="text" placeholder="eg:Utility" value={labelMiscCharges1} onChange={(event) => { this.setState({ labelMiscCharges1: event.target.value }) }} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" placeholder="20.00" value={grossMiscCharges1} onChange={(event) => {
                            this.setState({ grossMiscCharges1: event.target.value }, () => {
                              if (this.state.isGstMiscCharges1 == false) {
                                this.setState({ gstMiscCharges1: 0, netMiscCharges1: grossMiscCharges1 })
                              }
                              else {
                                this.setState({ gstMiscCharges1: Number(Number(grossMiscCharges1) / 11).toFixed(2), netMiscCharges1: Number(Number(grossMiscCharges1) / 1.1).toFixed(2) })
                              }
                            })
                          }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMiscCharges1}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMiscCharges1}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3" >
                          <Row>
                            <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                              <Form.Check type="checkbox" label="GST?" checked={isGstMiscCharges2} onChange={() => {
                                if (this.state.isGstMiscCharges2 == true) {
                                  this.setState({ isGstMiscCharges2: false, gstMiscCharges2: 0, netMiscCharges2: grossMiscCharges2 })
                                }
                                else {
                                  this.setState({ isGstMiscCharges2: true, gstMiscCharges2: Number(Number(grossMiscCharges2) / 11).toFixed(2), netMiscCharges2: Number(Number(grossMiscCharges2) / 1.1).toFixed(2) })
                                }
                              }} />
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ paddingRight: 0 }}>
                              <Form.Control type="text" placeholder="" value={labelMiscCharges2} onChange={(event) => { this.setState({ labelMiscCharges2: event.target.value }) }} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" placeholder="20.00" value={grossMiscCharges2} onChange={(event) => {
                            this.setState({ grossMiscCharges2: event.target.value }, () => {
                              if (this.state.isGstMiscCharges2 == false) {
                                this.setState({ gstMiscCharges2: 0, netMiscCharges2: grossMiscCharges2 })
                              }
                              else {
                                this.setState({ gstMiscCharges2: Number(Number(grossMiscCharges2) / 11).toFixed(2), netMiscCharges2: Number(Number(grossMiscCharges2) / 1.1).toFixed(2) })
                              }
                            })
                          }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMiscCharges2}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMiscCharges2}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3" >
                          <Row>
                            <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                              <Form.Check type="checkbox" label="GST?" checked={isGstMiscCharges3} onChange={() => {
                                if (this.state.isGstMiscCharges3 == true) {
                                  this.setState({ isGstMiscCharges3: false, gstMiscCharges3: 0, netMiscCharges3: grossMiscCharges3 })
                                }
                                else {
                                  this.setState({ isGstMiscCharges3: true, gstMiscCharges3: Number(Number(grossMiscCharges3) / 11).toFixed(2), netMiscCharges3: Number(Number(grossMiscCharges3) / 1.1).toFixed(2) })
                                }
                              }} />
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ paddingRight: 0 }}>
                              <Form.Control type="text" placeholder="" value={labelMiscCharges3} onChange={(event) => { this.setState({ labelMiscCharges3: event.target.value }) }} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" placeholder="20.00" value={grossMiscCharges3} onChange={(event) => {
                            this.setState({ grossMiscCharges3: event.target.value }, () => {
                              if (this.state.isGstMiscCharges3 == false) {
                                this.setState({ gstMiscCharges3: 0, netMiscCharges3: grossMiscCharges3 })
                              }
                              else {
                                this.setState({ gstMiscCharges3: Number(Number(grossMiscCharges3) / 11).toFixed(2), netMiscCharges3: Number(Number(grossMiscCharges3) / 1.1).toFixed(2) })
                              }
                            })
                          }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMiscCharges3}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMiscCharges3}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3" >
                          <Row>
                            <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                              <Form.Check type="checkbox" label="GST?" checked={isGstMiscCharges4} onChange={() => {
                                if (this.state.isGstMiscCharges4 == true) {
                                  this.setState({ isGstMiscCharges4: false, gstMiscCharges4: 0, netMiscCharges4: grossMiscCharges4 })
                                }
                                else {
                                  this.setState({ isGstMiscCharges4: true, gstMiscCharges4: Number(Number(grossMiscCharges4) / 11).toFixed(2), netMiscCharges4: Number(Number(grossMiscCharges4) / 1.1).toFixed(2) })
                                }
                              }} />
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ paddingRight: 0 }}>
                              <Form.Control type="text" placeholder="" value={labelMiscCharges4} onChange={(event) => { this.setState({ labelMiscCharges4: event.target.value }) }} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" placeholder="20.00" value={grossMiscCharges4} onChange={(event) => {
                            this.setState({ grossMiscCharges4: event.target.value }, () => {
                              if (this.state.isGstMiscCharges4 == false) {
                                this.setState({ gstMiscCharges4: 0, netMiscCharges4: grossMiscCharges4 })
                              }
                              else {
                                this.setState({ gstMiscCharges4: Number(Number(grossMiscCharges4) / 11).toFixed(2), netMiscCharges4: Number(Number(grossMiscCharges4) / 1.1).toFixed(2) })
                              }
                            })
                          }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMiscCharges4}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMiscCharges4}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3" >
                          <Row>
                            <Col xs={4} md={4} lg={4} style={{ paddingRight: 0 }}>
                              <Form.Check type="checkbox" label="GST?" checked={isGstMiscCharges5} onChange={() => {
                                if (this.state.isGstMiscCharges5 == true) {
                                  this.setState({ isGstMiscCharges5: false, gstMiscCharges5: 0, netMiscCharges5: grossMiscCharges5 })
                                }
                                else {
                                  this.setState({ isGstMiscCharges5: true, gstMiscCharges5: Number(Number(grossMiscCharges5) / 11).toFixed(2), netMiscCharges5: Number(Number(grossMiscCharges5) / 1.1).toFixed(2) })
                                }
                              }} />
                            </Col>
                            <Col xs={6} md={6} lg={6} style={{ paddingRight: 0 }}>
                              <Form.Control type="text" placeholder="" value={labelMiscCharges5} onChange={(event) => { this.setState({ labelMiscCharges5: event.target.value }) }} />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <Form.Group className="mb-3">
                          <Form.Control type="number" placeholder="20.00" value={grossMiscCharges5} onChange={(event) => {
                            this.setState({ grossMiscCharges5: event.target.value }, () => {
                              if (this.state.isGstMiscCharges5 == false) {
                                this.setState({ gstMiscCharges5: 0, netMiscCharges5: grossMiscCharges5 })
                              }
                              else {
                                this.setState({ gstMiscCharges5: Number(Number(grossMiscCharges5) / 11).toFixed(2), netMiscCharges5: Number(Number(grossMiscCharges5) / 1.1).toFixed(2) })
                              }
                            })
                          }} />
                        </Form.Group>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {netMiscCharges5}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {gstMiscCharges5}
                      </Col>
                    </Row>


                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6><b>Total Purchases</b></h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(grossTotalPurchases).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(netTotalPurchases).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(gstTotalPurchases).toFixed(2)}
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h5><b>Total Multibrands Earnings</b></h5>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        <h5><b>{Number(grossTotalSalesLessExpenditure).toFixed(2)}</b></h5>
                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6><b>Total Payout (inc GST)</b></h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(payoutResult).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                    </Row>

                    <Row>
                      <Col xs={3} md={3} lg={3}>
                        <h6><b>GST</b></h6>
                      </Col>
                      <Col xs={3} md={3} lg={3}>
                        {Number(gst).toFixed(2)}
                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                      <Col xs={3} md={3} lg={3}>

                      </Col>
                    </Row>
                    <hr></hr>
                    <Row style={{ marginTop: 20 }}>
                      <Col xs={4} md={4} lg={4}>
                        <h4>Report Configuartion</h4>
                        <p>{approved_by != '' ? 'Already Approved on ' + approved_on + " by " + approved_by : ''}</p>
                        <Button onClick={() => {
                          this.saveSalesInvoice(grossTotalRevenue, gst, netUberEatsFee, netDoordashFee, netDeliverooFee, netMenulogFee, grossKitchacoInStoreCashFee, netKitchacoInStoreCardFee, netKitchacoOnlinePickupFee, netKitchacoOnlineDeliveryFee, payoutResult,grossTotalSalesLessExpenditure)
                        }}>{approved_by == '' ? 'Approve' : 'Update and Approve Again'}</Button>

                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        {approved_by != '' ?
                          <>
                            <p>Send Email As</p>
                            <Form>
                              <div key={'inline-radio'} className="mb-3">
                                <Form.Check
                                  inline
                                  label="Kitchaco"
                                  name="group1"
                                  type={"radio"}
                                  id={'inline-radio-1'}
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({ sendEmailAs: "kitchaco" })
                                    }
                                    else {
                                      this.setState({ sendEmailAs: "" })
                                    }
                                  }}
                                />
                                <Form.Check
                                  inline
                                  label="Multibrands"
                                  name="group1"
                                  type={"radio"}
                                  id={'inline-radio-2'}
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({ sendEmailAs: "multibrands" })
                                    }
                                    else {
                                      this.setState({ sendEmailAs: "" })
                                    }
                                  }}
                                />

                              </div>
                            </Form>
                          </>
                          : null}
                      </Col>
                      <Col xs={4} md={4} lg={4}>
                        {approved_by != '' ?
                          <>
                            <p>&nbsp;</p>
                            <Button onClick={() => { this.sendEmail() }}>Email a copy</Button>
                          </>
                          : null}
                      </Col>
                    </Row>

                  </div>

                </Col>
              </Row>
              :
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <h4>Report</h4>
                  <p>{errorMessage} </p>
                </Col>
              </Row>}
          </Container>
        }
      </>
    )
  }
}
function mapStateToProps(state) {
  return {
    selectedTab: state.appReducer.selectedTab,
    vendorData: state.appReducer.vendorData,
    filters: state.appReducer.filters,
    selectedCategory: state.appReducer.selectedCategory,
    loggedIn: state.appReducer.loggedIn,
    smsSent: state.appReducer.smsSent,
    loginModalToggle: state.appReducer.loginModalToggle,
    phone: state.appReducer.phone,
    userId: state.appReducer.userId,
    token: state.appReducer.token,
    username: state.appReducer.username,
    email: state.appReducer.email,
    userAddress: state.appReducer.userAddress,
    userImg: state.appReducer.userImg,
    userLocation: state.appReducer.userLocation,
    currentLatitude: state.appReducer.currentLatitude,
    currentLongitude: state.appReducer.currentLongitude,
    selectedVendorData: state.appReducer.selectedVendorData,
    paymentStatus: state.appReducer.paymentStatus
  }
}

export default connect(mapStateToProps)(AdminTxnReportScreen);



